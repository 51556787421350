

import Vue from 'vue'
import axios from 'axios'
import CtaCteAPI from '@/api/clientes/cuentacorriente'

export default {
    namespaced: true,
    state: {
        cuentasCorrientes: [],
        cuentaCorriente: null,
        cuentaCorrienteEdit: null,
    },
    mutations: {
        setCuentasCorrientes(state, data) {
            state.cuentasCorrientes = data
        },
        setCuentaCorriente(state, data) {
            state.cuentaCorriente = data
        },
        setCuentaCorrienteEdit(state, data) {

            state.cuentaCorrienteEdit = { ...data }
        },

        addCuentaCorriente(state, ctaCte) {
            state.cuentasCorrientes.push(ctaCte)

        },
        quitCuentaCorriente(state, id) {
            for (var i in state.cuentasCorrientes) {

                if (state.cuentasCorrientes[i].id == id) {

                    state.cuentasCorrientes.splice(i, 1);

                    break;
                }
            }
        },

        updateCuentaCorriente(state, ctaCte) {

            for (var i in state.cuentasCorrientes) {

                if (state.cuentasCorrientes[i].id == ctaCte.id) {

                    state.cuentasCorrientes.splice(i, 1);


                    state.cuentasCorrientes.push(ctaCte)


                    break; //Stop this loop, we found it!
                }
            }

        }
    },

    getters: {
        cuentasCorrientes(state) {
            return state.cuentasCorrientes
        },

    },
    actions: {

        async fetchCuentasCorrientes(context) {
            const cuentasCorrientes = await CtaCteAPI.index()
            context.commit('setCuentasCorrientes', cuentasCorrientes.data)

        },

        async getCuentaCorriente(context, id) {
            console.log("VUEX - getCuentaCorriente");
            try {
                const cuentasCorrientes = await CtaCteAPI.show(id)

                    console.log(cuentasCorrientes);
                context.commit('setCuentaCorriente', cuentasCorrientes.data)

            } catch (error) {
                console.log(error);

                context.commit('setCuentaCorriente', null)
            }



        },
        async updateCuentaCorriente(context, ctaCte) {

            const cuentasCorrientes = await CtaCteAPI.update(ctaCte)

            // context.commit('update_CuentaCorriente', cuentasCorrientes.data)
            context.dispatch('getCuentaCorriente', ctaCte.cliente)

        },

        async open_cuenta_corriente(context, cliente_id) {
            var data = { cliente: cliente_id }
            try {
                await CtaCteAPI.open(data)
                context.dispatch('getCuentaCorriente', cliente_id)

            } catch (error) {

            }



        },

        async deleteCuentaCorriente(context, cliente_id) {
            const ctaCte = await CtaCteAPI.delete(cliente_id)
            // context.commit('quit_CuentaCorriente', ctaCte.id)
        },

        setHabilitado(context, id) {
            return new Promise((resolve, reject) => {

                CtaCteAPI.set_habilitado(id).then(response => {
                    context.dispatch('getCuentaCorriente', id)
                    resolve(response);
                }).catch(e => {
                    reject(e);
                })
            })




        },


    }
}