

import Vue from 'vue'
import axios from 'axios'
import EmpleadoAPI from "@/api/empleados"


export default {
  namespaced: true,
  state: { empleados: [],empleados_consumo: [], empleado: null, empleado_consumo: null, totalCtaCte: 0, loadingEmpleados: false, loadingEmpleado: false },
  mutations: {
    SET_EMPLEADOS(state, data) {
      console.log(data);
      state.empleados = data
    },
    SET_CONSUMO_EMPLEADO(state, data) {
      state.empleado_consumo = data
    },
    SET_LOADING_EMPLEADOS(state, value) {
      state.loadingEmpleados = value
    },
    SET_EMPLEADO(state, data) {
      state.empleado = data
    },
    SET_EMPLEADOS_CONSUMO(state, data) {
      state.empleados_consumo = data
    },
    SET_LOADING_EMPLEADO(state, value) {
      state.loadingEmpleado = value
    },
    SET_TOTAL_CTA_CTE(state, value) {
      state.totalCtaCte = value
    },
  },
  getters: {

  },
  actions: {
    async get_empleado(context, id) {
      context.commit('SET_LOADING_EMPLEADO', true)

      const response = await EmpleadoAPI.show(id)
      context.commit('SET_EMPLEADO', response.data)
      context.commit('SET_LOADING_EMPLEADO', false)


    },
    async get_consumo_empleado(context, id) {
      const response = await EmpleadoAPI.getConsumoEmpleado(id)
      context.commit('SET_CONSUMO_EMPLEADO', response.data)


    },
    async fetch_empleados(context, is_active=false) {
      context.commit('SET_LOADING_EMPLEADOS', true)
      const response = await EmpleadoAPI.index(is_active=is_active)
      context.commit('SET_EMPLEADOS', response.data.empleados)
      context.commit('SET_TOTAL_CTA_CTE', response.data.total_cta_cte)

      context.commit('SET_LOADING_EMPLEADOS', false)



    },
    async fetch_empleados_consumo(context) {
      context.commit('SET_LOADING_EMPLEADOS', true)
      const response = await EmpleadoAPI.indexConsumo()
      context.commit('SET_EMPLEADOS_CONSUMO', response.data.empleados)
      context.commit('SET_TOTAL_CTA_CTE', response.data.total_cta_cte)

      context.commit('SET_LOADING_EMPLEADOS', false)



    },
    set_active_empleado(context, empleado) {
      return new Promise((resolve, reject) => {

        EmpleadoAPI.setActive(empleado.id, !empleado.is_active).then(response => {
          context.dispatch('get_empleado', empleado.id)


          resolve(response);
        }).catch(e => {
          reject(e);
        })
      })

    },
    actualizar_empleado(context, empleado) {
      return new Promise((resolve, reject) => {
        const e = {
          id: empleado.id,
          nombre: empleado.nombre,
          apellido: empleado.apellido,
          telefono: empleado.telefono,
          direccion: empleado.direccion,
          email: empleado.email,
          documento: empleado.documento,

        }
        EmpleadoAPI.update(e).then(response => {
          resolve(response);
        }).catch(e => {
          reject(e);
        })
      })

      // const proveedores = await axios.put(`/proveedores/${proveedor.id}`, {
      //   nombre: proveedor.nombre,
      //   telefono: proveedor.telefono,
      //   direccion: proveedor.direccion,
      //   email: proveedor.email,
      //   localidad: { id: proveedor.localidad.id }
      // })

      // context.commit('update_proveedor', proveedores.data)
    },

    async registrar_proveedor(context, proveedor) {

      const proveedores = await axios.post(`/proveedores/`, {
        nombre: proveedor.nombre,
        telefono: proveedor.telefono,
        direccion: proveedor.direccion,
        email: proveedor.email,
        localidad: { id: proveedor.localidad.id }
      })

      context.dispatch('fetchProveedores')

    },

    async eliminar_proveedor(context, id) {

      const proveedores = await axios.delete(`/proveedores/${id}`)
      context.commit('quit_proveedor', id)
    },


  }
}