
export function SET_FORMAS_DE_PAGO(state, formas) {
        state.formas_de_pago = formas

}


export function SET_FORMAS_DE_PAGO_PARA_COBRO(state, formas) {
        state.formas_de_pago_para_cobro = formas

}
export function SET_MONTO_VENTAS_BLANCO(state, formas) {
        state.total_vendido_blanco = formas
}

export function SET_ASIENTOS_CONTABLES(state, data) {
        state.asientos_contable = data
}
export function SET_ASIENTO_CONTABLE(state, data) {
        state.asiento_contable = data
}
