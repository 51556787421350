export default {
    group: [],
    isLogged: false,
    
    sucursal: '',
    user: { username: '', full_name: '', groups: [], role: '' },
    nombreSucursal: '',
    uuidSucursal: '',
    empresa: null

}
