
export default [
    {
        path: '/proveedores',
        name: 'proveedores',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/proveedores/ProveedoresHome.vue'),

        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }


    },
    {
        path: '/proveedores/listasdeprecio',
        name: 'proveedoresListaDePrecio',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/proveedores/listasDePrecios/ProveedoresListasDePrecios.vue'),

        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }


    },
    {


        path: '/proveedores/:id_proveedor',
        component: () => import(/* webpackChunkName: "about" */ '@/views/proveedores/ProveedorHome.vue'),
        children: [
            // UserHome will be rendered inside User's <router-view>
            // when /user/:id is matched
            {
                path: '',
                name: 'proveedorHome',
                component: () => import(/* webpackChunkName: "about" */ '@/views/proveedores/ProveedorProfile.vue'),
            },
            {
                path: 'editar',
                name: 'editarPerfilProveedor',
                component: () => import(/* webpackChunkName: "about" */ '@/views/proveedores/ProveedorUpdate.vue'),
            },
            {
                path: 'lista-de-precio',
                name: 'listaDePrecioProveedor',
                component: () => import(/* webpackChunkName: "about" */ '@/views/proveedores/ProveedorListaDePrecio.vue'),
            },
          
            // ...other sub routes
        ],
        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }




    },
    
    {

        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '/proveedores/nuevo',

        name: 'nuevoProveedor',
        component: () => import(/* webpackChunkName: "about" */ '@/components/proveedor/ProveedorCreate.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }



    },
    // {


    //     path: '/proveedores/:id/actualizar',
    //     name: 'actualizarProveedor',
    //     component: () => import(/* webpackChunkName: "about" */ '@/components/proveedor/ProveedorUpdate.vue'),
    //     meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }




    // },
]