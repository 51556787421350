
export function userEsEmpleado (state) {
        const user = state.user;
        //! se utiliza para convertir el resultado de la expresión en un valor booleano. 
        //Esto garantiza que la función devuelva directamente true si el usuario tiene el role 'employee' y false en caso contrario.
        return !!(user && user.role && user.role.includes('employee'));
    }



export function username(state, group) {
    return  state.user.username;
}
