

import Vue from 'vue'
import axios from 'axios'
import ConfiguracionesAPI from '@/api/configuracion/parametros/index.js'

export default {
  namespaced: true,
  state: { notificacionesNuevas: [], notificacionesAnteriores: [], sin_ver: 0, alerta_retiro: false,parametros: [], tipo_parametros: [] },
  mutations: {
    SET_TIPO_PARAMETROS(state, data) {
      state.tipo_parametros = data
    },
    SET_PARAMETROS(state, data) {
      state.parametros = data
    },
  },

  getters: {

  },
  actions: {
    async fetch_parametros(context, tipo) {
      const response = await ConfiguracionesAPI.index(tipo)
      context.commit('SET_PARAMETROS', response.data)
    },
    async fetch_tipo_parametros(context) {
      const response = await ConfiguracionesAPI.tipo_parametros()
      context.commit('SET_TIPO_PARAMETROS', response.data)
    },
    async update_tipo_notificacion_status(context, data) {
      const response = await ConfiguracionesAPI.update(data)
    },



  }
}