import axios from 'axios'
export default {
   
    create( data ){
        var url = "/egresos/categorias";
        return axios.post( url, data );
    },
    index(nombreCategoria) {
        if (!nombreCategoria) {
            var nombreCategoria = 'CATEGORIA'
        }
        var url = `/egresos/categorias?name=${nombreCategoria}`;
        return axios.get(url)
    },
    updateCategoria(id, data) {

        var url = `egresos/categorias/${id}`;
        return axios.put(url, data)
    },
    fetchEgresoBuzon(date=null, history=false){
        console.log(date, history);
        var url = '/egresos/egresobuzon'
        
        if (history){
            url = `/egresos/egresobuzon?fecha=${date}&history=true`
        }
      
        return axios.get(url)
    }

 
}