<template>
  <div class="master">
    <div class="justify-center text-center">
      <div class="d-flex justify-space-between align-center">
        <v-img
          class="master"
          src="@/assets/bear-face-icon-2.png"
          width="150"
          height="200px"
          contain
        ></v-img>
      </div>
    </div>

    <!-- <h1 class="text-center">
      <span
        class="txt-rotate"
        data-period="2000"
        data-rotate='[ "Hola.", "Estamos iniciando el sistema.", "Aguarda un momento por favor.", "Ya estamos por terminar", "Muchas gracias" ]'
      ></span>
    </h1> -->
  </div>
</template>

<script>
var TxtRotate = function (el, toRotate, period) {
  this.toRotate = toRotate;
  this.el = el;
  this.loopNum = 0;
  this.period = parseInt(period, 10) || 2000;
  this.txt = "";
  this.tick();
  this.isDeleting = false;
};

TxtRotate.prototype.tick = function () {
  var i = this.loopNum % this.toRotate.length;
  var fullTxt = this.toRotate[i];

  if (this.isDeleting) {
    this.txt = fullTxt.substring(0, this.txt.length - 1);
  } else {
    this.txt = fullTxt.substring(0, this.txt.length + 1);
  }

  this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

  var that = this;
  var delta = 200 - Math.random() * 100;

  if (this.isDeleting) {
    delta /= 2;
  }

  if (!this.isDeleting && this.txt === fullTxt) {
    delta = this.period;
    this.isDeleting = true;
  } else if (this.isDeleting && this.txt === "") {
    this.isDeleting = false;
    this.loopNum++;
    delta = 500;
  }

  setTimeout(function () {
    that.tick();
  }, delta);
};
export default {
  mounted() {
    var elements = document.getElementsByClassName("txt-rotate");
    for (var i = 0; i < elements.length; i++) {
      var toRotate = elements[i].getAttribute("data-rotate");
      var period = elements[i].getAttribute("data-period");
      if (toRotate) {
        new TxtRotate(elements[i], JSON.parse(toRotate), period);
      }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
    document.body.appendChild(css);
  },
};
</script>

<style scoped>
html,
body {
  font-family: "Raleway", sans-serif;
  padding: 3em 2em;
  font-size: 18px;
  background: #222;
  color: #aaa;
}

h1,
h2 {
  font-weight: 200;
  margin: 0.4em 0;
}

h2 {
  color: #888;
  font-size: 2em;
}
</style>
