export function get_pedidos_pendientes(state) {
    return state.pedidos_pendientes
}

//PEDIDO COMPRA

export function detalles_pedido_compra(state) {
    return state.pedido_compra_register.detalle_pedido
}

export function proveedor_select_pedido_compra_register(state) {
    return state.pedido_compra_register.proveedor
}


//PEDIDO VENTA
export function detalles_pedido_venta(state) {
    return state.pedido_venta_register.detalle_pedido
}
export function formCliente(state) {
    return state.pedido_venta_register.cliente
}
export function formEnvio(state) {
    return state.pedido_venta_register.envio
}
 ///////




 

export function get_pedidos_aprobados(state) {
    return state.pedidos_aprobados
}
export function get_pedidos_finalizados(state) {
    return state.pedidos_finzalizados
}

export function cantidad_pedidos_pendientes(state) {
    return state.pedidos_pendientes.length
}
export function cantidad_pedidos_aprobados(state) {
    return state.pedidos_aprobados.length
}

export function get_detalles(state) {
    return state.views_detalle
}
export function is_update_detalles(state) {
    if (state.detalles_update.length > 0) {
        return true
    } else {
        return false
    }
}

export function get_detalles_register(state) {
    return state.pedido_register.detalle_pedido
}
export function get_id_proveedor(state) {
    return state.pedido_register.proveedor_id
}

export function get_detalles_for_update(state) {
    if (Object.keys(state.pedido_con_detalle).length === 0) {
        return []
    } else {
        return state.pedido_con_detalle.detalles_pedido

    }

}

//Devuelve los detalles para actualizar si existen
export function get_detalles_update(state) {
    if (state.detalles_update.length > 0) {
        return state.detalles_update
    } else {
        return false
    }
}








