

import Vue from 'vue'
import axios from 'axios'
import router from '@/router'

export default {
    namespaced: true,
    state: { loadData: false, loadingDataInit: false, error: false, errorMessage: 'errorMessage' },
    mutations: {
        //Esta variable indica si se realizó la petición de la info necesaria del sistema.
        setLoadData(state, bool) {
            state.loadData = bool
        },
        SET_LOADING_DATA_INIT(state, bool) {
            console.log("SET_LOADING_DATA_INIT, el parametro que llega es: ", bool);
            state.loadingDataInit = bool
        },
        SET_ERROR(state, payload) {
            state.error = payload.error;
            state.errorMessage = payload.errorMessage;
        }
    },
    getters: {

    },
    actions: {




        async fetchConfiguracion(context) {
            await context.dispatch('sucursales/fetch_configuracion', {}, { root: true })

        },



        async fetchSucursalInfo(context) {
            await context.dispatch('sucursales/fetch_sucursal_info', {}, { root: true })
        },




        async fetchInitConfig(context) {
            try {
                console.log("Iniciando fetchInitConfig...");
                context.commit('SET_LOADING_DATA_INIT', true);
                
                
                if (!Vue.$cookies.get('_sl_id')) {
                    console.log("Identificador de sesión no presente. Redirigiendo a '/sucursales'.");
                    context.commit('setLoadData', true);

                    router.push('/sucursales');
                    context.commit('SET_LOADING_DATA_INIT', false);

                    return;
                }
        
                console.log("Identificador de sesión presente. Continuando con la carga de datos iniciales.");


                console.log("Configuración cargada correctamente. Continuando con la validación de sucursal.");
        
                console.log("Realizando llamada a 'sucursales/validate_asignacion_sucursal'...");
                const validated_sucursal = await context.dispatch('sucursales/validate_asignacion_sucursal', null, { root: true });
                console.log("FetchInitConfig - validated_sucursal: ", validated_sucursal);
        
                if (!validated_sucursal.success) {
                    console.error("Error en 'sucursales/validated_sucursal'.");
                    context.commit('setLoadData', true);
                context.commit('SET_LOADING_DATA_INIT', false);

                    return;
                }
                console.log("Realizando llamada a 'sucursales/fetch_configuracion'...");
                const configuracion = await context.dispatch('sucursales/fetch_configuracion', {}, { root: true });
                console.log("FetchInitConfig - configuracion: ", configuracion);
                
                
                if (!configuracion.success) {
                    console.error("Error en 'sucursales/fetch_configuracion'.");
                    context.commit('setLoadData', true);
                context.commit('SET_LOADING_DATA_INIT', false);

                    return;
                }

        
                console.log("Configuración cargada correctamente. Continuando con la validación de caja.");
        
                console.log("Realizando llamada a 'sucursales/validate_caja'...");
                const validated_caja = await context.dispatch('sucursales/validate_caja', {}, { root: true });
                console.log("FetchInitConfig - validated_caja: ", validated_caja);
        
                if (!validated_caja.success) {
                    console.error("Error en 'sucursales/validate_caja'.");
                    context.commit('setLoadData', true);
                context.commit('SET_LOADING_DATA_INIT', false);

                    return;
                }
                console.log("validated_caja.response.data.caja", validated_caja.response.data.caja);
                if (!validated_caja.response.data.caja) {
                context.commit('setLoadData', true);

                    return
                }
        
                console.log("Validación de caja exitosa. Continuando con la obtención del arqueo vigente.");
        
                console.log("Realizando llamada a 'sucursales/fetchArqueoVigente'...");
                const arqueo = await context.dispatch('sucursales/fetchArqueoVigente', {}, { root: true });
                console.log("FetchInitConfig - arqueo: ", arqueo);
        
                if (!arqueo.success) {
                    console.error("Error en 'sucursales/fetchArqueoVigente'.");
                    context.commit('setLoadData', true);
                    context.commit('SET_LOADING_DATA_INIT', false);

                    return;
                }
        
                console.log("FetchInitConfig completado exitosamente.");
                context.commit('setLoadData', true);
                // router.push('/home');

        
            } catch (error) {
                console.error('Error en fetchInitConfig:', error);
                context.commit('SET_ERROR', { error: true, errorMessage: 'Error al cargar los datos iniciales' });
                context.commit('SET_LOADING_DATA_INIT', false);
                context.commit('setLoadData', true);
            }
            finally {
                context.commit('SET_LOADING_DATA_INIT', false);

            }
        },
        
        async waitForLoad(context) {
            // Puedes definir una condición que debe cumplirse antes de continuar
            // Por ejemplo, esperar a que loadData sea true
            while (!context.state.initialData.loadData) {
                // Espera un tiempo (puedes ajustar este valor según tus necesidades)
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        },
        async updateSystem(context) {
            // Actualiza los nuevos registros en el backend  la utlima version    
            var url = "/productos/actualizardatos";
            try {
                context.commit('setLoadingSystemUpdate', true, { root: true })
                const response = await Vue.axios({
                    method: 'GET',
                    url: url
                })
                context.commit('setSystemUpdate', true, { root: true })
                context.commit('setLoadingSystemUpdate', false, { root: true })
            }
            catch (error) {
                context.commit('setErrorSystemUpdate', true, { root: true })
                context.commit('setLoadingSystemUpdate', false, { root: true })
            }
        },
    }
}