<template>
  <div>
    <v-autocomplete
      :search-input.sync="inputSearch"
      v-model="inputSelectProducto"
      :class="{
        closed: this.isMobile && !this.isFocused,
        'custom-autocomplete': !this.isMobile,
      }"
      @focus="isFocused = true"
      @blur="isFocused = false"
      class="expanding-search mt-1"
      prepend-inner-icon="mdi-magnify"
      rounded
      filled
      dense
      hide-no-data
      :items="productos"
      :loading="isLoading"
      @keyup.enter="eventEnter(inputSearch)"
      placeholder="Buscar"
      color="blue"
      append-icon=""
      cache-items
      ref="productosApi"
      hide-details=""
      item-text="descripcion"
      item-value="descripcion"
      @click:clear="clearSearch()"
      clearable
      style="background-color: #3a3b3c"
    >
      <!-- </v-text-field> -->
    </v-autocomplete>
  </div>
</template>

<script>
import ProductosAPI from "@/api/productos";
export default {
  name: "producto.buscador",
  props: {
    categorias: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      inputSearch: null,
      inputSelectProducto: null,
      isLoading: false,
      productos: [],
      borrando: false, //Bandera que se activa cuando presiona 'del'
      isFocused: false,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    clearSearch() {
      this.inputSearch = null;
      if (this.$route.query.q || this.$route.query.barcode) {
        let query = Object.assign({}, this.$route.query);
        delete query.q;
        delete query.barcode;

        this.$router.replace({ query });
      }
    },
    focus() {
      this.$refs.productosApi.focus();
    },
    async getFromSearch(input_search) {
      try {
        this.isLoading = true;
        var replaced = input_search.split(" ").join("-");
        const response = await ProductosAPI.getSuggest(
          replaced,
          this.categorias
        );
        this.productos = response.data.results;
        this.isLoading = false;
      } catch (error) {
        var e = error.response;
        this.isLoading = false;

        // this.mensajeBuscando = "No se encontró coincidencia";
      }
    },
    eventEnter(inputSearch) {
      // Quito la referencia que trae s, porque sino cuando hago reset, se borra el query parametro
      if (inputSearch == null || inputSearch == "") return;
      var search = inputSearch ? inputSearch.repeat(1) : "";

      const productMenu = this.$refs.productosApi;
      if (productMenu.isMenuActive) {
        this.$refs.productosApi.isMenuActive = false;
      }
      this.$emit("process-buscador-producto", search);
      var isBarcode = false;
      let isnum = /^\d+$/.test(search);

      if (isnum && search.length > 6) {
        isBarcode = true;
      }
      if (isBarcode) {
        this.inputSearch = "";
      }
    },
  },
  watch: {
    inputSelectProducto(select) {
      this.$emit("process-buscador-producto-select", select);
    },
    inputSearch(val) {
      //val -> lo que escribe en el buscador

      if (
        val == null ||
        val == "" ||
        val == " " ||
        val == undefined ||
        val.length < 2
      ) {
        return;
      }
      const regex = /\S|$/;
      var cantEspacios = val.search(regex) || 0;
      if (cantEspacios > 0) {
        var cadena2 = val.slice(cantEspacios);
        this.inputSearch = cadena2;
        return;
      }
      // this.noDataSearch = val;
      // this.mensajeBuscando = "Buscando...";

      // Items have already been requested
      if (this.isLoading) return;
      if (this.borrando) return;

      // if (this.eventClick) return;

      this.getFromSearch(val);
    },
    $route: {
      handler: function (value) {
        this.inputSearch = null;
      },
    },
  },
  mounted() {
    if (this.$route.query.q != undefined && this.$route.query.q != "") {
      this.inputSearch = this.$route.query.q;
    }

    window.addEventListener("keyup", (event) => {
      if (event.keyCode == 8) {
        this.borrando = true;
      } else {
        this.borrando = false;
      }
      // si presiona suprimir se borra la busqueda
      console.log(event.code);
      if (event.code === "Delete") {
        this.clearSearch();
      }
    });
  },
};
</script>

<style  scoped>
.v-input.custom-autocomplete {
  width: 680px;
}
.v-input.expanding-search {
  transition: max-width 0s;
}
.v-input::v-deep .v-input__control .v-input__slot {
  padding: 0 9px;
}

.v-input.expanding-search::v-deep .v-input__control.v-input__slot {
  cursor: pointer !important;
}

.v-input.expanding-search.closed {
  max-width: 40px;
}

.v-input.expanding-search.closed .v-input__slot {
  background: transparent !important;
}
</style>

