import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import initialData from '@/store/modules/initialData'
import productos from '@/store/modules/productos'
import pedidos from '@/store/modules/pedidos'
import remitos from '@/store/modules/remitos'
import auth from '@/store/modules/authentication'
import marcas from '@/store/modules/marcas'
import buscador from '@/store/modules/buscador'
import categorias from '@/store/modules/categorias'
import reportes from '@/store/modules/reportes'
import empresa from '@/store/modules/empresa'
import comprobantes from '@/store/modules/comprobantes'
import soporte from '@/store/modules/soporte'
import notificaciones from '@/store/modules/notificaciones'
import sucursales from '@/store/modules/sucursales'
import proveedor from '@/store/modules/proveedor'
import ventas from '@/store/modules/ventas'
import configuraciones from '@/store/modules/configuraciones'
import egresos from '@/store/modules/egresos'
import cliente from '@/store/modules/clientes/cliente'
import clienteDirecciones from '@/store/modules/clientes/direcciones'
import clienteCuentaCorriente from '@/store/modules/clientes/cuentacorriente'
import empleado from '@/store/modules/empleado'
import contabilidad from '@/store/modules/contabilidad'
import facturaCompra from '@/store/modules/compra/factura'










const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth']

})

Vue.use(Vuex)



const store = new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    loading: false,
    loadingInit: false,
    error: false,
    errorMessage: 'aaa',
    errorLogoutFail: false,
    errorLogoutFailMessage: '',
    sucursal: "nada",
    caja: null,


    system: {
      update: false,
      error: false,
      loading: false
    },

    layout: 'layout-basic',
    withNav: true,

    links: [
    ],
    linksPorMenu: {},
    loadingLinksPorMenu: false,
    menues : {
      VENTAS: 'Ventas',
      COMPRAS: 'Compras',
      INVENTARIO: 'Inventario',
      // Agrega aquí los demás nombres de módulos que necesites
    }
  },
  mutations: {
    SET_LAYOUT(state, { layout, withNav }) {
      if (state.auth.isLogged) {

        state.layout = layout
        state.withNav = withNav
      } else {
        state.layout = 'layout-login'
      }
    },
    SET_LINKS(state, links) {
      state.links = links
    },
    SET_LINKS_POR_MENU(state, { menuNombre, links }) {
      console.log("SET_LINKS_POR_MENU", menuNombre, links);
      // Actualiza el estado con los links del menú correspondiente
      // Puedes almacenar los links en una propiedad del estado, como 'linksPorMenu'
      // Pero en este caso, como la response es un array, simplemente guardamos los links directamente
      // sin necesidad de usar un objeto donde la clave sea el nombre del menú
      state.linksPorMenu[menuNombre] = links;
    },
    SET_LOADING_LINKS(state, bool) {
      state.loadingLinksPorMenu = bool
    },

    setLoadingSystemUpdate(state, bool) {
      state.system.loading = bool
    },
    setSystemUpdate(state, bool) {
      state.system.update = bool
      state.system.error = false
    },
    setErrorSystemUpdate(state, bool) {
      state.system.update = bool
      state.system.error = true
    },
    setLoading(state, bool) {
      state.loading = bool
    },
    setLoadingInit(state, bool) {
      state.loadingInit = bool
    },
    setError(state, error) {
      state.error = true
      state.errorMessage = error
      state.loading = false

    },
    cleanError(state) {
      state.error = false
      state.errorMessage = ''
    },
    setSucursalCaja(state, data) {
      state.sucursal = data.sucursal
      state.caja = data.caja
    },
    setErrorLogoutFail(state, error) {
      state.errorLogoutFail = true
      state.errorLogoutFailMessage = error
      state.loading = false
      setTimeout(() => {
        state.errorLogoutFail = false
        state.errorLogoutFailMessage = ''
      }, 3000);
    },
  },
  getters: {
    getLayoutApp(state) {
      return state.layout
    },
    loadingSystemUpdate(state) {
      return state.system.loading
    },
    getSucursal(state) {
      return state.sucursal
    },
    getCaja(state) {
      return state.caja
    },
    getError(state) {
      return state.errorMessage
    },
    getLinksDelMenu: (state) => (menuNombre) => {
      // Verifica si el menú existe en el estado, si no, devuelve un array vacío
      if (state.linksPorMenu.hasOwnProperty(menuNombre)) {
        return state.linksPorMenu[menuNombre];
      } else {
        return [];
      }
    },
  },
  actions: {
    async fetchLinksPorMenu({ commit, state }, menuNombre) {
      // Verificar si los links del menú ya existen en el estado
      if (state.linksPorMenu[menuNombre]) {
        // Si ya existen, retornar los links almacenados en el estado
        return state.linksPorMenu[menuNombre];
      } else {
        // Si no existen, hacer la solicitud al servidor
        commit('SET_LOADING_LINKS', true);

        try {
          const response = await Vue.axios.get(`/empresas/link?menu=${menuNombre}`);
          const links = response.data;
          console.log("fetchLinksPorMenu",response);
          console.log("fetchLinksPorMenu",links);

  
          // Almacenar los links en el estado usando el nombre del menú como clave
          commit("SET_LINKS_POR_MENU", { menuNombre, links });
  
          return links;
        } catch (error) {
          // Manejar el error aquí si es necesario
          console.error("Error al obtener los links del menú:", error);
          return [];
        } finally {
          commit('SET_LOADING_LINKS', false);
        }
      }
    },

  },
  modules: {
    initialData,
    productos,
    pedidos,
    remitos,
    auth,
    proveedor,
    marcas,
    buscador,
    categorias,
    reportes,
    empresa,
    comprobantes,
    soporte,
    notificaciones,
    sucursales,
    ventas,
    configuraciones,
    egresos,
    cliente,
    clienteDirecciones,
    clienteCuentaCorriente,
    empleado,
    contabilidad,
    facturaCompra
  }
})

export default store;