import axios from 'axios'
import { controlFormasDePago } from "@/services/ventas/registrarVenta.js"
export default {
    index(paginacion) {
        let url = ''
        return axios.get(`${url}?esVenta=${true}
                        &doc=NV&page=${paginacion.page}
                        &page_size=${paginacion.rowsPerPage}`)
    },

    show(id) {
        return axios.get(`/comprobantes/comprobantes/${id}`)
    },

    update(id, data) {

    },

    create(data) {
        return axios.post('pedidos/venta', data);
    },

    pagar(id, dataCobro) {
        return axios.put(`pedidos/pagar/${id}`,
            {
                formaDePago: controlFormasDePago(),
                descuento: dataCobro.descuento ? dataCobro.descuento : 0,
                motivoDescuento: dataCobro.motivoDescuento,
                recargo: dataCobro.recargo ? dataCobro.recargo : 0,
                pagaCon: dataCobro.pagoCliente ? dataCobro.pagoCliente : 0

            });
    }
}