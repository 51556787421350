import axios from 'axios'
export default {
    index( paginacion ){
        return axios.get(`/comprobantes/comprobantes?esVenta=${true}&py=false&pf=false&doc=FC|NV&page=${paginacion.page}&page_size=${paginacion.rowsPerPage}`)
                        
    },
    indexpy( paginacion ){
        return axios.get(`/comprobantes/comprobantes?&esVenta=${true}&py=true&pf=false&doc=FC|NV&page=${paginacion.page}&page_size=${paginacion.rowsPerPage}`)
    },
    indexPendienteFacturacion( paginacion ){
        return axios.get(`/comprobantes/comprobantes?esVenta=${true}&py=false&pf=true&doc=FC|NV&page=${paginacion.page}&page_size=${paginacion.rowsPerPage}`)
    },


    show( id ){
        return axios.get(`/comprobantes/comprobantes/${id}`)
    },

    update( id, data ){
        
    },

    create( data ){
     
        return axios.post( 'comprobantes/ventadirecta', data );
    },

    anular( id, motivoAnulacion ){
        return axios.put(`comprobantes/anulados/${id}`, {
            motivoAnulacion: motivoAnulacion,
          });
    },
    facturar( id ){
        return axios.put(`comprobantes/facturar/${id}`);
    },
    facturarPreImpreso(id, data){
        return axios.post(`afipfiscal/preimpreso/crear/${id}`, data); 
    }

}