<template>
  <div>
    <component v-if="isLogged " :is="'LayoutInbox'"></component>
    <LoadingCenter v-if="loadingDataInit " />
    <Loading v-if="loadingInit" :active.sync="loadingInit" />

    <layout-login v-if="!isLogged"></layout-login>
  </div>
</template>

<script>
import LayoutLogin from "@/layouts/app/layout.login.vue";
import LayoutInbox from "@/layouts/app/inbox/LayoutInbox";

import LoadingCenter from "@/util/LoadingCenter.vue";
import Loading from "vue-loading-overlay";

import { mapActions, mapState } from "vuex";

export default {
  name: "App",
  components: {
    LayoutLogin,
    LayoutInbox,
    LoadingCenter,
    Loading,
  },
  computed: {
    ...mapState("auth", ["user", "isLogged"]),
    ...mapState(["loading", "errorLogoutFail", "layout", "loadingInit"]),
    ...mapState("initialData", ["loadData", "loadingDataInit"]),
    ...mapState("sucursales", ["configuracion"]),
  },

  mounted() {
    console.log("isLogged && !loadingInit || !loadingDataInit", this.isLogged, this.loadingInit, this.loadingDataInit);
    if (this.isLogged && !this.loadingInit || !this.loadingDataInit){
      console.log("LOS TRES JUNTOS: ",true);
      
    }else{
      console.log("LOS TRES JUNTOS: ",false);

    }
    window.addEventListener("keyup", (event) => {
      event.preventDefault();
      if (event.code === "F2") {
        if (this.configuracion && this.configuracion.menuVentaCruachan) {
          var path = "/ventas/pedidos/nuevo";
        } else {
          var path = "/ventas/nuevo";
        }
        if (this.$route.path !== path) {
          this.$router.push(path);
        }
      }
    });
  },
  created() {
    var empresa = this.$store.getters["empresa/empresa_nombre"];
    if (!empresa) {
      this.axios
        .get("/empresas/info")
        .then((response) => {
          console.log(response.data);
          this.$store.commit("empresa/SET_EMPRESA", response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
};
</script>
