
export default [
    {
        path: '/clientes',
        name: 'clientes',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/cliente/ClientesHomeList.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireModuloCliente: true }

    },
    {


        path: '/clientes/:id_cliente',
        component: () => import(/* webpackChunkName: "about" */ '@/views/cliente/Cliente.vue'),
        children: [
            // UserHome will be rendered inside User's <router-view>
            // when /user/:id is matched
            {
                path: '',
                name: 'clienteHome',
                component: () => import(/* webpackChunkName: "about" */ '@/views/cliente/ClienteHome.vue'),
            },
            {
                path: 'editar',
                name: 'actualizarCliente',
                component: () => import(/* webpackChunkName: "about" */ '@/views/cliente/ClienteUpdate.vue'),
            },
            {
                path: 'direcciones',
                name: 'direcciones',
                component: () => import(/* webpackChunkName: "about" */ '@/views/cliente/direcciones/cliente.direcciones.vue'),
            },
            {
                path: 'contabilidad',
                name: 'clienteContabilidad',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '@/views/cliente/contabilidad/ClienteContabilidad.vue'),
            },
            {
                path: 'cuentacorriente',
                name: 'clientesCuentasCorrientesDetalle',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '@/views/cliente/cliente/ClienteCuentaCorriente.vue'),
            },
            
            {
                path: 'cuentacorriente/settings',
                name: 'ClienteCuentaCorrienteSettings',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '@/views/cliente/cliente/ClienteCuentaCorrienteSettings.vue'),
            },
            {
                path: 'cuentacorriente/actividad',
                name: 'ClienteCuentaCorrienteMovimientos',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '@/views/cliente/cliente/ClienteCuentaCorrienteMovimientos.vue'),
            },
            {
                path: 'cuentacorriente/actividad/detail/:id_comprobante',
                name: 'ClienteCuentaCorrienteMovimientosDetail',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '@/views/cliente/cliente/ClienteCuentaCorrienteMovimientosDetail.vue'),
            }
            // ...other sub routes
        ],
        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireModuloCliente: true }




    },
    {

        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '/clientes/nuevo',

        name: 'nuevoCliente',
        component: () => import(/* webpackChunkName: "about" */ '@/views/cliente/ClienteCreate.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireModuloCliente: true }



    },
    
    {
        path: '/clientes/cuentascorrientes',
        name: 'clientesCuentasCorrientes',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/cliente/ClientesCuentaCorriente.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireModuloCliente: true }

    },
    // {
    //     path: '/clientes/cuentascorrientes/:id',
    //     name: 'clientesCuentasCorrientesDetalle',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '@/views/cliente/ClientesCuentaCorrienteDetalle.vue'),
    //     meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

    // },
]