import axios from 'axios'
const url = '/clientes/direcciones'
export default {

    index(cliente_id) {
        return axios.get(`${url}?c=${cliente_id}`)
    },

    show(id) {

        return axios.get(`${url}/${id}`)
    },

    create(data) {

        return axios.post(`${url}`, data)
    },

    update(data) {

        return axios.put(`${url}/${data.id}?c=${data.cliente}`, data)
    },

    get_direccion_favorita(cliente_id) {
        return axios.get(`/clientes/${cliente_id}/direcciones/favorita`)

    },
    set_favorita(cliente_id, direccion_id) {
        var data = {
            d: direccion_id,
        };
        return axios.post(`/clientes/${cliente_id}/direcciones/favorita`, data)

    },


    delete(data) {
        console.log(data);
        return axios.delete(`${url}/${data[0].id}`)


    },
    deleteMultiple(id, data) {
        return axios.post(`${url}/delete`, data)


    },

}