import axios from 'axios'
export default {
    index( ){
        var url = `/notificaciones/tipo-notificacion`
        return axios.get(url)
    },

    show( id, alerta ){
        var alerta= "RETIRO"
        var url = `/sucursales/configuracion/alerta?a=${alerta}`
        return axios.get(url)
    },

    update( field, data ){
        var url = `/notificaciones/tipo-notificacion/${data.id}?_field=${field}`
        return axios.put(url, {
            value: data[field],
          }) 
    },

    create( data ){
    },
    

  
}