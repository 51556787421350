

import Vue from 'vue'
import axios from 'axios'

export default {
  namespaced: true,
  state: { marcas: [], refresh: false },
  mutations: {
    setMarcas(state, data) {
      state.marcas = data
    },
    
    add_marca(state, marca) {
      state.marcas.push(marca)
      state.refresh = true
      setTimeout(() => {
      state.refresh = false
          
      }, 2000);
    }
  },

  getters: {
    marcas(state) {
      return state.marcas
    },

  },
  actions: {
    async fetch_marcas(context) {

      var url = "/soporte/marcas";
      console.log("Hola");
      const marcas = await Vue.axios({
        method: 'GET',
        url: url
      })
      context.commit('setMarcas', marcas.data)


    },
    async actualizar_marca(context, marca) {

      const marcas = await axios.put(`/soporte/marcas/${marca.id}`, marca)
      context.dispatch('fetch_marcas')
    },
    
    async registrar_marca(context, marca) {

      const marcas = await axios.post(`/soporte/marcas`, marca)
      context.commit('add_marca', marcas.data)

    },

    async eliminar_marca(context, id) {

      const marcas = await axios.delete(`/soporte/marcas/${id}`)
      context.dispatch('fetch_marcas')
    },


  }
}