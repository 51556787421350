<template>
  <v-navigation-drawer
    class="scroll-bar"
    color="#121212"
    v-model="drawer"
    app
    :floating="!$vuetify.breakpoint.smAndDown && !miniVariantComputed"
    fixed
    clipped
    permanent
    :mini-variant="miniVariantComputed"
  >
    <template v-slot:prepend>
      <v-list>
        <v-list-item link :to="{ name: 'home' }" class="px-2">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-list-item-avatar  v-on="on">
                <v-icon>mdi-home</v-icon>
              </v-list-item-avatar>
            </template>
            <span>Inicio</span>
          </v-tooltip>
          <v-list-item-title>Inicio</v-list-item-title>
        </v-list-item>
      </v-list>
    </template>

    <v-list v-if="links.length > 0">
      <v-list-item
        v-for="item in links"
        :key="item.name"
        link
        :to="{ name: item.route_name }"
        :color="item.color"
      >
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-list-item-icon v-on="on">
              <v-icon :color="item.color ? item.color : ''">{{
                item.icon
              }}</v-icon>
            </v-list-item-icon>
          </template>
          <span>{{ item.name }}</span>
        </v-tooltip>

        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-container v-if="links.length == 0">
      <v-row v-for="i in 6" :key="i">
        <v-col cols="12">
          <v-skeleton-loader
            max-height="350"
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavigationInbox",
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  computed: {
    miniVariantComputed() {
      return this.$route.path !== "/home";
    },
  },
  data() {
    return {
      drawer: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.scroll-bar {
  left: auto;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.scroll-bar:hover ::-webkit-scrollbar {
  width: 8px !important;
  padding: 0px;
}
.scroll-bar ::-webkit-scrollbar {
  width: 0px;
  height: 18px;
}

.scroll-bar ::-webkit-scrollbar-track {
  background: transparent;
  padding: 0px;
}
.scroll-bar ::-webkit-scrollbar-thumb {
  background: #888;
}
</style>