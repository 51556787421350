import axios from 'axios'
export default {
    index(nombreCategoria) {
        if (!nombreCategoria) {
            var nombreCategoria = 'CATEGORIA'
        }
        var url = `/categorias/categorias?name=${nombreCategoria}`;
        return axios.get(url)
    },
    indexFilters() {
        var url = "/categorias/categorias/filters";
        return axios.get(url)
    },
  
}