import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import ProductosAPI from '@/api/productos'


export async function fetch_productos_mas_vendidos(context) {
    try {
        context.commit('set_loading_mas_vendidos', true)

        const response = await ProductosAPI.fetchMasVendidos()
        context.commit('set_productos_mas_vendidos', response.data.results)
        context.commit('set_loading_mas_vendidos', false)

    } catch (error) {
        var e = error.response
        context.commit('set_loading_mas_vendidos', false)

    }
}
export async function fetch_productos_pedido_ya(context) {
    try {
        context.commit('set_loading_mas_vendidos', true)


        const response = await ProductosAPI.fetchPedidoYa()
        context.commit('set_productos_mas_vendidos', response.data.results)
        context.commit('set_loading_mas_vendidos', false)

    } catch (error) {
        var e = error.response
        context.commit('set_loading_mas_vendidos', false)

    }


}

export async function getProductosFromSearch(context, input_search) {
    try {

        //Variable interna para instanciar lo que escribió en el buscador
        var search = ''
        //Comprueba si se escribió en el buscador
        if (input_search !== null) {
            // context.commit('set_input_search', input_search)
            search = input_search

        }

        //Recorro el array  de filters_for_productos_admin para agregarlo a la url
        var param_url = ''
        if (context.state['filters_for_productos_admin'].length > 0) {
            param_url = '&'
            for (var i = 0; i < context.state['filters_for_productos_admin'].length; i++) {
                var nombre = context.state['filters_for_productos_admin'][i].nombre.toUpperCase()
                var key = context.state['filters_for_productos_admin'][i].key
                param_url = param_url.concat(key, '=', nombre, '&')
            }
        }


        var paginacion = context.state['paginacion_productos_admin'];
        // Si el usuario había escrito en el buscador, lo mantiene
        if (search == null && context.state['search_data']
        ) {
            search = context.state['search_data']
        }

        var url = `/productos/p?q=${search}&page=${paginacion.page}&page_size=${paginacion.rowsPerPage}${param_url}`;
        context.commit('set_url_productos_admin', url)


        const response = await Vue.axios({
            method: 'GET',
            url: url,
        })
        context.commit('set_productos_admin', response.data.results)
        context.commit('set_cantidad_productos_admin', response.data.count)


        // Guarda lo que escribio en el buscador, para que si utiliza algun filtro, se mantenga tambien lo que escribió
        if (search !== null) {
            context.commit('set_input_search', input_search)
        }




    } catch (error) {

        var e = error.response
        // context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    } finally {
        // context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    }



}

export async function getProductosInventario(context, filter) {
    try {
        const response = await ProductosAPI.fetchInventario(filter)
        context.commit('SET_PRODUCTOS_INVENTARIO', response.data.results)
    } catch (error) {
        var e = error.response
    }
}


export async function actualizarProductoInventario(context, producto) {
    try {
        const response = await ProductosAPI.updateProductoInventario(producto)
    } catch (error) {
        var e = error.response
    }
}


export async function get_productos_for_params(context, params) {
    try {
        context.commit('SET_LOADING_PRODUCTOS_ADMIN', true)

        //Recorro el array  de filters_for_productos_admin para agregarlo a la url
        // var param_url = ''
        var param_url = ''
        if (params.length > 0) {

            param_url = '&'
            for (var i = 0; i < params.length; i++) {
                var value = params[i].value
                var key = params[i].key
                param_url = param_url.concat(key, '=', value, '&')


            }
        }

        var paginacion = context.state['paginacion_productos_admin'];
        // Si el usuario había escrito en el buscador, lo mantiene

        // var sucursal = context.rootState.sucursales.sucursal ? context.rootState.sucursales.sucursal.id : 0;
        var url = `/productos/p?&page=${paginacion.page}&page_size=${paginacion.rowsPerPage}${param_url}`;


        const response = await Vue.axios({
            method: 'GET',
            url: url,
        })
        context.commit('SET_LOADING_PRODUCTOS_ADMIN', false)

        context.commit('set_productos_admin', response.data.results)
        context.commit('set_cantidad_productos_admin', response.data.count)



    } catch (error) {

        var e = error.response
        context.commit('SET_LOADING_PRODUCTOS_ADMIN', false)

        // context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    } finally {
        // context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    }



}
export async function get_productos_for_filters(context) {
    try {
        context.commit('SET_LOADING_PRODUCTOS_ADMIN', true)

      
        var filtros = context.state['productos_filters'].concat(context.rootGetters['categorias/filtrosEditorMasivo'])
        var paginacion = context.state['paginacion_productos_admin'];
        // Si el usuario había escrito en el buscador, lo mantiene


        var url = `/productos/filters?page=${paginacion.page}&page_size=${paginacion.rowsPerPage}&filters=${filtros}`;


        const response = await Vue.axios({
            method: 'GET',
            url: url,
        })
        context.commit('set_productos_admin', response.data.results)
        context.commit('set_cantidad_productos_admin', response.data.count)

        context.commit('SET_LOADING_PRODUCTOS_ADMIN', false)


    } catch (error) {

        var e = error.response
        context.commit('SET_LOADING_PRODUCTOS_ADMIN', false)

        // context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    } finally {
        // context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    }



}
export async function eliminar_producto_buscador_admin({ commit, state }, id) {
    try {


        var url = `/productos/p/${id}`;
        await Vue.axios({
            method: 'DELETE',
            url: url,
        })

        commit('quit_producto_admin', id)


    } catch (error) {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    } finally {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }
}
export async function eliminar_producto_multiples({ commit }, productos) {
    try {
        var data;
        for (let i = 0; i < productos.length; i++) {
            data.push(productos[i].id)

        }

        var url = `/productos/delete/multiple/`;
        const request = await axios.post(url, data)
        for (let i = 0; i < productos.length; i++) {
            commit('quit_producto_admin', productos[i].id)
        }


    } catch (error) {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    } finally {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }
}


export function registrar_productos_multiple(context) {

    var valid = context.getters['validar_registro_producto'];

    if (valid) {
        var categoria_id = context.state.producto_registrar_multiple.categoria.id
        var marca_id = context.state.producto_registrar_multiple.marca.id
        proveedor_id = null
        if (context.state.producto_registrar_multiple.proveedor){
            var proveedor_id = context.state.producto_registrar_multiple.proveedor.id
        }

        var es_elaboracion_propia = context.state.producto_registrar_multiple.es_elaboracion_propia


        //Comprueba de que manera envia el json al servidor
        if (context.state.producto_registrar_multiple.es_marca_unica) {
            var productos = context.state.producto_registrar_multiple.productos
            var data = {
                id: 0,
                productos: productos,
                marca_id: marca_id,
                proveedor_id: proveedor_id,
                categoriaArbol_id: categoria_id,
                es_elaboracion_propia: es_elaboracion_propia
            }

        } else {
            var productos = context.state.producto_registrar_multiple.productos
            var data = {
                id: 1,
                productos: productos,
                proveedor_id: proveedor_id,
                categoriaArbol_id: categoria_id,
                es_elaboracion_propia: es_elaboracion_propia
            }
        }
        var url = '/productos/productsmultiple'
        return new Promise((resolve, reject) => {
            Vue.axios({
                method: 'POST',
                url: url,
                data: data,
            }).then(response => {
                resolve(response);
                context.commit("RESET_PRODUCTO_REGISTRAR_MULTIPLE")
                context.dispatch('fetch_productos_mas_vendidos')
            }).catch(e => {
                reject(e);
            })
        })


    } else {
        console.log("Validación incorrecta");
    }

}
export function registrar_producto_simple(context, producto) {

    var precioVenta = Math.round(
        producto.precioCosto *
        (1 + parseFloat(producto.ganancia) / 100) *
        (1 + parseFloat(producto.flete ? producto.flete : 0) / 100) *
        (1 + parseFloat(producto.iva) / 100)
    );
    producto.precioVenta = precioVenta
    if (producto.marca) {
        producto.marca = producto.marca.id
    }else{
        producto.marca = null

    }
    if (producto.categoriaArbol) {
        producto.categoriaArbol = producto.categoriaArbol.id
    }else{
        producto.categoriaArbol = null

    }
    if (producto.proveedor) {
        producto.proveedor = producto.proveedor.id
    }else{
        producto.proveedor = null

    }

    return new Promise((resolve, reject) => {
        ProductosAPI.registrarSimple(producto).then(response => {
            resolve(response);
        }).catch(e => {
            reject(e);
        })
    })

}

// ACTUALIZACION
export function actualizar_producto(context, producto) {
    var marca_id = producto.marca ? producto.marca.id : null;

    producto.precioVenta = producto.precioVenta
    producto.marca = marca_id
    producto.categoriaArbol = producto.categoriaArbol.id
     if (producto.proveedor) {
        producto.proveedor = producto.proveedor.id
    }else{
        producto.proveedor = null

    }

    return new Promise((resolve, reject) => {
        ProductosAPI.update(producto).then(response => {
            resolve(response);
        }).catch(e => {
            reject(e);
        })
    })
}
export function actualizar_inventario(context, producto) {
    var marca_id = producto.marca ? producto.marca.id : null;
    producto.marca = marca_id
    producto.categoriaArbol = producto.categoriaArbol.id
    producto.proveedor = producto.proveedor.id

    return new Promise((resolve, reject) => {
        ProductosAPI.update(producto).then(response => {
            context.commit("REMOVE_ITEM_INVENTARIO", response)
            resolve(response);
        }).catch(e => {
            reject(e);
        })
    })
}

export async function get_productos_filters({ commit, state }, id) {
    try {
        // id = 0 -> para actualizar productos
        //id = 1 -> para reportes
        
        commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root

        var param_url = ''
        for (var i = 0; i < state['filters'].length; i++) {

            param_url = param_url.concat(state['filters'][i].key, '=', state['filters'][i].value, '&')
        }
        var url = `/productos/products_filter?${param_url}`;
        await Vue.axios({
            method: 'GET',
            url: url,
        }).then(response => {
            if (id == 1) {

                commit('set_productos_reporte', response.data)
            } else {
                commit('set_productos_update', response.data)
            }

        })
    } catch (error) {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    } finally {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }
}

export async function get_producto({ commit, state }, id) {
    //Busca en el servidor el producto con el id que llega como paramtro, y setea produco_selected
    try {


        var url = `/productos/p/${id}`;
        await Vue.axios({
            method: 'GET',
            url: url,
        }).then(response => {

            commit('set_producto_selected', response.data)

        })
    } catch (error) {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    } finally {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }
}

export async function eliminar_producto({ commit, state }, id) {
    try {


        var url = `/products/${id}`;
        await Vue.axios({
            method: 'DELETE',
            url: url,
        }).then(response => {

            commit('quit_producto_for_update', id)

        })
    } catch (error) {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    } finally {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }
}


export function clean_filters({ commit, dispatch }) {
    commit('clean_filters')
    dispatch('get_productos_filters')

}

export async function get_vianda({ commit, state }, id) {
    //Busca en el servidor el producto con el id que llega como paramtro, y setea produco_selected
    try {

        const response = await ProductosAPI.getVianda()
        commit('SET_VIANDA', response.data.results)


    } catch (error) {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    } finally {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }
}

export async function registrarProductosMedianteListaDePrecios({commit, state}) {
    return new Promise((resolve, reject) => {
        var data = {proveed}
        ProductosAPI.registrarProductosMedianteListaDePrecios(producto).then(response => {
            context.commit("REMOVE_ITEM_INVENTARIO", response)
            resolve(response);
        }).catch(e => {
            reject(e);
        })
    })
}