

import Vue from 'vue'
import axios from 'axios'
import ContabilidadAPI from "@/api/contabilidad"
import ContabilidadAsientosTipoAPI from '@/api/contabilidad/asientostipo'
import ContabilidadSucursal from '@/api/contabilidad/sucursal'




export default {
  namespaced: true,
  state: { planDeCuentas: [], rubrosVenta: [], rubrosCompra: [], loadingPlanCuentas: false, asientosTipo: [], contabilidadSucursal: null },
  mutations: {
    SET_PLAN_CUENTAS(state, data) {
      state.planDeCuentas = data
    },
    SET_RUBRO_VENTAS(state, data) {
      state.rubrosVenta = data
    },
    SET_RUBRO_COMPRAS(state, data) {
      state.rubrosCompra = data
    },
    SET_LOADING_PLAN_CUENTAS(state, bool) {
      state.loadingPlanCuentas = bool
    },
    UPDATE_CUENTA(state, cuenta_nueva) {
      // Función recursiva para buscar la cuenta en el árbol
      const buscarCuenta = (nodos, id) => {
        for (const nodo of nodos) {
          // Verificar si el nodo actual es la cuenta buscada
          if (nodo.id === id) {
            return nodo;
          }
          // Si el nodo tiene hijos, buscar en ellos recursivamente
          if (nodo.children && nodo.children.length > 0) {
            const cuentaEncontrada = buscarCuenta(nodo.children, id);
            if (cuentaEncontrada) {
              return cuentaEncontrada;
            }
          }
        }
        // Si no se encuentra la cuenta en ninguno de los nodos, retornar null
        return null;
      };

      // Buscar la cuenta en el árbol
      const cuentaActualizada = buscarCuenta(state.planDeCuentas, cuenta_nueva.id);

      // Si se encontró la cuenta en el árbol
      if (cuentaActualizada) {
        // Actualizar los datos de la cuenta
        cuentaActualizada.nombre = cuenta_nueva.nombre;
        cuentaActualizada.codigo = cuenta_nueva.codigo;

        // Puedes agregar aquí la actualización de otros campos si es necesario

        // Emitir un mensaje de depuración para verificar que la cuenta se encontró correctamente
      } else {
        // Emitir un mensaje de advertencia si la cuenta no se encontró en el árbol
        console.warn('No se encontró la cuenta en el árbol:', cuenta_nueva.id);
      }
    },
    REMOVE_CUENTA(state, cuentaId) {
      // Función recursiva para buscar y eliminar la cuenta del árbol
      const eliminarCuenta = (nodos, id) => {
        for (let i = 0; i < nodos.length; i++) {
          const nodo = nodos[i];
          if (nodo.id === id) {
            nodos.splice(i, 1); // Elimina el nodo del array
            return true; // Retorna true para indicar que se encontró y eliminó la cuenta
          }
          if (nodo.children && nodo.children.length > 0) {
            if (eliminarCuenta(nodo.children, id)) {
              return true; // Retorna true si se encontró y eliminó la cuenta en los hijos
            }
          }
        }
        return false; // Retorna false si no se encontró la cuenta en este nivel del árbol
      };
      // Buscar y eliminar la cuenta del árbol
      eliminarCuenta(state.planDeCuentas, cuentaId);

    },
    SET_ASIENTOS_TIPO(state, data) {
      state.asientosTipo = data
    },
    SET_CONTABILIDAD_SUCURSAL(state, data) {
      state.contabilidadSucursal = data
    }

  },
  getters: {

  },
  actions: {

    async get_consumo_empleado(context, id) {
      const response = await EmpleadoAPI.getConsumoEmpleado(id)
      context.commit('SET_CONSUMO_EMPLEADO', response.data)


    },
    async fetchPlanDeCuentas(context, is_active = false) {
      context.commit('SET_LOADING_PLAN_CUENTAS', true)
      try {
        const response = await ContabilidadAPI.indexPlanCuentas()
        context.commit('SET_PLAN_CUENTAS', response.data)
      } catch (error) {

      } finally {
        context.commit('SET_LOADING_PLAN_CUENTAS', false)

      }

    },

    registrar_cuenta(context, cuenta) {
      return new Promise((resolve, reject) => {
        console.log(cuenta);
        ContabilidadAPI.create(cuenta).then(response => {

          resolve(response);
        }).catch(e => {
          reject(e);
        })
      })

    },

    actualizar_cuenta(context, cuenta) {
      return new Promise((resolve, reject) => {
        console.log(cuenta);
        ContabilidadAPI.update(cuenta).then(response => {
          console.log("actualizar_cuenta: Response", response);
          resolve(response);
        }).catch(e => {
          console.log("actualizar_cuenta e->", e);
          reject(e);
        })
      })

    },
    eliminar_cuenta(context, cuenta) {
      return new Promise((resolve, reject) => {
        console.log(cuenta);
        ContabilidadAPI.delete(cuenta).then(response => {

          resolve(response);
        }).catch(e => {
          reject(e);
        })
      })

    },
    registrar_rubro_venta(context, rubro) {
      return new Promise((resolve, reject) => {
        ContabilidadAPI.createRubroVenta(rubro).then(response => {

          resolve(response);
        }).catch(e => {
          reject(e);
        })
      })

    },
    actualizar_rubro_venta(context, rubro) {
      return new Promise((resolve, reject) => {
        ContabilidadAPI.updateRubroVenta(rubro).then(response => {

          resolve(response);
        }).catch(e => {
          reject(e);
        })
      })

    },
    eliminar_rubro(context, rubro) {
      return new Promise((resolve, reject) => {
        console.log(rubro);
        ContabilidadAPI.deleteRubro(rubro).then(response => {
          context.dispatch('fetch_rubro_ventas');
          resolve(response);
        }).catch(e => {
          reject(e);
        })
      })

    },


    async fetch_rubro_ventas(context) {
      try {
        const response = await ContabilidadAPI.indexRubroVentas()
        console.log(response);
        context.commit('SET_RUBRO_VENTAS', response.data)
      } catch (error) {

      }
    },


    registrar_rubro_compra(context, rubro) {
      return new Promise((resolve, reject) => {
        ContabilidadAPI.createRubroCompra(rubro).then(response => {

          resolve(response);
        }).catch(e => {
          reject(e);
        })
      })

    },
    actualizar_rubro_compra(context, rubro) {
      return new Promise((resolve, reject) => {
        ContabilidadAPI.updateRubroCompra(rubro).then(response => {

          resolve(response);
        }).catch(e => {
          reject(e);
        })
      })

    },
    eliminar_rubro_compra(context, rubro) {
      return new Promise((resolve, reject) => {
        console.log(rubro);
        ContabilidadAPI.deleteRubroCompra(rubro).then(response => {
          context.dispatch('fetch_rubro_compras');
          resolve(response);
        }).catch(e => {
          reject(e);
        })
      })

    },


    async fetch_rubro_compras(context) {
      try {
        const response = await ContabilidadAPI.indexRubroCompras()
        console.log(response);
        context.commit('SET_RUBRO_COMPRAS', response.data)
      } catch (error) {

      }
    },
    async fetch_asientos_tipo(context) {
      if (context.state.asientosTipo.length > 0) {
        // Si ya hay asientos tipo cargados, no hace falta hacer el fetch
        return;
      }

      try {
        const response = await ContabilidadAsientosTipoAPI.index();
        context.commit('SET_ASIENTOS_TIPO', response.data);
      } catch (error) {
        console.error('Error fetching asientos tipo:', error);
      }
    },
    async registrar_asiento_tipo(context, data) {
      return new Promise((resolve, reject) => {
        ContabilidadAsientosTipoAPI.create(data).then(response => {

          context.dispatch('fetch_asientos_tipo');
          resolve(response);
        }).catch(e => {
          reject(e);
        })
      })

    },
    eliminar_asiento_tipo(context, data) {
      return new Promise((resolve, reject) => {
        ContabilidadAsientosTipoAPI.delete(data).then(response => {
          context.dispatch('fetch_asientos_tipo');
          resolve(response);
        }).catch(e => {
          reject(e);
        })
      })

    },
    actualizar_asiento_tipo(context, data) {
      return new Promise((resolve, reject) => {
        ContabilidadAsientosTipoAPI.update(data).then(response => {
          context.dispatch('fetch_asientos_tipo');
          resolve(response);
        }).catch(e => {
          reject(e);
        })
      })

    },

    async fetch_contabilidad_sucursal(context) {
      try {
        const response = await ContabilidadSucursal.index()
        console.log(response);
        context.commit('SET_CONTABILIDAD_SUCURSAL', response.data)
      } catch (error) {

      }
    },
    update_contabilidad_sucursal(context, data) {
      return new Promise((resolve, reject) => {
        ContabilidadSucursal.update(data).then(response => {
          resolve(response);
        }).catch(e => {
          console.log(e);
          
          reject(e);
        })

      })

    },


  }
}