import axios from 'axios'
export default {
    index( paginacion ){
   
    },
    fetch(){

        return axios.get(`/sucursales/sucursales`)
    },
    getInfo(){
        var url = `/sucursales/sucursal`
        return axios.get(url) 
    },
    validate(sucursal_uuid){
        const url = `/sucursales/validate/${sucursal_uuid}`;
        return axios.get(url) 
    },
    validate_caja(){
        var url = `/sucursales/caja/validate`
        return axios.get(url) 
    },
    fetchConfig(){

        return axios.get(`/sucursales/configuracion/`)
    },
    fetchParametros(){

        var url = `/sucursales/tipo-parametro`
        return axios.get(url)
    },
    getCaja(caja_id){

        return axios.get(`/sucursales/cajas/${caja_id}`)
    },
    getCajaPorArqueo(){
        return axios.get(`/sucursales/mi_caja`)

    },
    getArqueoVigente(){
        return axios.get(`/sucursales/arqueo`)
    },


    show( id ){
    },

    update( id, data ){
        
    },
    

}