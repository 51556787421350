import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import moment from 'moment-timezone'

require('./plugins/vue-axios')
require('./plugins/vue-cookies')
require('./plugins/vee-validate')


moment.tz.setDefault("America/Argentina/Cordoba")
moment.locale('es');
Vue.prototype.moment = moment
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.prototype.$updateBus = new Vue;

Vue.filter('convertPesoArg', function (value) { return `$ ${new Intl.NumberFormat("de-DE").format(value)}` })

Vue.config.errorHandler = function (error, vm, info) {
  // Manejar el error aquí
  console.error('Error en el componente:', error);
  console.error('Componente:', vm);
  console.error('Información adicional:', info);
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
