<template>
  <v-container fluid v-if="!isLogged">
    <!-- <v-row> <v-img contain height="200" src="@/assets/LSVG.svg"></v-img></v-row> -->

    <v-row align="center" justify="center" style="height: 800px">
      <v-col xs="12" sm="8" md="8">
        <v-row>
          <v-col>
            <v-card class="d-flex align-center mb-6">
              
              <v-container>
                <v-card-title> ¡Bienvenido {{ empresa_nombre }}! </v-card-title>
              </v-container>
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <v-container>
                <v-card-text>
                  <v-alert
                    class="mb-4"
                    v-if="error"
                    text
                    prominent
                    type="error"
                    icon="mdi-cloud-alert"
                    >{{ errorMessage }}
                  </v-alert>
                  <div class="text-center headline mb-4">Iniciar sesión</div>
                  <v-form @submit.prevent="submit()" data-vv-scope="form-login">
                    <v-text-field
                      autocomplete="off"
                      outlined
                      autocapitalize="sentences"
                      label="Usuario"
                      v-model="user.username"
                      v-validate="'required|min:1|max:25'"
                      :error-messages="errors.collect('usuario')"
                      data-vv-name="usuario"
                      name="nombre"
                    />
                    <v-text-field
                      autocomplete="off"
                      v-validate="'required|min:5|max:25'"
                      :error-messages="errors.collect('contraseña')"
                      data-vv-name="contraseña"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      outlined
                      label="Contraseña"
                      v-model="user.password"
                      name="contraseña"
                    />

                    <v-spacer />
                    <v-btn
                      @keyup.enter="submit"
                      x-large
                      rounded
                      block
                      type="submit"
                      :disabled="errors.any()"
                      color="#AAA9AD"
                      >Iniciar sesión</v-btn
                    >
                  </v-form>
                </v-card-text>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
     
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { getStorage } from "@/services/serviceLocalStorage";

export default {
  name: "Login",
  $_veeValidate: {
    validator: "new",
  },
  computed: {
    ...mapState(["error", "errorMessage"]),
    ...mapGetters("empresa", ["empresa_nombre"]),
    ...mapState("auth", ["isLogged"]),

  },
  data() {
    return {
      showPassword: false,
      user: {
        username: "",
        password: "",
        //  username: "juli",
        // password: "julirover",
      },
    };
  },

  methods: {
    ...mapActions("auth", ["signIn"]),
    async submit() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          var credentials = {
            username: this.user.username,
            password: this.user.password,
          };
          this.signIn(credentials);
        } else {
          this.$validator.validate();
        }
      });
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", { layout: "layout-login" });
  },
};
</script>

<style scoped></style>
