export default {
    paginacion_productos_admin: { page: 1, cantidad: 0, rowsPerPage: 30 },

    //Obtiene todos los productos cuando se ingresa al listado de productos
    productos_admin: [],
    productos_admin_selected: [],
    productos_admin_seleccion_manual: false,
    productos_inventario: [],
    productos_filters: [],
    loading_productos_admin: false,

    descripcionBuscadorAdmin: null,
    search_data: '',

    error: false,
    errorMessage: '',

    // Lo utilizo para limipar el producto seleccionado cuando elimina, esta en productoTabla

    //Si es true, se registraran multiples productos de una sola marca
    marca_unica: true,

    // Estos 4 se se utilizan para enviar al server

    producto_registrar_multiple: {
      productos: [],
      marca : {id: null},
      proveedor: null,
      categoria: {id: null},
      es_marca_unica: false,
      es_categoria_unica:true,
      es_proveedor_unico:true,
      es_elaboracion_propia: false,
    },
    proveedor_register: null,
    marca_register: null,
    tipoProducto_register: null,
    producto_register : {
        productos: [],
        marca : null,
        proveedor: null,
        categoria: null,
    },

    //Bandera para cuando se registra producto
    producto_repetido: false,

    refresh:false,
    //Se utiliza cuando se selecciona un producto para editar, cuando se registra el producto
    producto_selected: null,
    
    productos_update: [],
    productos_reporte: [],
    filters: [],
    productos_selected: [],
    filters_for_productos_admin: [],

    configuracion: {
      actualizar: {
        filtro: {
          lateral: false,
          frontal: false,
        }
      }
    },

    productos_mas_vendidos: [],
    refresh_producto_frecuente: false,
    refresh_producto: null,
    loading_mas_vendidos: false,
    vianda: null,
}