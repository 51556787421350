export default {

  //''' VENTA '''

  // REGISTRO DE PEDIDO
  //Formato para enviar el pedido al servidor
  pedidoClienteVenta: null,
  pedidoClienteDireccion: null,
  pedidoEsParaEnviar: false,

  pedido_venta_register: {
    cliente: {
      nombre: null,
      telefono: null,
      paraEnviar: false,
    },
    envio: {
      selected: "",
      calle: "",
      altura: "",
      piso: "",
      depto: "",
      observacionEnvio: "",
    },
    detalle_pedido: []
  },
  views_detalle_pedido_venta: [],




  // ----- 
  pedido_compra_register: {
    proveedor: null,
    detalle_pedido: []

  },
  views_detalle_pedido_compra: [],
  


  //Pedidos obtenidos del servidor
  pedidos_compra_pendientes: [],
  pedidos_compra_aprobados: [],
  pedidos_compra_finalizados: [],

  pedidos_venta_pendientes: [],
  pedidos_venta_aprobados: [],
  pedidos_venta_finalizados: [],
  pedidos_finalizados: [],
  pedidos_venta: [],

  // REGISTRO DE PEDIDO
  //Formato para enviar el pedido al servidor
  pedido_register: {
    proveedor: null,
    detalle_pedido: []
  },

  //Tiene los detalles que se estan cargando en el pedido con el formato requerido para visualizacion
  views_detalle: [],

  // ACTUALIZACION DE PEDIDO
  //Obtiene el  pedido  selecccionado con los detalles del back
  pedido_con_detalle: null,
  //Contiene los detalles del pedido seleccionado para actualizacion que fueron modificados, eliminados o registrados
  detalles_update: [],

  //////

  //Bandera para indicar que se registro con éxito el pedido, 'FormProveedor' tiene un watch a este estado para limpiar su campo seleccionado
  register_success: false,

  //En el caso que quiera registrar un detalle que ya esta registrado, mustra el mensaje de error
  error_detalle_repetido: {
    state: false,
    message: "Se cambió la cantidad"
  },
  deshacer: {
    state: false,
    message: "Detalle de pedido eliminado",
    button: "Deshacer"
  },
  item_para_deshacer: { para_mostrar: {}, para_enviar: {} }



}