import Vue from 'vue'
import router from '@/router'




//Este lo usa modulo producto
export async function getProductosFromSearch(context, input_search) {
    try {
        // context.commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root
        if (input_search !== null) {
            context.commit('set_input_search', input_search)

        }

        var param_url = ''
        if (context.state['filters_for_productos_admin'].length > 0) {
            param_url = '&'
            for (var i = 0; i < context.state['filters_for_productos_admin'].length; i++) {

                param_url = param_url.concat(context.state['filters_for_productos_admin'][i].key, '=', context.state['filters_for_productos_admin'][i].value, '&')
            }
        }
        var paginacion = context.state['paginacion'];
        if (context.state['search_data']
        ) {
            var search = context.state['search_data']
        } else {
            var search = ''
        }
        //Search es lo que escribio en el buscador
        var url = `/productos/products_list_search/search?q=${search}&page=${paginacion.page}&page_size=${paginacion.rowsPerPage}${param_url}`;
        context.commit('set_url_productos_admin', url)


        const response = await Vue.axios({
            method: 'GET',
            url: url,
        })
        if (input_search !== null) {

            context.commit('set_input_search', input_search)

        }
        context.commit('set_productos_admin', response.data)

    } catch (error) {

        var e = error.response
        // context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    } finally {
        // context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    }



}

export async function eliminar_producto_buscador_admin({ commit, state }, id) {
    try {


        var url = `/products/${id}`;
        await Vue.axios({
            method: 'DELETE',
            url: url,
        })

        commit('quit_producto_admin', id)


    } catch (error) {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    } finally {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }
}


export async function get_productos_filters({ commit, state }) {
    try {
        // if (state['productos_admin'].length > 0) {

        // } else {

        // }
        commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root
        var param_url = ''
        if (state['filters_for_productos_admin'].length > 0) {
            for (var i = 0; i < state['filters_for_productos_admin'].length; i++) {

                param_url = param_url.concat(state['filters_for_productos_admin'][i].key, '=', state['filters_for_productos_admin'][i].value, '&')
            }
            var url = `/productos/products_filter?${param_url}`;
            const response = await Vue.axios({
                method: 'GET',
                url: url,
            })
            commit('set_productos_admin', response.data)
        } else {
            if (state['descripcionBuscadorAdmin'] !== null || state['descripcionBuscadorAdmin'] !== '') {
                var url = `/products_search/search?q=${state['descripcionBuscadorAdmin']}&page=1&page_size=10`;

                const response = await Vue.axios({
                    method: 'GET',
                    url: url,
                })
                commit('set_productos_admin', response.data.results)
            }
        }



    } catch (error) {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    } finally {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }
}

