import axios from 'axios'
export default {
    index(codigo){
        var tp = codigo ? codigo : null
        var url = `/sucursales/parametros?codigo=${tp}`
        return axios.get(url)
    },
    tipo_parametros(){
        var url = `/sucursales/tipo-parametros`
        return axios.get(url)
    },

    show( id, alerta ){
        var alerta= "RETIRO"
        var url = `/sucursales/configuracion/alerta?a=${alerta}`
        return axios.get(url)
    },

    update( field, data ){
        var url = `/sucursales/parametros/${data.id}?_field=${field}`
        return axios.put(url, {
            value: data[field],
          }) 
    },

    create( data ){
    },
    

  
}