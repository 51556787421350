import store from '@/store/index'
export default [
  {
    path: '/compras',

    component: () => import(/* webpackChunkName: "about" */ '@/views/compra/compras.vue'),
    children: [{

      path: '',
      name: 'compras',

      component: () => import(/* webpackChunkName: "about" */ '@/views/compra/compras.home.vue'),
    }

    ],


    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireModuloCompra: true }


  },
  {
    path: '/compras/pedidos',
    name: 'pedidoCompra',
    props: { tipoComprobante: 'compras' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/compra/pedidos/pedidos.compra.vue'),

    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireModuloCompra: true }



  },

  {
    path: '/compras/pedidos/nuevo',
    name: 'nuevoPedidoCompra',

    component: () => import(/* webpackChunkName: "about" */ '@/views/compra/pedidos/pedido.compra.registrar.vue'),

    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireModuloCompra: true }




  },
  {
    path: '/compras/pedidos/actualizar/:id',
    name: 'ActualizarPedidoCompra',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/compra/pedidos/pedido.compra.actualizar.vue'),

    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

  },
  {
    path: '/compras/remitos',
    name: 'remitoCompra',
    props: { tipoComprobante: 'compras' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/compra/remitos/remito.compra.vue'),

    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireModuloCompra: true }



  },
  {
    path: '/compras/remitos/consultar',
    name: 'remitosConsultar',
    props: { tipoComprobante: 'compras' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/compra/remitos/remitos.consultar.vue'),

    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireModuloCompra: true }



  },
  {
    path: '/compras/remitos/consultar/:id',
    name: 'remitosConsultarDetalle',
    props: { tipoComprobante: 'compra' },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/compra/remitos/remito.compra.consultar.detalle.vue'),

    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

  },


  {
    path: '/compras/remitos/nuevo',
    name: 'nuevoRemitoCompra',
    props: { tipoComprobante: 'compras' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/compra/remitos/remito.compra.registrar.vue'),

    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireModuloCompra: true }



  },
  {
    path: '/compras/pedidos/consultar',
    name: 'pedidosCompraConsultar',
    props: { tipoComprobante: 'compra' },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/compra/pedidos/pedido.consultar.vue'),


    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

  },
  {
    path: '/compras/pedidos/consultar/:id',
    name: 'pedidoCompraDetalle',
    props: { tipoComprobante: 'compra' },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/compra/pedidos/pedido.compra.item.vue'),

    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

  },


  {
    path: '/compras/factura',
    name: 'facturaCompra',
    component: () => import(/* webpackChunkName: "about" */ '@/views/compra/factura/FacturaCompraView.vue'),

    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireModuloCompra: true }



  },
  {
    path: '/compras/factura/nuevo',
    name: 'nuevaFacturaCompra',
    component: () => import(/* webpackChunkName: "about" */ '@/views/compra/factura/FacturaCompraRegistrar.vue'),

    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireModuloCompra: true }



  },
  {
    path: '/compras/factura/:id',
    name: 'facturaItem',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/compra/factura/FacturaCompraItemView.vue'),

    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

  },



]
