import Vue from 'vue'
import axios from 'axios'
import router from '@/router'





// ACTUALIZACION
export async function get_productos_filters({ commit, state }) {
    try {
        // id = 0 -> para actualizar productos
        //id = 1 -> para reportes

        commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root

        var param_url = ''
        for (var i = 0; i < state['filters_reporte'].length; i++) {

            param_url = param_url.concat(state['filters_reporte'][i].key, '=', state['filters_reporte'][i].value, '&')
        }
        var url = `/productos/products_filter?${param_url}`;
        await Vue.axios({
            method: 'GET',
            url: url,
        }).then(response => {
                
                commit('set_productos_reporte', response.data)
           

        })
    } catch (error) {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    } finally {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }
}

//Busca en el servidor el producto con el id que llega como paramtro, y setea produco_selected
export async function get_producto({ commit, state }, id) {
    try {


        var url = `/products/${id}`;
        await Vue.axios({
            method: 'GET',
            url: url,
        }).then(response => {

            commit('set_producto_selected', response.data)

        })
    } catch (error) {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    } finally {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }
}

export async function eliminar_producto({ commit, state }, id) {
    try {


        var url = `/products/${id}`;
        await Vue.axios({
            method: 'DELETE',
            url: url,
        }).then(response => {

            commit('quit_producto_for_update', id)

        })
    } catch (error) {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root


    } finally {
        commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }
}





export function clean_filters({ commit, dispatch }) {
    commit('clean_filters')
    dispatch('get_productos_filters')

}


// export async function getProductosPaginados(context) {
//     try {

//         context.commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root
//         var paginacion = context.state['paginacion'];
//         var url = `/products_page?page=${paginacion.page}&page_size=${paginacion.rowsPerPage}`;
//         await Vue.axios({
//             method: 'GET',
//             url: url,
//         }).then(response => {
//             context.commit('setProductos', response.data.results)
//             context.commit('set_cantidad_productos_admin', response.data.count)
//             context.commit('setNextPrevious', response.data)
//         })


//     } catch (error) {
//         context.commit('getError', e)
//     } finally {
//         context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

//     }

// }