import axios from 'axios'
const url = '/contabilidad/asiento-tipo'

export default {
    create(data){
        return axios.post(url, data)
    },
    update(data) {
        return axios.put(`${url}/${data.id}`, data)
    },
    index(){
        return axios.get(url)
    },
    delete(data){

        return axios.delete(`${url}/${data.id}`)

    },    
    show(id) {
        return axios.get(`${url}/${id}`)
    },
   

}