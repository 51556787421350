export function setProductos(state, response) {

    // state.productos.results = []


    state.productos = response
    state.paginacion.cantidad = response.count
    state.error = false
    state.errorMessage = ''
}

export function set_productos_admin(state, response) {



    state.productos_admin = response
    state.paginacion.cantidad = response.count


}

export function set_descripcion_input_search(state, d) {
    state.descripcionBuscadorAdmin = d
}

export function setPage(state, page) {
    state.paginacion.page = page


}
export function setDescripcion(state, d) {
    state.descripcion = d.split("-").join(" ");
}
export function set_input_search(state, input_search) {

    var replaced = input_search.split(" ").join("-");
    state.search_data = replaced
}

export function set_url_productos_admin(state, data) {
    state.url_data = data
}

export function setProductosSearchNextPrevious(state, data) {

    state.productosSearch.productos = []

    state.productosSearch.productos = data.results
    state.productosSearch.next = data.next
    state.productosSearch.previous = data.previous


    state.productosSearch.cantidad = data.count

}

export function setNextPrevious(state, data) {
    state.next = data.next
    state.previous = data.previous
}


export function getError(state, error) {
    state.error = true
    state.errorMessage = error + ". Contacte con el administrador"
    state.productos = []
}

export function filterProductos(state, filter) {

    var resultado = []
    for (let i = 0; i < state.productos_admin.length; i++) {
        if (state.productos_admin[i].categoriaArbol.id == filter.value) {

            resultado.push(state.productos_admin[i])
        }
    }
    state.productos_admin = resultado

    // let element = productos_encontrados.find(d => d.categoriaArbol.id == filter.value)
    // console.log("ELEMENT:",element);
    // if (element) {
    //     for (let i = 0; i < state.filters_for_productos_admin.length; i++) {
    //         if (state.filters_for_productos_admin[i].key == element.key) {

    //             state.filters_for_productos_admin[i] = filter
    //         }
    //     }
    // } else {
    //     state.filters_for_productos_admin.push(filter);

    // }

}
export function filterProductosForName(state, descripcion) {

    var resultado = []
    for (let i = 0; i < state.productos_admin.length; i++) {
        if (state.productos_admin[i].descripcion == descripcion) {

            resultado.push(state.productos_admin[i])
        }
    }
    state.productos_admin = resultado

    // let element = productos_encontrados.find(d => d.categoriaArbol.id == filter.value)
    // console.log("ELEMENT:",element);
    // if (element) {
    //     for (let i = 0; i < state.filters_for_productos_admin.length; i++) {
    //         if (state.filters_for_productos_admin[i].key == element.key) {

    //             state.filters_for_productos_admin[i] = filter
    //         }
    //     }
    // } else {
    //     state.filters_for_productos_admin.push(filter);

    // }

}

// FILTROS
export function clean_filters(state) {
    state.filters_for_productos_admin = []
}
export function addFilter(state, filter) {
    console.log(filter);
    let element = state.filters_for_productos_admin.find(d => d.key == filter.key)

    if (element) {
        for (let i = 0; i < state.filters_for_productos_admin.length; i++) {
            if (state.filters_for_productos_admin[i].key == element.key) {
                state.filters_for_productos_admin.splice(i, 1);

                state.filters_for_productos_admin.push(filter);

            }
        }
    } else {
        state.filters_for_productos_admin.push(filter);

    }

}
export function quitFilter(state, filter) {


    const index = state.filters_for_productos_admin.indexOf(filter);

    if (index > -1) {

        state.filters_for_productos_admin.splice(index, 1);
        // reset_filter(filter)


    }


}