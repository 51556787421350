export default [
  {
    path: '/productos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductosHome.vue'),
    children: [
      // UserHome will be rendered inside User's <router-view>
      // when /user/:id is matched
      {

        path: '',
        name: 'productosListado',
        component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductoHomeListaProductos.vue'),
      },
      
      {
        path: 'inventario',
        name: 'productoInventarioGestion',
        component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductoHomeInventarioGestion.vue'),


      },
    
      {
        path: 'inventario/control',
        name: 'inventarioControl',
        component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductoHomeInventarioControl.vue'),


      },
      {

        path: 'editor-masivo',
        name: 'productosEditorMasivo',
        component: () => import(/* webpackChunkName: "about" */ '@/components/producto/CmpProductoEditorMasivo.vue'),
        


      },
      
  //       component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductoHomeInventarioControl.vue'),
     
    ],
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireVentaConPedido: false }
  },
  {
    path: '/productos/listadeprecios',
    name: 'productoslistadeprecios',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/components/producto/registrar/productos.listadeprecios.vue'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireVentaConPedido: false }





  },
  {

    path: '/productos/:id',
    name: 'productoHome',
    component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductoItemHome.vue'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

    


  },
  {

    path: '/productos/:id/historial',
    name: 'productoItemHistorial',
    component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductoItemHistorial.vue'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }
  },
  {
    path: '/productos/:id_producto/inventario',
    name: 'productoInventarioDetail',
    component: () => import(/* webpackChunkName: "about" */ '@/views/producto/inventario/ProductoItemInventario.vue'),


  },
  {
    path: '/productos/:id/editar',
    name: 'actualizarProducto',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/producto/actualizar/producto.actualizar.vue'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }




  },
 
  // {
  //   path: '/productos/editor-masivo',
  //   name: 'productosEditorMasivo',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/producto/actualizar/ProductosEditorMasivo.vue'),
  //   meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }


  // },
  {
    path: '/productos/registrar/lista-de-precios/:id_proveedor',
    name: 'productosListasDePreciosRegistrar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ListaDePrecios.vue'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }


  },
  
  {
    path: '/productos/lista/consultarPrecios',
    name: 'consultarPrecios',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductoConsultarPrecio.vue'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }


  },
  {
    path: '/productos/editor-masivo2',
    name: 'productosEditorMasivo2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/producto/actualizar/producto.actualizar.multiple.home.vue'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }


  },
  {
    path: '/productos/registrar/home',
    name: 'nuevoProducto',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductoHomeRegistrar.vue'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, navHome: false }


  },
  {
    path: '/productos/registrar/simple',
    name: 'nuevoProductoSimple',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductoHomeRegistrarSingle'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }


  },
  {
    path: '/productos/registrar/multiple',
    name: 'nuevoProductoMultiple',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductoHomeRegistrarMultiple'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }


  },
  {
    path: '/productos/actualizar',
    name: 'actualizarHome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/producto/actualizar/producto.update.home.vue'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

  },

 
  
  // {


  //   path: '/productos/inventario',
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductoHomeInventario.vue'),
  //   props: {
  //     title: 'Inventario',
  //     // function mode, more about it below
  //   },
  //   children: [
  //     // UserHome will be rendered inside User's <router-view>
  //     // when /user/:id is matched
  //     {
  //       path: '',
  //       name: 'productoInventarioGestion',
  //       component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductoHomeInventarioGestion.vue'),


  //     },
  //     {
  //       path: 'control',
  //       name: 'inventarioControl',
  //       component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductoHomeInventarioControl.vue'),


  //     },
     
  //   ],
  //   meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }




  // },
  
  {


    path: '/productos/fraccionados',
    component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductosHomeFraccionados.vue'),
    children: [
      // UserHome will be rendered inside User's <router-view>
      // when /user/:id is matched
      {
        path: '',
        name: 'productosFraccionados',
        component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductosFraccionados.vue'),


      },
      {
        path: ':id',
        name: 'añadirProductoFraccionado',
        component: () => import(/* webpackChunkName: "about" */ '@/views/producto/ProductosFraccionadosItem.vue'),


      },
     
     
    ],
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }




  },
]