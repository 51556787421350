import axios from 'axios'
const url = '/empleados/empleado'

export default {
    create(data){
        return axios.post(url, data)
    },
    index(is_active){
        return axios.get(url+`?is_active=${is_active}`)
    },
    indexConsumo(){
        return axios.get('empleados/consumo')
    },
    getMiConsumo(url){
        return axios.get(`empleados/miconsumo`)

    },
    getConsumoEmpleado(id){
        return axios.get(`empleados/consumo/${id}`)

    },
    
    show(id) {
        return axios.get(`${url}/${id}`)
    },
    update(data) {
        return axios.put(`${url}/${data.id}`, data)
    },
    pagarCtaCte(id_empleado) {
        return axios.get(`/comprobantes/deudaempleado/${id_empleado}`)
    },
    setActive(id_empleado, bool) {
        console.log("·saegsdgsd");
        var action = 'a'
        if (!bool){
            action = 'd'
        }
        return axios.put(`${url}/${id_empleado}`, {
            'status': action
        } )

    },

}