
export default [
    {
        path: '/sucursales',
        name: 'sucursales',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/sucursal/Sucursales.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: false, requireCaja: false, requireSucursal: false }

    },
    {
        path: '/sucursales/:uuid_sucursal',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/sucursal/SucursalDetail.vue'),
        children: [
            // UserHome will be rendered inside User's <router-view>
            // when /user/:id is matched
            {
              path: '',
              name: 'sucursalDatosGenerales',
              component: () => import(/* webpackChunkName: "about" */ '@/components/sucursal/SucursalDetallesGenerales.vue'),
            },
            {
                path: 'empleados',
                name: 'sucursalesEmpleados',
                component: () => import(/* webpackChunkName: "about" */ '@/components/sucursal/SucursalEmpleadosList.vue'),
              },
              {
                path: 'empleados/registrar',
                name: 'sucursalEmpleadosRegistrar',
                component: () => import(/* webpackChunkName: "about" */ '@/components/sucursal/SucursalEmpleadosRegistrar.vue'),
              },
              {
                path: 'editar',
                name: 'sucursalEditar',
                component: () => import(/* webpackChunkName: "about" */ '@/views/sucursal/sucursal.editar.vue'),
                
              },
              {
                path: 'duenos',
                name: 'sucursalDueño',
                component: () => import(/* webpackChunkName: "about" */ '@/components/sucursal/SucursalDueñoList.vue'),
              },
              {
                path: 'duenos/registrar',
                name: 'sucursalDueñoRegistrar',
                component: () => import(/* webpackChunkName: "about" */ '@/components/sucursal/SucursalEmpleadosRegistrar.vue'),
              },

              
          
        ],
        meta: { requiresAuth: true, requireArqueoCaja: false, requireCaja: false, requireSucursal: false }

    },

    
    {
        path: '/sucursales/registrar',
        name: 'registrarSucursal',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/sucursal/sucursal.registrar.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: false, requireCaja: false, requireSucursal: false }

    },
    // {
    //     path: '/sucursal/:id_sucursal/cajas',

    //     name: 'seleccionCaja',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '@/views/sucursal/seleccion.sucursal.caja.vue'),
    //     meta: { requiresAuth: true, requireArqueoCaja: false, requireCaja: false, requireSucursal: true }

    // },
    {
        path: '/cajas',

        name: 'cajas',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/sucursal/Cajas.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: false, requireCaja: false, requireSucursal: true }

    },
    {
        path: '/cajas/settings/:id_caja',

        name: 'cajaSettings',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/sucursal/CajaSettings'),
        meta: { requiresAuth: true, requireArqueoCaja: false, requireCaja: true, requireSucursal: true }

    },
    {
        path: '/cajas/:id_caja/arqueos',

        name: 'arqueos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/sucursal/seleccion.sucursal.caja.arqueo.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: false, requireCaja: true, requireSucursal: true }

    },
]