export default [
    {
        path: '/egresos',
        name: 'egresos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/egresos/egresos.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true  }
    
      },
      {
        path: '/egresos/nuevo',
        name: 'egresoNuevo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/egresos/egreso.registrar.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true  }
    
      },
      {
        path: '/egresos/gastos',
        name: 'egresosGastos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/egresos/gastos/EgresosGastos.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true  }
    
      },
      {
        path: '/egresos/categorias/:padre',
        name: 'EgresoCategoriaTree',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/components/egresos/categorias/EgresoCategoriasTree.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true  }
    
      },
      {
        path: '/egresos/categorias',
        name: 'egresosCategoria',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/egresos/EgresosCategoria.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true  }
    
      },
      {
        path: '/egresos/:id',
        name: 'egresoActualizar',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/egresos/egreso.actualizar.vue'),

        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true  }
    
      },
      {
        path: '/egresos/tipo-egreso',
        name: 'tipoIngresoEgreso',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/egresos/tipo.egreso.ingreso.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true  }
    
      },
      {
        path: '/egreso/buzon',
        name: 'buzon',
        redirect: { name: 'buzonHome'},
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/egresos/buzon/buzon.vue'),
        children: [
          {
            path: '',
            name: 'buzonHome',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '@/views/egresos/buzon/buzon.home.vue'),
        
          },
          {
            path: 'registrar',
            name: 'buzonRegistrar',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '@/views/egresos/buzon/buzon.registrar.vue'),
        
          },
          {
            path: 'historial',
            name: 'buzonHistorial',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '@/views/egresos/buzon/buzon.historial.vue'),
        
          },
        ],
        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true  }
      },
]