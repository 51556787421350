import Vue from 'vue'
import axios from 'axios'

//en contexto tenemos todo el objeto completo

export function registrar_empresa(context, data) {

    context.commit('setLoadingInit', true, { root: true }) //root true, se define la mutacion en la mutacion root

    //Guardo en el array 'detalles_formato_server'  el formato correcto para enviar al server

    return new Promise((resolve, reject) => {
        var url = '/empresas/'
        Vue.axios({
            method: 'POST',
            url: url,
            data: data,
        }).then(response => {
            resolve(response);
            context.commit('setLoadingInit', false, { root: true }) //root true, se define la mutacion en la mutacion root
        }).catch(e => {
            reject(e);
            context.commit('setLoadingInit', false, { root: true }) //root true, se define la mutacion en la mutacion root
        })
    })
}

export function actualizar_empresa(context, {data, uuid_empresa}) {

    context.commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root

    return new Promise((resolve, reject) => {
        var url = `/empresas/${uuid_empresa}`
        Vue.axios({
            method: 'PUT',
            url: url,
            data: data,
        }).then(response => {
            resolve(response);
            context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root
        }).catch(e => {
            reject(e);
            context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root
        })
    })
}

export const get_empresa = () => {
    return new Promise((resolve, reject) => {
        var url = `/empresas/`;
        axios.get(url).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        })
    })
};

export const actualizar_datos = (context) => {
    context.commit('setLoading', true, { root: true }) 
    return new Promise((resolve, reject) => {
        var url = `/productos/actualizardatos`;
        axios.get(url).then((response) => {
            resolve(response);
            context.commit('setLoading', false, { root: true }) 
            // commit('logUserIn',response.data.data);
        }).catch((error) => {
            reject(error);
            context.commit('setLoading', false, { root: true }) 
        })
    })
   
};

export async function get_pedido_con_detalle(context, id) {
    try {
        context.commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root
        var url = `/pedidos/${id}`;
        await Vue.axios({
            method: 'GET',
            url: url,
        }).then(response => {
            context.commit('set_pedido_con_detalle', response.data)
        })
    } catch (error) {
        console.log("ERROR EN registrar pedido : " + error)
    } finally {
        context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root
    }
}