
export default [
    {
        path: '/iniciarCaja',
        name: 'iniciarCaja',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/caja/caja.iniciar.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: false,requireCaja: true, requireSucursal: true }

    },
    {
        path: '/cerrarCaja',
        name: 'cerrarCaja',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/caja/caja.finalizar.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: false,requireCaja: true, requireSucursal: true }

    },
    {
        path: '/movimientos',
        name: 'movimientos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/caja/caja.movimientos.vue'),

        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true  }

    },
    {
        path: '/movimientos/retiro',
        name: 'movimientosRetiro',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/actividad/retiro.efectivo.caja.vue'),

        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true  }

    },

]