import store from '@/store/index'

function getDetallesParaServer(detalles) {
    var detalles_pedido_formato_server = []
    for (let i = 0; i < detalles.length; i++) {
        console.log(detalles[i]);
        if (detalles[i].isProducto) {
            detalles_pedido_formato_server.push({
                producto: detalles[i].producto.id,
                cantidad: detalles[i].cantidad,
                iva: detalles[i].producto.iva,

            });
        } else {
            detalles_pedido_formato_server.push({
                promocion: detalles[i].promocion.id,
                cantidad: detalles[i].cantidad,
            });
        }
    }
    return detalles_pedido_formato_server
}
function quitDetalle(detalles, detalle) {
    const index = detalles.indexOf(detalle);

    if (index > -1) {
        // index_register contiene el indice del array detalle_pedido, que se utiliza para enviar en formato correcto al servidor

        detalles.splice(index, 1);
        return detalles

    }

}
function controlaStock(detalles, detalle) {
    const cantidadDetalle = detalles[i].cantidad + detalle.cantidad
    if (detalle.producto.cantidadstock < cantidadDetalle) {
        console.warn("Stock insuficiente")
        return false
    }
    return true
}
function addDetalle(detalles, detalle, overwrite = false) {
    /*  ##  Hay dos formas de agregar detalle ##
        1º - Sumarle uno a la cantidad actual (si el detalle existe, sino se crea y cantidad igual a 1)
        2º - Asignarle una cantidad predefinida al detalle.
        La variable que define una u otra es overwrite
    */
    const moduloInventario = store.state.sucursales.configuracion.productoInventario
    
    if (detalle.producto) {
        let index = detalles.find(d => d.producto && d.producto.id == detalle.producto.id)
        if (index) {
            for (var i in detalles) {
                if (detalles[i].producto.id == detalle.producto.id) {
                    if (overwrite) {
                        if (moduloInventario && detalle.producto.controlaStock) {
                            if (detalle.producto.cantidadstock >= parseInt(detalle.cantidad)) {
                                detalles[i].cantidad = detalle.cantidad
                                store.commit('pedidos/set_detalle_repetido')
                            }else{
                                return "Stock insuficiente"
                            }
                        }else{
                            detalles[i].cantidad = detalle.cantidad
                            store.commit('pedidos/set_detalle_repetido')
                        }
                        
                    } else {
                        var cantidad = 1
                        console.log(detalle.producto);
                        console.log(detalle.producto.categoriaArbol.name.includes("PIZZA"));
                        if (detalle.producto.categoriaArbol.name.includes("PIZZA")){
                             cantidad = 0.5
                        }
                       
                       
                        const cantidadDetalle = detalles[i].cantidad + cantidad
                        if (moduloInventario && detalle.producto.controlaStock) {
                            if (detalle.producto.cantidadstock < cantidadDetalle) {
                                console.warn("Stock insuficiente")
                                return "Stock insuficiente"

                            }
                        }
                        detalles[i].cantidad = cantidadDetalle;
                    }
                }
            }
        }
        else {
            if (detalle.producto.categoriaArbol.name.includes("PIZZA")){
                cantidad = 0.5
                detalle.cantidad = cantidad
           }
            detalles.push(detalle);
        }
    } else {
        let index = detalles.find(d => d.promocion && d.promocion.id == detalle.promocion.id)
        if (index) {
            for (var i in detalles) {
                const cantidadDetalle = detalles[i].cantidad + 1
                detalles[i].cantidad = cantidadDetalle;
                // if (detalles[i].promocion.id == detalle.promocion.id) {
                //     detalles[i].cantidad = detalle.cantidad;
                //     break; //Stop this loop, we found it!
                // }
            }
            // store.commit('pedidos/set_detalle_repetido')
        }
        else {
            detalles.push(detalle);
        }
    }


}
function updateDetalle(detalles, detalle) {
    const index = detalles.indexOf(detalle);
    if (detalle.producto) {
        for (var i in detalles) {
            if (detalles[i].producto.id == detalle.producto.id) {
                detalles[i] = detalle;
                break; //Stop this loop, we found it!
            }
        }
    } else {
        for (var i in detalles) {
            if (detalles[i].promocion.id == detalle.promocion.id) {
                detalles[i] = detalle;
                break; //Stop this loop, we found it!
            }
        }
    }

}





export { quitDetalle, updateDetalle, addDetalle, getDetallesParaServer }