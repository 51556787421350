import axios from 'axios'
const url = '/clientes/cliente'
export default {
    index() {
        return axios.get(`${url}`)
    },

    show(id) {
        return axios.get(`${url}/${id}`)
    },
    create(data) {

        return axios.post(`${url}`, data)
    },
    updateContable(data) {
        const url = '/clientes/contable'
        return axios.put(`${url}/${data.id}`, data)

    },

    update(data) {
        return axios.put(`${url}/${data.id}`, data)
    },



    delete(id, data) {
        return axios.delete(`${url}/${id}`, data)


    },

}