import Vue from 'vue';

export function SET_SUCURSALES(state, sucursales) {
        state.sucursales = sucursales

}
export function SET_SUCURSAL(state, sucursal) {
        state.sucursal = sucursal

}

export function SET_CAJA(state, caja) {
        state.caja = caja

}
export function SET_ARQUEO_CAJA(state, arqueo) {
        console.log("Dentro de SET_ARQUEO_CAJA, ", arqueo);


        state.arqueoCaja = arqueo



}


export function SET_CAJA_ID_COOKIE(state, id) {
        // Establece la cookie
        var domain = process.env.VUE_APP_SUBDOMAIN_URL
        const key = '_cj_id'
        Vue.$cookies.set(key, 
                id, 
                '365d', // Duración en días (opcional)
                '/', // Ruta por defecto
                domain, // Establece el dominio
                !domain.includes('127') // Usar HTTPS (opcional)
                );
}
export function DELETE_CAJA_COOKIE(state) {
        // Establece la cookie
        const key = '_cj_id'
        Vue.$cookies.remove(key); // Cambia '7d' según tu necesidad
}


export function SET_CONFIGURACION(state, configuracion) {
        state.configuracion = configuracion

}
export function SET_SUCURSAL_UUID_COOKIE(state, uuid) {
        // Establece la cookie
        var domain = process.env.VUE_APP_SUBDOMAIN_URL
        const key = '_sl_id'
        Vue.$cookies.set(key, 
                uuid, 
                '365d', // Duración en días (opcional)
                '/', // Ruta por defecto
                domain, // Establece el dominio
                !domain.includes('127') // Usar HTTPS (opcional)
              );
}
export function DELETE_SUCURSAL_COOKIE(state) {
        // Establece la cookie
        const key = '_sl_id'
        Vue.$cookies.remove(key); // Cambia '7d' según tu necesidad
}
