<template>
  <v-app>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :right="snackbar.right"
      :timeout="snackbar.timeout"
      :top="snackbar.top"
    >
      <span class="title"
        ><v-icon large>{{ snackbar.icon }}</v-icon> {{ snackbar.text }}</span
      >
    </v-snackbar>

    <template>
      <v-main>
        <!-- <logged></logged> -->

        <login></login>

        <loading :active.sync="loading"></loading>
      </v-main>
    </template>
  </v-app>
</template>

<script>
import { getStorage } from "@/services/serviceLocalStorage";

import login from "@/views/usuarios/Login.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState, mapActions } from "vuex";

export default {
  name: "App",
  components: {
    Loading,
    login,
  },
  data: () => ({
    snackbar: {
      show: false,
      color: "",
      mode: "",
      timeout: null,
      text: "",
      top: true,
      right: true,
      icon: null,
      vertical: false,
    },
  }),
  computed: {
    ...mapState(["links", "loading"]),
  },
  methods: {},
  mounted() {
    this.$updateBus.$on("showSnackBar", (snackbar) => {
      this.snackbar = Object.assign(this.snackbar, snackbar);
    });
  },

  //   created() {
  //     if (this.isLogged && !this.loadData) {
  //       this.fetchInitConfig();
  //     }
  //   },
};
</script>
<style>
.v-btn {
  text-transform: none;
}
</style>
