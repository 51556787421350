export function QUITAR_FORMA_DE_PAGO(state, index) {
    state.formasDePagoSeleccionadas.splice(index,1)
}
export function RESET_FORMAS_DE_PAGO_SELECCIONADAS(state, monto) {
    state.formasDePagoSeleccionadas = [{nombre: 'EFECTIVO', monto: null}]
}
export function AGREGAR_FORMA_DE_PAGO(state, {descripcion, monto=null}) {
    console.log("AGREGAR_FORMA_DE_PAGO",monto);
    var item = { nombre: descripcion, monto: monto };
    state.formasDePagoSeleccionadas.push(item)
}
export function SET_MONTO_CREDITO(state, monto) {
    console.log(monto);
    state.montoCredito = monto
}
export function SET_MONTO_DEBITO(state, monto) {
    state.montoDebito = monto
}
export function SET_MONTO_MERCADOPAGO(state, monto) {
    state.montoMercadoPago = monto
}
export function SET_MONTO_TRANSFERENCIA(state, monto) {
    state.montoTransferencia = monto
}
export function SET_MONTO_FORMA_DE_PAGO(state, { nombre, monto }) {
    console.log(monto);
    // Encontrar la forma de pago correspondiente
    var forma_de_pago = state.formasDePagoSeleccionadas.find(fp => fp.nombre == nombre);
    
    if (forma_de_pago) {
        // Convertir monto a número, redondear a dos decimales y luego asignarlo
        var montoNumero = parseFloat(monto);
        if (!isNaN(montoNumero)) {
            forma_de_pago.monto = parseFloat(montoNumero.toFixed(2));
        } else {
            console.error('El monto proporcionado no es un número válido:', monto);
        }
    }
}

export function SET_PORCENTAJE_RECARGO_CREDITO(state, recargo) {
    state.porcentajeRecargoCredito = recargo
}
export function SET_DESCUENTO(state, descuento) {
    state.descuento = descuento
}
export function SET_MOTIVO_DESCUENTO(state, motivo) {
    state.motivoDescuento = motivo
}
export function SET_MONTO_CREDITO_SIN_RECARGO(state, montoCredito) {
    state.montoCredito = parseInt(montoCredito)
}
export function SET_SUBTOTAL(state, subtotal) {
    state.subtotal = subtotal
}
export function SET_IMPUESTOS(state, impuestos) {
    state.impuestos = impuestos
}


export function SET_CLIENTE_VENTA(state, cliente) {
    state.clienteVenta = cliente
}
export function SET_CLIENTE_DIRECCION_VENTA(state, direccion) {
    state.clienteDireccion = direccion
    // if (direccion) {
    //     // Agrego la propiedad de esta manera para que sea reactivo clienteVenta.direccion.
    //     // Si lo agrego como state.clienteVenta.direccion = direccion no es reactiva al cambio el state clienteVenta
    //     state.clienteVenta = { ...state.clienteVenta, direccion: direccion }
    // } else {
    //     // Eliminar la propiedad direccion del objeto clienteVenta

    //     // Desestructuración del objeto state.clienteVenta para extraer la propiedad 'direccion'
    //     // y crear un nuevo objeto 'clienteVentaSinDireccion' sin esa propiedad.
    //     const { direccion, ...clienteVentaSinDireccion } = state.clienteVenta;

    //     // Actualizar el estado 'clienteVenta' con el nuevo objeto 'clienteVentaSinDireccion'
    //     state.clienteVenta = clienteVentaSinDireccion;
    // }
}

export function SET_ES_PARA_ENVIAR(state, bool) {
    state.esParaEnviar = bool
}

export function RESET_CLIENTE_VENTA(state){
    state.clienteVenta = null
    state.clienteDireccion = null
    state.esParaEnviar = false

}

