import axios from 'axios'
import store from '@/store/index'


export default function getComprobantes(esVenta, documento, paginacion) {
    var doc;
    switch (documento) {
        case 'remito':
            doc = 'RE'
            break;
        case 'ventaDirecta':
            doc = 'NV'
            break;

        default:
            'RE';
    }
    try {

        return axios.get(`/comprobantes/comprobantes?esVenta=${esVenta}&doc=${doc}|FC&page=${paginacion.page}&page_size=${paginacion.rowsPerPage}`)


    } catch (error) {
        console.log(error);
        return "Error obtener los comprobantes";
    }



}

async function get_remitos_compra(paginacion) {
    console.log("Paginacion", paginacion);

    try {
        return axios.get(`/comprobantes/comprobantes?esVenta=false&doc=RE&page=${paginacion.page}&page_size=${paginacion.rowsPerPage}`)
        


    } catch (error) {
        throw error
    }



}

async function imprimirComprobante(id) {
    const isLocalPrinting = store.state.sucursales.configuracion.isLocalPrinting
    try {
        if (isLocalPrinting) {
            return axios
                .get(`/comprobantes/imprimir?id=${id}`)
        } else {

            const response = await axios.get(`/comprobantes/ticket/${id}`)
            const ticketContent = response.data; // Contenido del ticket recibido
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            document.body.appendChild(iframe);
            const iframeDocument = iframe.contentDocument;
            iframeDocument.open();
            iframeDocument.write(ticketContent);
            iframeDocument.close();
            iframe.style.width = "80mm";
            iframe.style.height = "80mm";
            iframe.style.margin = "0";
            iframe.contentWindow.print();
        }

    } catch (error) {
        console.log(error.response);
        return "Error al imprimir el pedido";
    }
}



async function imprimirPedido(id) {
    const isLocalPrinting = store.state.sucursales.configuracion.isLocalPrinting

    try {
        if (isLocalPrinting) {
            return axios
                .get(`/pedidos/imprimir/imprimir?id=${id}`)

        } else {

            const response = await axios.get(`/pedidos/ticket/${id}`)
            const ticketContent = response.data; // Contenido del ticket recibido
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            document.body.appendChild(iframe);
            const iframeDocument = iframe.contentDocument;
            iframeDocument.open();
            iframeDocument.write(ticketContent);
            iframeDocument.close();
            iframe.style.width = "80mm";
            iframe.style.height = "80mm";
            iframe.style.margin = "0";
            iframe.contentWindow.print();
        }

    } catch (error) {
        console.log(error.response);
        return "Error al imprimir el pedido";
    }
}



export { imprimirComprobante, imprimirPedido, get_remitos_compra }

