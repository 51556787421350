<template>
  <v-menu v-model="menu" :nudge-width="200" offset-y :close-on-click="true">
    <template v-slot:activator="{ on }">
      <v-btn
        text
        icon
        dark
        v-on="on"
        class="ml-4 mr-2"
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <!-- <v-icon>mdi-account</v-icon> -->
        <v-icon>mdi-account-circle-outline</v-icon>
      </v-btn>
      <v-btn v-else text dark v-on="on" class="ml-4 mr-2">
        <!-- <v-icon>mdi-account</v-icon> -->
        <v-icon>mdi-account-circle-outline</v-icon>

        <span>{{ user.username }}</span>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon large>mdi-account-circle-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-if="user">Hola {{ user.username }}</v-list-item-title>
            <!-- <v-list-item-subtitle>Dueño</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense>
        <!-- <v-list-item>
              <v-list-item-action></v-list-item-action>
              <v-list-item-title>Mi cuenta</v-list-item-title>
            </v-list-item>-->
        <v-list-item>
          <v-list-item-action></v-list-item-action>
          <v-list-item-title>Mis datos</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action></v-list-item-action>
          <v-list-item-title>Mis consumos</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action></v-list-item-action>
          <v-list-item-title>Mis retiros</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="mt-1" @click="logout">
          <v-list-item-action></v-list-item-action>
          <v-list-item-title>Cerrar sesión</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "usuario.menu",
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),

  },
  methods: {
    ...mapActions("auth", ["logOut"]),
    async logout() {
      await this.logOut();
    },
  },
};
</script>

<style lang="scss" scoped></style>
