
export default [
    {
        path: '/categorias',
        name: 'categorias',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/categorias/categorias.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true  }
    
      },
      {
        path: '/categorias/:padre',
        name: 'ProductoCategoriasTree',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/categorias/ProductoCategoriasTree.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true  }
    
      },
]