import Vue from 'vue'
import router from '@/router'
import { imprimirPedido } from '@/services/comprobante.js'
import PedidoVentaAPI from "@/api/venta/pedido";
import { getDetallesParaServer } from "@/services/detallesComprobante/serviceDetalles";


//en contexto tenemos todo el objeto completo
export function registrar_pedido_venta(context, payload) {
    const cliente = context.state['pedidoClienteVenta']
    const direccion = context.state['pedidoClienteDireccion']
    const esParaEnviar = context.state['pedidoEsParaEnviar']

    const cliente_id = cliente ? cliente.id : null
    const direccion_id = direccion ? direccion.id : null

    const { detalles, nombreEntidad } = payload
    const detalle_pedido = getDetallesParaServer(detalles)

    var formData = {
        detalle_pedido: detalle_pedido,
        paraEnviar: esParaEnviar,
        cliente: cliente_id,
        direccion: direccion_id,
        nombreEntidad: nombreEntidad
    }

    return new Promise((resolve, reject) => {
        PedidoVentaAPI.create(formData).then(response => {
            if (cliente_id) {

                //si se creó un pedido de venta con un cliente asignado, limpiamos la store de cliente
                context.commit('RESET_CLIENTE')
            }
            context.commit('SET_PEDIDO_ES_PARA_ENVIAR', false)

            context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root
            imprimirPedido(response.data.id)


            context.dispatch('fetch_pedidos', 'pendiente')
            resolve(response);

        }, error => {
            context.commit('SET_PEDIDO_ES_PARA_ENVIAR', false)
            context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root
            reject(error);
        })

    })

}

export function registrar_pedido(context, { tipoComprobante, proveedor, nombreEntidad, detalles }) {

    context.commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root

    var formData = {
        detalle_pedido: getDetallesParaServer(detalles)
    }
    formData.cliente = 0
    formData.proveedor = 0


    var url = ''
    if (tipoComprobante === "ventas") {

        formData.nombreEntidad = nombreEntidad
        formData.tipo = "venta"
        url = `/pedidos/venta`

    }
    else {

        formData.proveedor = proveedor.id
        formData.tipo = "compra"
        url = `/pedidos/compra`

    }
    return new Promise((resolve, reject) => {
        Vue.axios({
            method: 'POST',
            url: url,
            data: formData,
        }).then(response => {

            context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root
            if (tipoComprobante == "ventas") {
                imprimirPedido(response.data.id)
            }


            context.dispatch('fetch_pedidos', 'pendiente')
            resolve(response);

        }, error => {
            context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root
            reject(error);
        })

    })


}

export function actualizar_estado_pedido(context, { id_pedido, estado }) {
    context.commit('setLoading', true, { root: true });
    let url = `pedidos/actestado/${id_pedido}/${estado}`;

    return new Promise((resolve, reject) => {
        Vue.axios({
            method: 'GET',
            url: url,
        }).then(response => {
            context.commit('setLoading', false, { root: true });
            const pedidoActualizado = response.data;
            context.commit('ACTUALIZAR_ESTADO_PEDIDO_VENTA', pedidoActualizado);
            resolve(response);
        }, error => {
            context.commit('setLoading', false, { root: true });
            reject(error);
        });
    });
}

export async function fetch_pedidos_compra(context, estado) {
    console.log("e = ", estado);
    try {
        context.commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root
        if (estado) {

            var url = `/pedidos/compra?estado=${estado}`;

            var estado = estado.toUpperCase()
        } else {
            var activos = ['APROBADO', 'PENDIENTE']

            var url = `/pedidos/compra?activos=${activos}`;

        }

        await Vue.axios({
            method: 'GET',
            url: url,
        }).then(response => {
            let pedidos = response.data.results

            context.commit('set_pedidos_compra', { estado, pedidos })

        }).catch(e => {
            console.log(e);
        })


    } catch (error) {
        console.log(error);
        context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    } finally {
        context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }

}
export async function fetch_pedidos_venta(context, estado) {
    try {
        context.commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root
        if (estado) {
            var url = `/pedidos/venta?estado=${estado}`;

            var estado = estado.toUpperCase()
        } else {
            var activos = ['APROBADO', 'PENDIENTE']
            var url = `/pedidos/venta?activos=${activos}`;


        }

        await Vue.axios({
            method: 'GET',
            url: url,
        }).then(response => {
            let pedidos = response.data.results

            context.commit('set_pedidos_venta', { estado, pedidos })

        }).catch(e => {
            console.log(e);
        })


    } catch (error) {
        console.log(error);
        context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    } finally {
        context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }

}

export async function get_pedido_con_detalle(context, id) {
    try {

        context.commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root
        var url = `/pedidos/${id}`;
        await Vue.axios({
            method: 'GET',
            url: url,
        }).then(response => {
            context.commit('set_pedido_con_detalle', response.data)
        })

    } catch (error) {
        context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    } finally {
        context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }

}