
export default [
    {
      path: '/promociones',
      name: 'promociones',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/promocion/promocion.home.vue'),
      meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }
  
    },
   
    {
      path: '/promociones/nueva',
      name: 'promocionNueva',
      component: () => import(/* webpackChunkName: "about" */ '@/views/promocion/promocion.registrar.vue'),

  
      meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }
  
  
    },
  
   
    {
      path: '/promocion/:id',
      name: 'detallePromocion',
      component: () => import(/* webpackChunkName: "about" */ '@/views/promocion/promocion.actualizar.vue'),

      meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }
  
  
    },
    
  
  ]