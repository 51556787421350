import Vue from 'vue'
import router from '@/router'
import { saveStorage } from "@/services/serviceLocalStorage";

export async function signIn(context, user) {
    try {
        // Inicio de sesión del usuario
        console.log("Iniciando sesión con usuario:", user);
        // Activar la animación de carga en toda la aplicación
        context.commit('initialData/SET_LOADING_DATA_INIT', true, { root: true });
        console.log("Despues de setear loading datainit: ",context.rootState.initialData.loadingDataInit);
        console.log("Activando loading de animacion.");


        // Realizar la llamada asincrónica para iniciar sesión
        const response = await context.dispatch('login', user);
        console.log("signIn - Respuesta del inicio de sesión:", response);


        // Realizar la llamada asincrónica para cargar los datos iniciales
        const data_init = await context.dispatch('initialData/fetchInitConfig', {}, { root: true });
        console.log("signIn - Datos iniciales cargados:", data_init);

        // Limpiar cualquier error existente
        context.commit('cleanError', '', { root: true });

        // Devolver los datos de la respuesta del inicio de sesión
        router.push('/home')

        return response.data;

    } catch (error) {
        // Manejar el error en caso de fallo en el inicio de sesión o carga de datos iniciales
        console.error("Error en signIn:", error);
        context.commit('initialData/SET_LOADING_DATA_INIT', false, { root: true });

       
    } 
}

export async function login(context, user){
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/usuarios/token',
            data: user
        });
    
        // Almacenar tokens de acceso y actualización en el almacenamiento local
        localStorage.setItem('token_access', response.data.access);
        localStorage.setItem('token_refresh', response.data.refresh);
    
        context.commit('SET_ISLOGGED', true);
        context.commit('SET_USERNAME', response.data.response.user.username);
        context.commit('SET_FULLNAME', response.data.response.user.full_name);
        context.commit('SET_ROLES', response.data.response.user.roles);
        context.commit('SET_GROUP', response.data.response.user.groups);
    
        context.commit('SET_EMPRESA', response.data.response.empresa);
        return response
    } catch (error) {
        console.log('error', error);

        // Manejar errores durante el inicio de sesión
        context.commit('authError');

        if (error.response && error.response.status) {
            const status = error.response.status;

            // Manejar errores específicos según el código de estado de la respuesta
            switch (status) {
                case 480:
                case 400:
                    context.commit('setError', error.response.data.detail, { root: true });
                    break;
                default:
                    context.commit('setError', "Usuario o contraseña incorrectos.", { root: true });
                    break;
            }
        } else {
            // Manejar otros tipos de errores (por ejemplo, errores de red)
            context.commit('setError', "Error de red u otra situación inesperada.", { root: true });
        }
        throw error;
    }
    

}
export function registrar_usuario({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
        Vue.axios({
            method: 'POST',
            url: '/usuarios/usuarios',
            data: user
        }).then(response => {
            resolve(response);
        }).catch(e => {
            reject(e);
        })
    })

}


export async function logOut(context, msg) {
    try {
        context.commit('setLoadingInit', true, { root: true }) //root true, se define la mutacion en la mutacion root
        console.log("Hola! ");
        await Vue.axios({
            method: 'GET',
            // headers: { 'Authorization': `Bearer ${user.token_access}` },
            url: '/usuarios/logout'
        })
        if (msg) {
            context.commit('setError', msg, { root: true })
        } else {
            context.commit('cleanError', '', { root: true })
        }
        context.commit('SET_ISLOGGED', false);
        context.commit('SET_USERNAME', null);
        context.commit('SET_FULLNAME', null);
        context.commit('SET_ROLES', []);
        context.commit('SET_GROUP', null);

        localStorage.removeItem('token_access')
        localStorage.removeItem('token_refresh')
        localStorage.removeItem('vuex')
        router.push('/Login')
    } catch (error) {
        console.log(error);
        context.commit('setErrorLogoutFail', error.response.data, { root: true })
    } finally {
        context.commit('setLoadingInit', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }

}


export async function get_user_group(context) {

    var url = "/usuarios/group";


    const user = await Vue.axios({
        method: 'GET',
        url: url
    })
    context.commit('setUserGroup', user.data)


}