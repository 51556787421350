<template>

    <div class="two-colors">
  
      <v-dialog fullscreen v-model="dialog">
        <v-card class="d-flex align-content-center justify-center flex-wrap">
          <loading-init ></loading-init>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import LoadingInit from "@/components/loading/loading.init.vue";
    export default {
        name: 'LoadingCenter',
        components: {
            LoadingInit
        }, data() {
            return {
                dialog: true
            }
        },
    }
</script>

<style scoped>
.two-colors {
  background: #005d5b;
  background: linear-gradient(
    180deg,
    #005d5b 0,
    #005d5b 100px,
    hsla(0, 0%, 97.6%, 0) 0,
    hsla(0, 0%, 97.6%, 0) 0
  );
}
</style>