
import axios from 'axios'
export default [

  {
    path: '/empresas',
    component: () => import('@/views/empresa/EmpresasPage.vue'),
    children: [
      {
        path: '',
        name: 'empresasList',
        component: () => import('@/components/empresa/EmpresasList.vue'),
      },
      {
        path: 'registrar',
        name: 'registrarEmpresa',
        component: () => import('@/components/empresa/EmpresaRegister.vue'),
      },
    ],

    meta: { requiresAuth: true, requireArqueoCaja: false, requireCaja: false, requireSucursal: false }


  },
  {
    path: '/empresas/:uuid_empresa',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/empresa/EmpresaDetail.vue'),
    children: [
      // UserHome will be rendered inside User's <router-view>
      // when /user/:id is matched
      {
        path: '',
        name: 'empresaDatosGenerales',
        component: () => import('@/components/empresa/EmpresaDetallesGenerales.vue'),
      },
      {
        path: 'editar',
        name: 'editarEmpresa',
        component: () => import('@/components/empresa/EmpresaUpdate.vue'),
      },
      {
        path: 'sucursales',
        name: 'empresaSucursales',
        component: () => import('@/components/empresa/EmpresaSucursalesList.vue'),
      },

      {
        path: 'sucursales/registrar',
        name: 'registrarSucursalEmpresa',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/sucursal/sucursal.registrar.vue'),
        meta: { requiresAuth: true, requireArqueoCaja: false, requireCaja: false, requireSucursal: false }

      },
     
    ],

    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }


  },

  {
    path: '/empresas/configuracion',
    name: 'configuracionsistema',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/empresa/Configuracion.vue'),
    children: [
      {
        path: 'condicionesIva',
        name: 'condicionesIva',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/empresa/soporte/CondicionesIva.vue'),

      },
      {
        path: 'estados',
        name: 'estados',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/empresa/soporte/EstadoPedidosSoporte.vue'),

      },
      {
        path: 'formasDePago',
        name: 'formasDePago',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/empresa/soporte/FormasDePagoSoporte.vue'),

      },
      {
        path: 'tipoNotificaciones',
        name: 'tipoNotificaciones',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/empresa/soporte/TipoDeNotificacionesSoporte.vue'),

      },
      {
        path: 'plazoVencimiento',
        name: 'plazoVencimiento',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/empresa/soporte/PlazoVencimientoSoporte.vue'),

      },
      {
        path: 'unidadMedida',
        name: 'unidadMedida',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/empresa/soporte/UnidadMedidaSoporte.vue'),

      },
      {
        path: 'motivoCambio',
        name: 'motivoCambio',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/empresa/soporte/MotivoCambioSoporte.vue'),

      },
      {
        path: 'recargo',
        name: 'recargo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/empresa/soporte/RecargoSoporte.vue'),

      },




    ],



    meta: { requiresAuth: true, requireArqueoCaja: false, requireCaja: false, requireSucursal: false }


  },

]