import axios from 'axios'
export default {
    index( paginacion ){
      
    },

    show( id ){
    var url = `/comprobantes/compra/remito/${id}`
    return axios.get(url);


    },

    update( id, data ){
        
    },

    create( data ){
    var url = '/comprobantes/compra/remito'
    return axios.post(url, data)

    },
    getRemitosPendientes() {
        var url = '/comprobantes/compra/remito?estado=PENDIENTE'
        return axios.get(url);
      }
   

  
}