export function productos(state) {
    if (Object.keys(state.productos_admin).length === 0) {
        return []
    } else {
        return state.productos_admin.results
    }

}

export function proveedores_filters(state) {
    if (Object.keys(state.productos_admin).length === 0) {
        return []
    } else {
        return state.productos_admin.proveedores


    }
}
export function marcas_filters(state) {
    if (Object.keys(state.productos_admin).length === 0) {
        return []
    } else {
        return state.productos_admin.marcas

    }
}
export function categorias_filters(state) {
    return state.productos_admin.categorias
}

export function cantidadProductos(state) {
    return state.productos_admin.count
}

export function busqueda(state) {
    return state.descripcion
}