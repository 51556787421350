export default {
    productos: {},
    productos_admin: {},
    descripcionBuscadorAdmin: null,
    filters_for_productos_admin: [],
    descripcion: '',
    paginacion: { page: 1, cantidad: 0, rowsPerPage: 20 },
    error: false,
    errorMessage: '',
    url_data: '',
    //tiene lo que se manda al servidor para buscar
    search_data: '',
    filters: [],
    // id: null,
    // descripcion: '',
    // precioVenta: null,
    // precioCosto: null,
    // iva: null,
    // ganancia: null,
    // flete: null,
    // id_tproducto: null,
    // id_marca: null,
    // id_proveedor: null,

}