<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="400px"
    :nudge-width="400"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="actualizarVisto"
        dark
        v-bind="attrs"
        v-on="on"
        icon
        :style="$vuetify.breakpoint.smAndDown ? 'height: 35px; width: 35px' : ''"
        style="background-color: #3a3b3c"
      >
        <v-badge
          color="red"
          :value="notificacionesSinVer"
          :content="notificacionesSinVer"
          overlap
        >
          <v-icon  :style="$vuetify.breakpoint.smAndDown ? 'font-size: 20px' : ''"> mdi-bell </v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card max-height="400" class="overflow-y-auto dark">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><h2>Notificaciones</h2></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div name="NUEVAS" v-show="notificacionesNuevas.length > 0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><h3>Nuevas</h3></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="n in notificacionesNuevas" :key="n.id">
            <v-list-item-action>
              <v-icon>mdi-bell</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                ><div class="text-wrap">
                  {{ n.title }}. {{ n.body }}.
                </div></v-list-item-title
              >
              <v-list-item-subtitle>{{
                moment(n.timestamp).fromNow()
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon small color="indigo">mdi-circle</v-icon>
            </v-list-item-action>
          </v-list-item>
        </div>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><h3>Anteriores</h3></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="n in notificacionesAnteriores" :key="n.id">
          <v-list-item-action>
            <v-icon>mdi-bell</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title><div class="text-wrap">
                  {{ n.title }}. {{ n.body }}.
                </div></v-list-item-title
              ></v-list-item-title>

            <v-list-item-subtitle>{{
              moment(n.timestamp).fromNow()
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon small color="indigo">mdi-circle</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-card v-intersect="infiniteScrolling"></v-card>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "notificaciones.menu",
  data() {
    return {
      page: 1,
      nextPage: true,
      menu: false,
    };
  },
  computed: {
    ...mapState("notificaciones", ["notificaciones"]),
    ...mapGetters(["loadingSystemUpdate"]),

    ...mapGetters("notificaciones", [
      "notificacionesAnteriores",
      "notificacionesNuevas",
      "notificacionesSinVer",
    ]),
    url() {
      return "/notificaciones/pendientes?page=" + this.page;
    },
  },

  methods: {
    ...mapMutations("notificaciones", ["APPEND_NOTI_ANT"]),
    ...mapActions("notificaciones", ["fetch_notificaciones"]),
    fetchNotificaciones(page) {
      this.fetch_notificaciones(page);
    },

    infiniteScrolling(entries, observer, isIntersecting) {

      setTimeout(() => {
        if (this.nextPage) {
          if (isIntersecting) {
            this.page++;
            this.axios
              .get(this.url)
              .then((response) => {
                if (!response.data.next) {
                  this.nextPage = false;
                }
                if (response.data.results.anteriores.length > 1) {
                  this.APPEND_NOTI_ANT(response.data.results.anteriores);
                } else {
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      }, 500);
    },
    ...mapActions("notificaciones", ["acutalizarVistoNotificaciones"]),
    actualizarVisto() {
      this.acutalizarVistoNotificaciones();
    },
  },
  created() {
    this.fetchNotificaciones(1);
  },
};
</script>

<style lang="scss" scoped></style>
