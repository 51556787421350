import Vue from 'vue'

export function sucursalUuid(state) {
    return Vue.$cookies.get('_sl_id');
}
export function caja_id_cookie(state) {
    return Vue.$cookies.get('_cj_id');
}

export function pedidos_compra_pendientes(state) {
    return state.pedidos_compra_pendientes
}
export function pedidos_compra_aprobados(state) {
    return state.pedidos_compra_aprobados
}

export function estado_pedido(state) {

    return state.pedido_con_detalle.estado.nombre;
}

//PEDIDO COMPRA

export function detalles_pedido_compra(state) {
    return state.pedido_compra_register.detalle_pedido
}

export function proveedor_select_pedido_compra_register(state) {
    return state.pedido_compra_register.proveedor
}


//PEDIDO VENTA
export function detalles_pedido_venta(state) {
    return state.pedido_venta_register.detalle_pedido
}
export function formCliente(state) {
    return state.pedido_venta_register.cliente
}
export function formEnvio(state) {
    return state.pedido_venta_register.envio
}
///////






export function get_pedidos_aprobados(state) {
    return state.pedidos_aprobados
}
export function get_pedidos_finalizados(state) {
    return state.pedidos_finzalizados
}

export function cantidad_pedidos_pendientes(state) {
    return state.pedidos_pendientes.length
}
export function cantidad_pedidos_aprobados(state) {
    return state.pedidos_aprobados.length
}

export function get_detalles(state) {
    return state.views_detalle
}
export function is_update_detalles(state) {
    if (state.detalles_update.length > 0) {
        return true
    } else {
        return false
    }
}

export function get_detalles_register(state) {
    return state.pedido_register.detalle_pedido
}
export function get_id_proveedor(state) {
    return state.pedido_register.proveedor_id
}

export function get_detalles_for_update(state) {
    if (state.pedido_con_detalle) {
        return state.pedido_con_detalle.detalles_pedido
    } else {
        return []

    }


}

//Devuelve los detalles para actualizar si existen
export function get_detalles_update(state) {
    if (state.detalles_update.length > 0) {
        return state.detalles_update
    } else {
        return false
    }
}








