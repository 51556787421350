import Vue from 'vue'
import router from '@/router'
import ContabilidadAsientosContableAPI from '@/api/contabilidad/asientoscontables'


//en contexto tenemos todo el objeto completo

export async function fetch_formas_de_pago(context) {
  try {
    // Comprobar si ya existen formas de pago en el estado
    if (context.state.formas_de_pago.length > 0) {
      return; // Si ya existen, salimos de la función
    }

    var url = `/comprobantes/formaDePago`;

    await Vue.axios({
      method: 'GET',
      url: url,
    }).then(response => {
      context.commit('SET_FORMAS_DE_PAGO', response.data);
    });
  } catch (error) {
    console.error("Error al obtener formas de pago:", error);
  }
}

export async function fetch_formas_de_pago_para_cobro(context) {
  try {
    // Comprobar si ya existen formas de pago en el estado
    if (context.state.formas_de_pago_para_cobro.length > 0) {
      return; // Si ya existen, salimos de la función
    }

    var url = `/comprobantes/formasdepago/cobro`;

    await Vue.axios({
      method: 'GET',
      url: url,
    }).then(response => {
      context.commit('SET_FORMAS_DE_PAGO_PARA_COBRO', response.data);
    });
  } catch (error) {
    console.error("Error al obtener formas de pago:", error);
  }
}



export async function get_total_ventas_blanco(context) {

  try {
    var url = `/comprobantes/ventas/blanco`;

    await Vue.axios({
      method: 'GET',
      url: url,
    }).then(response => {
      context.commit('SET_MONTO_VENTAS_BLANCO', response.data)
    })
  } catch (error) {

  }
}

export function fetch_asientos_contables(context, paginacion = { page: 1, rowsPerPage: 10 }) {
   
  return new Promise((resolve, reject) => {
    ContabilidadAsientosContableAPI.index(paginacion).then(response => {

      context.commit('SET_ASIENTOS_CONTABLES', response.data.results);

      resolve(response);
    }).catch(e => {
      reject(e);
    })
  })

}
 
// Método para buscar un asiento contable por número
export async function buscarAsientoContable(context, numeroAsiento) {
  try {
    const response = await ContabilidadAsientosContableAPI.show(numeroAsiento);

    // Actualiza el estado con el asiento contable encontrado
    context.commit('SET_ASIENTO_CONTABLE', response.data);
  } catch (error) {
    console.error('Error al buscar el asiento contable:', error);
    // Puedes manejar errores aquí según sea necesario
  }
}

export async function registrar_asiento_contable(context, data) {
  return new Promise((resolve, reject) => {
    ContabilidadAsientosContableAPI.create(data).then(response => {

      context.dispatch('fetch_asientos_contables');
      resolve(response);
    }).catch(e => {
      reject(e);
    })
  })

}
export function eliminar_asiento_contable(context, data) {
  return new Promise((resolve, reject) => {
    ContabilidadAsientosContableAPI.delete(data).then(response => {
      context.dispatch('fetch_asientos_contables', );
      resolve(response);
    }).catch(e => {
      reject(e);
    })
  })

}
export function actualizar_asiento_contable(context, data) {
  return new Promise((resolve, reject) => {
    ContabilidadAsientosContableAPI.update(data).then(response => {
      context.dispatch('fetch_asientos_contables');
      resolve(response);
    }).catch(e => {
      reject(e);
    })
  })

}