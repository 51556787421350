

import Vue from 'vue'
import axios from 'axios'
import ProductosAPI from '@/api/productos'

export default {
  namespaced: true,
  state: {
    proveedores: [],
    proveedor_selected_update: null,
    productos_de_lista_para_registrar: {
      id_lista: null,
      ganancia: 0,
      categoria_id: null,
      proveedor_id: null,
      productos: [],

    },
    listaDePrecio: null
  },
  mutations: {
    SET_ID_LISTA_PRECIO(state, id) {
      state.productos_de_lista_para_registrar.id_lista = id
    },
    SET_GANANCIA_LISTA_PRECIO(state, ganancia) {
      state.productos_de_lista_para_registrar.ganancia = ganancia

    },
    SET_ID_CATEGORIA_LISTA_PRECIO(state, categoria_id) {
      state.productos_de_lista_para_registrar.categoria_id = categoria_id

    },
    SET_PROVEEDOR_ID_LISTA_PRECIO(state, proveedor_id) {
      state.productos_de_lista_para_registrar.proveedor_id = proveedor_id

    },
    SET_PRODUCTOS_LISTA_PRECIO(state, productos) {
      state.productos_de_lista_para_registrar.productos = productos
    },
    SET_LISTA_PRECIO(state, lista) {
      state.listaDePrecio = lista
    },
    RESET_PRODUCTOS_DE_LISTA_PARA_REGISTRAR(state) {
      state.productos_de_lista_para_registrar = {
        id_lista: null,
        ganancia: 0,
        categoria_id: null,
        proveedor_id: null,

        productos: []
      }
    },
    setProveedor(state, data) {
      state.proveedores = data
    },
    setProveedorSelectedUpdate(state, data) {
      state.proveedor_selected_update = data
    },

    add_proveedor(state, proveedor) {
      state.proveedores.push(proveedor)

    },
    quit_proveedor(state, id) {
      for (var i in state.proveedores) {

        if (state.proveedores[i].id == id) {

          state.proveedores.splice(i, 1);

          break;
        }
      }
    },

    update_proveedor(state, proveedor) {

      for (var i in state.proveedores) {

        if (state.proveedores[i].id == proveedor.id) {

          state.proveedores.splice(i, 1);


          state.proveedores.push(proveedor)


          break; //Stop this loop, we found it!
        }
      }

    }
  },

  getters: {
    proveedores(state) {
      return state.proveedores
    },
    ganancia() {
      return state.productos_de_lista_para_registrar.ganancia
    },
    esValidoRegistrarProductosDesdeLista(state) {
      return state.productos_de_lista_para_registrar.productos.length > 0 && state.productos_de_lista_para_registrar.categoria_id
    },

  },
  actions: {
    async registrarProductosDesdeListaDePrecios(context) {
      return new Promise((resolve, reject) => {
        ProductosAPI.registrarProductosMedianteListaDePrecios(context.state.productos_de_lista_para_registrar).then(response => {
          context.commit('RESET_PRODUCTOS_DE_LISTA_PARA_REGISTRAR')
          resolve(response);
        }).catch(e => {
          reject(e);
        })
      })

    },
    async fetchProveedores(context) {

      var url = "/proveedores/";

      const proveedores = await Vue.axios({
        method: 'GET',
        url: url
      })
      context.commit('setProveedor', proveedores.data)



    },
    async get_proveedor(context, id) {

      var url = `/proveedores/${id}`;

      const proveedor = await Vue.axios({
        method: 'GET',
        url: url
      })
      context.commit('setProveedorSelectedUpdate', proveedor.data)



    },
    async actualizar_proveedor(context, proveedor) {
      if (Array.isArray(proveedor.categorias) && proveedor.categorias.every(item => typeof item === 'object')) {
        // Crear un nuevo array con solo los IDs de los objetos
        proveedor.categorias = proveedor.categorias.map(item => item.id);
        console.log(proveedor.categorias);
      }
      console.log(proveedor.categorias);
      const data = {
        nombre: proveedor.nombre,
        telefono: proveedor.telefono,
        direccion: proveedor.direccion,
        email: proveedor.email,
        localidad: { id: proveedor.localidad.id },
        categorias: proveedor.categorias,
        condicionIva: proveedor.condicionIva,
        tipo_documento: proveedor.tipo_documento,
        documento: proveedor.documento,

        cuenta_corriente: proveedor.cuenta_corriente
          ? proveedor.cuenta_corriente.id
          : null,
        cuenta_contado: proveedor.cuenta_contado
          ? proveedor.cuenta_contado.id
          : null,
        cuenta_otros: proveedor.cuenta_otros ? proveedor.cuenta_otros.id : null,
        rubro_compra: proveedor.rubro_compra ? proveedor.rubro_compra.id : null,

      }
      console.log(data.rubro_compra);
      const proveedores = await axios.put(`/proveedores/${proveedor.id}`, data)

      context.commit('update_proveedor', proveedores.data)
    },

    async registrar_proveedor(context, proveedor) {
      
      var formData = {
        nombre: proveedor.nombre,
        telefono: proveedor.telefono,
        direccion: proveedor.direccion,
        email: proveedor.email,
        localidad: { id: proveedor.localidad.id },
        categorias: proveedor.categorias,
        condicionIva: proveedor.condicionIva,
        tipo_documento: proveedor.tipo_documento,
        documento: proveedor.documento,
        cuenta_corriente: proveedor.cuenta_corriente
          ? proveedor.cuenta_corriente.id
          : null,
        cuenta_contado: proveedor.cuenta_contado
          ? proveedor.cuenta_contado.id
          : null,
        cuenta_otros: proveedor.cuenta_otros ? proveedor.cuenta_otros.id : null,
        rubro_compra: proveedor.rubro_compra ? proveedor.rubro_compra.id : null,

      }
      
      const proveedores = await axios.post(`/proveedores/`, formData)

      context.dispatch('fetchProveedores')

    },

    async eliminar_proveedor(context, id) {

      const proveedores = await axios.delete(`/proveedores/${id}`)
      context.commit('quit_proveedor', id)
    },


  }
}