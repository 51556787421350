import axios from 'axios'
import CategoriasAPI from '@/api/categorias'

export default {
    namespaced: true,
    state: {
        categoriaSelected: null,
        categoriasSeleccionadas: [],
        categoriasArbol: null,
        categoriasProductosFiltros: null,
        categoriasProductosFiltrosSeleccionado: null,


    },
    mutations: {
        setCategoriaSelected(state, cate) {
            state.categoriaSelected = cate
        },


        ADD_CATEGORIAS_SELECCIONADAS(state, categoria) {
            state.categoriasSeleccionadas.push(categoria);
        },
        REMOVE_CATEGORIAS_SELECCIONADAS(state, categoria) {

            state.categoriasSeleccionadas.splice(state.categoriasSeleccionadas.indexOf(categoria), 1); //deleting

        },
        RESET_CATEGORIAS_SELECCIONADAS(state){
            state.categoriasSeleccionadas = []
        },
        SET_CATEGORIAS_ARBOL(state, categorias) {

            state.categoriasArbol = categorias
        },
        SET_CATEGORIAS_PRODUCTOS_FILTROS(state, categorias) {
            state.categoriasProductosFiltros = categorias
        },
        SET_CATEGORIAS_PRODUCTOS_FILTROS_SELECCIONADO(state, categoria) {
            state.categoriasProductosFiltrosSeleccionado = categoria
        }


    },
    getters: {
        filtrosEditorMasivo(state) {
            return state.categoriasSeleccionadas.map(categoria => `cat-${categoria.id}`)
        }

    },
    actions: {
        fetchCategoriasFilters(context) {
            CategoriasAPI.indexFilters().then((categorias) => {
                context.commit('SET_CATEGORIAS_ARBOL', categorias.data)
            });
        },
        fetchCategoriasProductosFiltros(context, nombreCategoria) {
            if (!nombreCategoria) {
                var nombreCategoria = 'CATEGORIA'
            }
            CategoriasAPI.index(nombreCategoria).then((categorias) => {
                context.commit('SET_CATEGORIAS_PRODUCTOS_FILTROS', categorias.data)
            });
        },
        agregarCategoriaSeleccionada(context, categoria) {
            if (categoria.checked) {
                context.commit('REMOVE_CATEGORIAS_SELECCIONADAS', categoria)
            } else {
                context.commit('ADD_CATEGORIAS_SELECCIONADAS', categoria)
            }

            categoria.checked = !categoria.checked


            // if (context.state.categoriasSeleccionadas.includes(categoria)) {
            //     context.commit('REMOVE_CATEGORIAS_SELECCIONADAS', categoria)


            // } else {
            //     context.commit('ADD_CATEGORIAS_SELECCIONADAS', categoria)


            // }
            if (categoria.children.length > 0) {
                for (let i = 0; i < categoria.children.length; i++) {
                    const element = categoria.children[i];
                    context.dispatch('agregarCategoriaSeleccionada', element)
                }
            }
        }
    }
}

