import Vue from 'vue'

export function SET_EMPRESA(state, empresa) {
    Vue.$cookies.set('empresa_name', empresa.razonSocial, '365d'); // Cambia '7d' según tu necesidad
    state.empresa = empresa
}

export function set_pedidos_pendientes(state, pedidos) {
    state.pedidos_pendientes = []
    state.pedidos_pendientes = pedidos
}

export function set_pedidos_aprobados(state, pedidos) {
    state.pedidos_aprobados = []
    state.pedidos_aprobados = pedidos
}
export function finalizados(state, pedidos) {
    state.pedidos_finalizados = []
    state.pedidos_finalizados = pedidos
}
export function set_pedido_con_detalle(state, pedidos) {
    state.pedido_con_detalle = []
    state.pedido_con_detalle = pedidos
}

export function add_pedido(state, pedido) {
    state.pedidos_pendientes.push(pedido)
}

export function set_bandera_success_register(state) {

    state.register_success = true

    setTimeout(() => {
        state.register_success = false
    }, 1000);
}



// VENTA
export function set_pedido_venta_register(state, { cliente, envio, detalles }) {
    state.pedido_venta_register.cliente = cliente
    state.pedido_venta_register.envio = envio
    state.pedido_venta_register.detalle_pedido = detalles
}

export function reset_state_pedido_venta(state) {

    state.pedido_venta_register.cliente = {
        nombre: null,
        telefono: null,
        paraEnviar: false,
    },
        state.pedido_venta_register.envio = {
            selected: "",
            calle: "",
            altura: "",
            piso: "",
            depto: "",
            observacionEnvio: "",
        },
        state.pedido_venta_register.detalle_pedido = []
}


// PEDIDO COMPRA
export function set_pedido_compra_register(state, { proveedor, detalles }) {
    state.pedido_compra_register.proveedor = proveedor
    state.pedido_compra_register.detalle_pedido = detalles


}
export function set_proveedor_pedido_compra(state, proveedor) {
    state.pedido_compra_register.proveedor = proveedor
}

export function reset_state_pedido_compra(state) {

    state.pedido_compra_register.proveedor = null
    state.pedido_compra_register.detalle_pedido = []
}

///

export function quit_detalle(state, { tipoComprobante, detalle }) {
  

    var detalles_pedido = tipoComprobante === 'compras' ? state.pedido_compra_register.detalle_pedido : state.pedido_venta_register.detalle_pedido

    const index = detalles_pedido.indexOf(detalle);

    if (index > -1) {
        // index_register contiene el indice del array detalle_pedido, que se utiliza para enviar en formato correcto al servidor

        detalles_pedido.splice(index, 1);

    }
}

export function update_detalle(state, { tipoComprobante, detalle }) {


    var detalles_pedido = tipoComprobante === 'compras' ? state.pedido_compra_register.detalle_pedido : state.pedido_venta_register.detalle_pedido
   

    for (var i in detalles_pedido) {
        if (detalles_pedido[i].producto.id == detalle.producto.id) {
            detalles_pedido[i] = detalle;
            break; //Stop this loop, we found it!
        }
    }

    // index contiene el indice del arra yviews_detalle, que es el mismo de detalle_pedido, que se utiliza para enviar en formato correcto al servidor

    // const index = detalles_pedido.indexOf(detalle);


    // if (index > -1) {


    //     detalles_pedido.splice(index, 1, detalle);


    // }


    // call form submission logic

}

export function add_detalle(state, { tipoComprobante, detalle }) {



    var detalles_pedido = tipoComprobante === 'compras' ? state.pedido_compra_register.detalle_pedido : state.pedido_venta_register.detalle_pedido

    let index = detalles_pedido.find(d => d.producto.id == detalle.producto.id)


    if (index) {
        for (var i in detalles_pedido) {
            if (detalles_pedido[i].producto.id == detalle.producto.id) {
                detalles_pedido[i].cantidad = detalle.cantidad;
                break; //Stop this loop, we found it!
            }
        }
        state.error_detalle_repetido.state = true

        setTimeout(() => {
            state.error_detalle_repetido.state = false

        }, 3000);

    }
    else {
        detalles_pedido.push(detalle);
    }
}

// ACTUALIZACION DE PEDIDO -------------------------------------------------------------------
export function clean_detalles_update(state) {

    state.detalles_update = []
    state.pedido_con_detalle = []

}

export function add_detalle_update(state, detalle) {

    //Accion 0: Eliminar, 1:Modificar y 2: Agregar

    let detalle_pedido = {
        producto: detalle.producto.id,
        cantidad: detalle.cantidad,
        accion: 2
    }

    let existeDetalle = state.detalles_update.find(d => d.producto == detalle_pedido.producto);
    // console.log("Agregar detalle");

    if (existeDetalle) {
        // console.log("Existe el detalle para enviar, es: ", existeDetalle);

        //El detalle existia y fue eliminado
        if (existeDetalle.accion == 0) {
            // console.log("Esta en el server, y fue eliminado");
            // console.log("Pero ahora se agrego de nuevo, y es modificado");


            //Tiene que cambiar a la accion de modificar, y setear la cantidad
            for (var i in state.detalles_update) {
                if (state.detalles_update[i].producto == detalle.producto.id) {
                    state.detalles_update[i].accion = 1
                    state.detalles_update[i].cantidad = detalle_pedido.cantidad;

                    break; //Stop this loop, we found it!
                }
            }
            //Tambien debe mostrarlo
            state.pedido_con_detalle.detalles_pedido.push(detalle);


        } else {
            // console.log("Existe el detalle, y fue modificado");

            // Si existe para enviar y no fue por que se elimino
            //Actualizo la cantidad para enviar
            for (var i in state.detalles_update) {
                if (state.detalles_update[i].producto == detalle.producto.id) {

                    state.detalles_update[i].cantidad = detalle_pedido.cantidad;

                    break; //Stop this loop, we found it!
                }
            }
            //Actualizo la cantidad para mostrar

            for (var i in state.pedido_con_detalle.detalles_pedido) {
                // es el id del detalle
                if (state.pedido_con_detalle.detalles_pedido[i].producto.id == detalle.producto.id) {

                    state.pedido_con_detalle.detalles_pedido[i].cantidad = detalle_pedido.cantidad;
                    break;
                }
            }
        }

    }
    else {
        // console.log("No existe el detalle para enviar, es un detalle nuevo");


        let index = state.pedido_con_detalle.detalles_pedido.find(d => d.producto.id == detalle.producto.id)


        if (index) {
            state.error_detalle_repetido.state = true

            setTimeout(() => {
                state.error_detalle_repetido.state = false

            }, 3000);
        }
        else {
            state.detalles_update.push(detalle_pedido);
            state.pedido_con_detalle.detalles_pedido.push(detalle);

            // state.detalles_views_update.push(detalle);
        }

    }
}
export function update_detalle_update(state, detalle) {


    let detalle_pedido = {
        detallePedido_id: detalle.id,
        producto: detalle.producto.id,
        cantidad: detalle.cantidad,
        accion: 1
    }


    // Busca si ya existe el detalle 
    let existeDetalle = state.detalles_update.find(d => d.producto == detalle_pedido.producto);

    if (existeDetalle) {

        for (var i in state.detalles_update) {
            if (state.detalles_update[i].producto == detalle.producto.id) {

                state.detalles_update[i].cantidad = detalle_pedido.cantidad;

                break; //Stop this loop, we found it!
            }
        }

        for (var i in state.pedido_con_detalle.detalles_pedido) {
            // es el id del detalle
            if (state.pedido_con_detalle.detalles_pedido[i].producto.id == detalle.producto.id) {

                state.pedido_con_detalle.detalles_pedido[i].cantidad = detalle_pedido.cantidad;
                break;
            }
        }
    }
    else {
        state.detalles_update.push(detalle_pedido);
        for (var i in state.pedido_con_detalle.detalles_pedido) {
            // es el id del detalle
            if (state.pedido_con_detalle.detalles_pedido[i].producto.id == detalle.producto.id) {

                state.pedido_con_detalle.detalles_pedido[i].cantidad = detalle_pedido.cantidad;

                break; //Stop this loop, we found it!
            }
        }

    }




}

export function quit_detalle_update(state, detalle) {

    let detalle_pedido = {
        detallePedido_id: detalle.id,
        producto: detalle.producto.id,
        cantidad: detalle.cantidad,
        accion: 0
    }
    let existeDetalleParaEnviar = state.detalles_update.find(d => d.producto == detalle_pedido.producto);

    if (existeDetalleParaEnviar) {
        //"Esta en para enviar");

        if (existeDetalleParaEnviar.accion == 1) {
            //"Y fue modificado, por lo que existia ya en el server");
            //"Lo seteo como eliminado nuevamente");
            for (var i in state.detalles_update) {
                if (state.detalles_update[i].producto == detalle.producto.id) {
                    state.detalles_update[i].accion = 0
                    state.detalles_update[i].cantidad = detalle_pedido.cantidad;

                    break; //Stop this loop, we found it!
                }
            }
            // lo quito de la vista");


            for (var i in state.pedido_con_detalle.detalles_pedido) {
                // es el id del detalle
                if (state.pedido_con_detalle.detalles_pedido[i].producto.id == detalle.producto.id) {
                    state.item_para_deshacer.para_mostrar = state.pedido_con_detalle.detalles_pedido[i]

                    state.pedido_con_detalle.detalles_pedido.splice(i, 1);
                    break;
                }

            }
            state.deshacer.state = true

            setTimeout(() => {
                state.deshacer.state = false
                state.item_para_deshacer.para_mostrar = {}
                state.item_para_deshacer.para_enviar = {}

            }, 250000);


        } else {
            //Este detalle es nuevo, lo quito del para enviar");
            for (var i in state.detalles_update) {
                if (state.detalles_update[i].producto == detalle.producto.id) {
                    state.item_para_deshacer.para_enviar = state.detalles_update[i]
                    state.detalles_update.splice(i, 1);

                    break; //Stop this loop, we found it!
                }
            }
            //"Tambien lo quito para mostrar");

            for (var i in state.pedido_con_detalle.detalles_pedido) {
                // es el id del detalle
                if (state.pedido_con_detalle.detalles_pedido[i].producto.id == detalle.producto.id) {
                    state.item_para_deshacer.para_mostrar = state.pedido_con_detalle.detalles_pedido[i]

                    state.pedido_con_detalle.detalles_pedido.splice(i, 1);
                    break;
                }

            }
            state.deshacer.state = true

            setTimeout(() => {
                state.deshacer.state = false
                state.item_para_deshacer.para_mostrar = {}
                state.item_para_deshacer.para_enviar = {}

            }, 250000);

        }

    } else {
        /*El detalle no existia para enviar, se eliminara y");
        se guardara en para enviar. y lo quito de la vista");*/
        state.detalles_update.push(detalle_pedido);
        for (var i in state.pedido_con_detalle.detalles_pedido) {
            // es el id del detalle
            if (state.pedido_con_detalle.detalles_pedido[i].producto.id == detalle.producto.id) {
                state.item_para_deshacer.para_mostrar = state.pedido_con_detalle.detalles_pedido[i]
                state.item_para_deshacer.para_enviar = detalle_pedido

                state.pedido_con_detalle.detalles_pedido.splice(i, 1);
                break;
            }
        }
        state.deshacer.state = true

        setTimeout(() => {
            state.deshacer.state = false
            state.item_para_deshacer.para_mostrar = {}
            state.item_para_deshacer.para_enviar = {}

        }, 250000);
    }
}

export function deshacer_eliminado(state) {
    let items = state.item_para_deshacer
    if (items.para_mostrar
    ) {
        for (var i in state.detalles_update) {
            if (state.detalles_update[i].producto == items.para_enviar.producto) {

                state.detalles_update.splice(i, 1);

                break;
            }
        }
        state.pedido_con_detalle.detalles_pedido.push(items.para_mostrar);
    }
    state.deshacer.state = false
    state.item_para_deshacer.para_mostrar = {}
    state.item_para_deshacer.para_enviar = {}
}



