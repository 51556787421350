




export function set_productos_reporte(state, productos) {
    state.productos_reporte = []
    state.productos_reporte = productos
    state.error = false
    state.errorMessage = ''
}


// FILTROS
export function clean_filters(state) {
    state.filters_reporte = []
}
export function addFilter(state, filter) {
    let element = state.filters_reporte.find(d => d.key == filter.key)

    if (element) {
        for (let i = 0; i < state.filters_reporte.length; i++) {
            if (state.filters_reporte[i].key == element.key) {
                state.filters_reporte.splice(i, 1);
                state.filters_reporte.push(filter);
            }
        }
    } else {
        state.filters_reporte.push(filter);

    }

}
export function quitFilter(state, key_filter) {

    state.filters_reporte.splice(state.filters_reporte.findIndex(e => e.key === key_filter), 1);

}



