export function productos_agregados_register(state) {
    return state.producto_registrar_multiple.productos
}
export function productos_admin(state) {
    // if (Object.keys(state.productos_admin).length === 0) {
    //     return []
    // } else {
    //     return state.productos_admin.results
    // }
    return state.productos_admin



}
export function proveedor_select(state, rootGetters, rootState) {
    return state.proveedor_register
}
export function marca_select(state, getters, rootState, rootGetters) {
    return state.marca_register
}
export function categoria_select(state) {
    return state.tipoProducto_register
}

export function validar_registro_producto(state) {
    if (state.producto_registrar_multiple.es_elaboracion_propia && state.producto_registrar_multiple.productos.length > 0) {
        return true
    }
    if (state.producto_registrar_multiple.proveedor.id &&
        state.producto_registrar_multiple.productos.length > 0) {
        return true
    } else {
        return false
    }
}

export function filters_admin(state) {

    return state.filters_for_productos_admin

}

export function loadingProductosFrecuentes(state) {
    return state.loading_mas_vendidos
}


