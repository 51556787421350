import { saveStorage } from "@/services/serviceLocalStorage";
import Vue from 'vue'
import store from '@/store';


export function SET_AUTH_SUCURSAL(state, data) {
    if (data) {
        state.nombreSucursal = data.nombre
        state.uuidSucursal = data.uuid
    } else {
        state.nombreSucursal = null
        state.uuidSucursal = null
    }

}

export function SET_ROLES(state, roles) {
    state.user.role = roles
}
export function SET_GROUP(state, group) {
    state.groups = group
}
export function SET_EMPRESA(state, empresa) {
    state.empresa = empresa

    if (!empresa) {
        localStorage.removeItem("empresa")
    } else {

        localStorage.setItem("empresa", empresa)
    }

}
export function SET_USERNAME(state, username) {
    console.log("SET_USERNAME", username);
    state.user.username = username
}
export function SET_FULLNAME(state, full_name) {
    state.user.full_name = full_name;

}
export function SET_ISLOGGED(state, isLogged) {
    state.isLogged = isLogged;

}


export function SET_AUTH_INFO(state, data) {
    if (data) {

        state.user.username = data.user.username;
        state.user.full_name = data.user.full_name;
        state.isLogged = true



        state.user.groups = data.user.groups;
        state.user.role = data.user.roles;
        if (data.empresa) {
            state.empresa = data.empresa
            localStorage.setItem("empresa", data.empresa.name)
        }

    } else {
        console.log("data in SET_AUTH_INFO is null");
        // Establecer los valores de usuario a null o valores iniciales
        state.user.username = null;
        state.user.full_name = null;
        state.isLogged = false


        state.user.groups = [];
        state.user.role = [];

    }











}


export function REMOVE_USER(state) {
    state.user.username = null;
    state.user.full_name = null;
    state.user.groups = [];
    state.user.role = [];
    state.group = []
    state.isLogged = false



}

export function authError(state) {
    state.user.username = null;
    state.user.full_name = null;
    state.user.groups = [];
    state.user.role = [];
    state.isLogged = false
}

export function setUserGroup(state, user) {
    state.user.groups = user.groups
    state.user.groups = user.groups

    state.user.username = user.username
}

