import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import axios from 'axios'
import { saveStorage, getStorage } from "@/services/serviceLocalStorage";


import routesProductos from './producto/routes.js'
import routesProveedor from './proveedores/routes.js'
import routesEgresos from './egresos/routes.js'
import routesCategorias from './categorias/routes.js'
import routerUsuarios from './usuarios/routes'
import routerConfiguracion from './configuracion/routes'
import routerPromocion from './promociones/routes'
import routerCaja from './caja/routes'
import routesReportes from './reportes/routes'
import routesEmpresa from './empresa/routes'
import routerCliente from './clientes/routes'
import routesSucursal from './sucursal/routes'
import routesCompras from './compras/routes' //
import routesContabilidad from './contabilidad/routes' //












import routesVentas from './ventas/routes.js'



Vue.use(VueRouter)

// Verifica si hay un usuario y si tiene la propiedad 'role'
var role_user_owner = store.state.auth.user && store.state.auth.user.role && store.state.auth.user.role.includes('owner');
var role_user_admin = store.state.auth.user && store.state.auth.user.role && store.state.auth.user.role.includes('admin');
var role_user_employee = store.state.auth.user && store.state.auth.user.role && store.state.auth.user.role.includes('employee');





const baseRoutes = [
  {
    path: '/',
    redirect: '/home'
  },
  {

    path: '/Login',
    name: 'login',

    component: () => import('@/views/usuarios/Login.vue'),
    meta: { requiresAuth: false, requireArqueoCaja: false }


  },
  {

    path: '/error',
    name: 'error',

    component: () => import('@/views/ErrorPage.vue'),
    meta: { requiresAuth: false, requireArqueoCaja: false },


  },
  {

    path: '/home',
    name: 'home',

    component: () => import('@/views/Home.vue'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }


  },
  {
    path: '/marcas',
    name: 'marcas',
    component: () => import('@/views/marcas/Marcas.vue'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

  },

]
const routes = baseRoutes.concat(routesProductos, routesProveedor, routesVentas, routesEgresos, routesCategorias, routerUsuarios, routerConfiguracion, routerPromocion, routerCaja, routesReportes, routesEmpresa, routerCliente, routesSucursal, routesCompras, routesContabilidad);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Guard authentication
// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//   const isLogged = store.state.auth.isLogged;
//   console.log("Login - Primer beforeach");
//   console.log("isLogged", isLogged);
//   console.log("to", to);
//   console.log();
//   if (requiresAuth && !isLogged) {
//     console.error(`Navegación bloqueada desde '${from.path}' a '${to.path}' debido a falta de autenticación.`);
//     next({ name: 'login' });
//   } else {
//     console.log("Continuando la navegación normalmente en el segundo beforeEach");
//     // Si isError era true y la navegación pasó por el primer beforeEach,
//     // esta parte ya no se ejecutará debido al 'next()' en el bloque de isError.
//     next();
//   }
// });

//Guard error

// router.beforeEach((to, from, next) => {
//   const isError = store.state.initialData.error;
//   console.log("Dentro de redirectToErrorIfError, isError:", isError);

//   if (isError) {
//     if (to.name !== 'error') {
//       console.log("Redirigiendo a 'error' debido a isError");
//       next({ name: 'error' });
//     } else {
//       console.log("Continuando la navegación en 'error'");
//       // Si isError es true y la ruta ya es 'error', simplemente permite continuar
//       next();
//     }
//   } else {
//     console.log("Continuando la navegación normalmente");
//     // Si isError es false, permite continuar
//     next();
//   }
// });


router.beforeEach((to, from, next) => {
  let isError = store.state.initialData.error

  if (to.name == 'error') {
    // Si ya se redirigió a la página de error, detener la navegación
    next(false);
    return;
  }

  // Resto de tu lógica de guard aquí

  if (isError) {
    // Marcar que se redirigió a la página de error
    next({ name: 'error', params: { errorMessage: 'Mensaje de error' } });
  } else {
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   // Obtén el contador actual de los metadatos de la ruta

//   // Verifica si se ha alcanzado un límite de visitas para evitar ciclos infinitos
//   console.log("BEFORE DE ERROR");

//     console.error('Posible ciclo infinito detectado. Redirigiendo a página de error o a una ruta segura.')
//     // Puedes redirigir a una página de error o a una ruta segura para romper el ciclo
//     if (to.path !== '/error') {
//       console.log("to.path !== '/error'", to.path !== '/error');
//       next('/error')
//     } else {
//       console.log("to.path es igual a /error");
//       // Si ya estás en la ruta de error, redirige a una ruta segura
//       next()
//     }

// })

router.beforeEach((to, from, next) => {
  let isError = store.state.initialData.error

  console.log("Autenticacion - Soy el segundo beforeEach");
  console.log(to);
  // Verifica si la ruta requiere autenticación
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  // Verifica si el usuario está autenticado
  const isLogged = store.state.auth.isLogged

  // if (requiresAuth && !isLogged) {
  //   if (to.name === 'login') next()
  //   else next({ name: 'login' })

  //   console.error(`Navegación bloqueada desde '${from.path}' a '${to.path}' debido a falta de autenticación.`)
  // }
  console.log("beforeEach - LOGIN. to: ", to);
  if (to.name === 'login') {
    console.log("Estoy yendo a Login");
    if (isLogged) {
      console.log("Estoy logueado, no voy a entrar");
      next(false)
    } else {
      console.log("Sigo navegando");
      next()
    }
  } else {
    console.log("No estoy yendo a login");
    if (isLogged) {
      console.log("Estoy logueado, navegacion normal");
      next()
    } else {
      console.log("No estoy logueado");
      if (requiresAuth) {
        console.log("Esta ruta requiere autenticacion, me voy a login");
        next({ name: 'login' })
      } else {
        console.log("Esta ruta NO requiere autenticacion, sigo");

        next()
      }
    }
  }
  // if (isLogged) {
  //   if (to.name === 'login') next(false)
  //   else next()
  // } else {
  //   if (to.name === 'login'){

  //   }
  //   if (requiresAuth) {
  //     if (to.name === 'login'){
  //       next()
  //     }else{

  //     }
  //   }
  //   if (!requiresAuth || to.name === 'login') {
  //     // Si el usuario no está autenticado y la ruta no requiere autenticación
  //     // o se dirige a la página de inicio de sesión, permite continuar
  //     console.log("Usuario no autenticado, permitiendo acceso a login.");
  //     next()
  //   } else {
  //     // Si la ruta requiere autenticación y el usuario no está autenticado,
  //     // redirige a la página de inicio de sesión
  //     console.log("Usuario no autenticado, redirigiendo a la página de inicio de sesión.");
  //     next({ name: 'login' })
  //   }
  // }

})

// Guardia de Navegación: Carga de Datos Iniciales

// Este guardia de navegación (navigation guard) se ejecuta antes de cada cambio de ruta.
// Su propósito es asegurarse de que los datos iniciales estén cargados antes de permitir
// el acceso a rutas protegidas.

router.beforeEach(async (to, from, next) => {
  console.log("Iniciando beforeEach Carga de datos...");

  // Verificar si el usuario está autenticado
  let isLoadData = store.state.initialData.loadData;
  let isLogged = store.state.auth.isLogged;

  console.log("isLoadData:", isLoadData);
  console.log("isLogged:", isLogged);

  // Si el usuario está autenticado, continuar
  if (isLogged && to.path !== '/error') {
    console.log("Usuario autenticado. Path actual:", to.path);

    // Si los datos iniciales ya se han cargado, permitir el acceso a la siguiente ruta
    if (isLoadData) {
      console.log("Datos iniciales ya cargados. Permitiendo acceso a la siguiente ruta:", to.path);
      next();
    } else {
      try {
        // Iniciar la carga de datos iniciales
        console.log("Iniciando carga de datos iniciales...");

        // Mostrar un indicador de carga si es necesario
        // ...

        // Realizar la llamada asincrónica para cargar los datos iniciales
        await store.dispatch('initialData/fetchInitConfig', {}, { root: true });

        // Verificar si los datos se cargaron correctamente
        if (store.state.initialData.loadData) {
          // Si los datos se cargaron correctamente, permitir el acceso a la siguiente ruta
          console.log("Datos iniciales cargados correctamente. Permitiendo acceso a la siguiente ruta:", to.path);
          next();
        } else {
          // Manejar el caso donde los datos no se cargaron correctamente
          console.error('Error al cargar los datos iniciales');
          store.commit('initialData/SET_ERROR', { error: true, errorMessage: 'Error al cargar los datos iniciales' });

          // Redirigir a la página de error
          console.log("Redirigiendo a la página de error.");
          next({ name: 'error' });
        }
      } catch (error) {
        // Manejar el error de la petición fetchInitConfig
        console.error('Error al cargar los datos iniciales:', error);
        store.commit('initialData/SET_ERROR', { error: true, errorMessage: 'Error al cargar los datos iniciales' });

        // Redirigir a la página de error
        console.log("Redirigiendo a la página de error.");
        next({ name: 'error' });
      }
    }
  } else {
    // Si el usuario no está autenticado, redirigir a la página de inicio de sesión
    console.log("Usuario no autenticado o redirigiendo a '/error'. Redirigiendo a la página de inicio de sesión.");
    next();
  }

});


// router.beforeEach(async (to, from, next) => {
//   const isLogged = store.state.auth.isLogged;
//   let isError = store.state.initialData.error
//   let messageError = store.state.initialData.messageError
//   if (isError){
//     next()
//   }else{

//   if (isLogged) {
//     if (store.state.initialData.loadData) {
//       next(); // Si los datos iniciales ya se cargaron, continúa a la siguiente ruta.

//     } else {
//       try {
//         console.log("initial data de beforeEach");

//         await store.dispatch('initialData/fetchInitConfig', {}, { root: true });

//         if (store.state.initialData.loadData) {
//           next();
//         } 
//       } catch (error) {
//         // Manejar el error de la petición fetchInitConfig
//         console.error('Error al cargar los datos iniciales:', error);
//         next({ name: 'error', params: { errorMessage } }); // Redirige a la página de error con el mensaje de error.

//       }
//     }
//   } else {
//     // Si el usuario no está autenticado, simplemente no permitas la navegación.
//     // Puedes redirigirlo a la página de inicio de sesión o a otra página de tu elección.
//     next(false);
//   }
// }

// });
// // router.push({ name: 'error', params: { errorMessage: error.message } });
// router.beforeEach((to, from, next) => {
//   let isError = store.state.initialData.error
//   let messageError = store.state.initialData.messageError
//   if (isError){
//     next()
//   }else{

//   console.log("EMPRESAS");
//   const isEmpresa = localStorage.getItem("empresa")
//   if (isEmpresa) {
//     if (to.name !== 'registrarEmpresa') {
//       next();
//     } else {
//       next(false)
//     }
//   }
//   //else {

//   // store.dispatch('empresa/get_empresa').then(data => {
//   //   if (data.data.length == 0) {
//   //     store.commit('SET_LAYOUT', { layout: 'layout-basic', withNav: false })


//   //     if (to.name == 'registrarEmpresa') {
//   //       next();
//   //     } else {
//   //       next({ name: 'registrarEmpresa' })
//   //     }

//   //   } else {
//   //     localStorage.setItem("empresa", data.data[0].nombreFantasia)
//   //     if (to.path !== '/registrarEmpresa') {
//   //       next();
//   //     } else {
//   //       next('/home');
//   //     }
//   //   }
//   // }).catch(e => {

//   // })
//   //}
// }


// })

router.beforeEach((to, from, next) => {
  const requiresSucursal = to.matched.some(record => record.meta.requireSucursal)
  const isSucursal = store.state.sucursales.sucursal


  console.log("requiresSucursal", requiresSucursal);
  console.log("isSucursal", isSucursal);
  console.log("TO ??", to.name);
  if (to.name !== 'sucursales') {
    if (requiresSucursal) {
      if (!isSucursal) {
        console.log("role_user_admin", role_user_admin);
        next('/sucursales')

        // if (role_user_admin || role_user_owner) {

        //   next()
        // } else {

        //   console.log("Aca lo redirijo a sucursals");
        //   next('/sucursales')
        // }

      } else {

        next()

      }
    } else {
      console.log("no requiere sucursal", to);


      next()
    }
  } else {
    next()
  }
})
// // router.beforeEach((to, from, next) => {
// //   const requiresSucursal = to.matched.some(record => record.meta.requireSucursal)
// //   const isSucursal = localStorage.getItem("sucursal")
// //   const isEmpresa = localStorage.getItem("empresa")
// //   if (isEmpresa) {
// //     var empresa = localStorage.getItem("empresa")
// //     if (to.name !== 'sucursales') {
// //       if (requiresSucursal && !isSucursal) {
// //         console.log(next({ name: 'sucursales' }));
// //         next({ name: 'sucursales' })
// //         // axios.get(`/config?empresa=${empresa}`).then(response => {
// //         //   if (response.data.sucursal) {
// //         //     saveStorage("sucursal", response.data.sucursal);
// //         //     next()
// //         //   } else {
// //         //     store.commit('SET_LAYOUT', { layout: 'layout-basic', withNav: false })
// //         //     next({ name: 'sucursales' })
// //         //   }
// //         // })
// //       }
// //       else { next() }
// //     } else {
// //       console.log("Quiero ir a sucursales");
// //       if (!requiresSucursal) {
// //         console.log("Si la ruta no requiere sucursal");
// //         if (isSucursal) {
// //           console.log("La sucursal esta en localstorage");
// //           console.log("to: ", to);
// //           console.log("from", from);
// //           console.log("next", next());


// //           next()
// //         } else {
// //           next()
// //           // console.log("No esta en localstorage, hago solcitud config?empresa");
// //           // axios.get(`/config?empresa=${empresa}`).then(response => {
// //           //   if (response.data.sucursal) {
// //           //     saveStorage("sucursal", response.data.sucursal);
// //           //     next('/home')
// //           //   } else {

// //           //     next()
// //           //   }
// //           // })
// //         }
// //       }
// //     }
// //   } else {
// //     next()
// //   }
// // })
router.beforeEach(async (to, from, next) => {
  const requiresCaja = to.matched.some(record => record.meta.requireCaja);
  const isSucursal = store.getters['sucursales/sucursalUuid'];
  const isCaja = store.state.sucursales.caja;

  console.log("is_caja", isCaja);
  if (to.name !== 'cajas') {
    if (requiresCaja) {
      if (role_user_admin || role_user_owner) {
        console.log("role_user_admin || role_user_owner", role_user_admin || role_user_owner);
        next()
      } else {
        if (!isCaja) {
          // Verificar si la cookie "caja" existe en el navegador
          const cajaCookie = Vue.$cookies.get('_cj_id');

          console.log("cajaCookie:", cajaCookie);
          if (cajaCookie) {
            // Realizar una petición al servidor para obtener la información de la caja y guarda la información de la caja en Vuex
            try {
              await store.dispatch('sucursales/validate_caja', {}, { root: true })
              // Continuar la navegación
              next();
            } catch (error) {


              // Error al obtener la información de la caja desde el servidor
              console.error('Error al obtenerole_user_adminr información de la caja:', error);
              next({ name: 'cajas' });
            }
          } else {
            console.log("NO ENCONTRE UNA CAJA EN LA COOKIE; TENGO QUE IR A SELECCIONAR UNA CAJA");
            // await store.dispatch('sucursales/mi_caja', { root: true })
            next({ name: 'cajas' });

            // La cookie "caja" no existe en el navegador

          }
        } else {
          // La caja ya está en Vuex, continuar la navegación
          next();
        }
      }

    } else {
      // La ruta no requiere una caja, continuar la navegación
      next();
    }
  } else {
    //La ruta se esta dirigiendo a cajas
    console.log("//La ruta se esta dirigiendo a cajas y hago next()");
    next()
  }
});


router.beforeEach((to, from, next) => {
  const requiresArqueoCaja = to.matched.some(record => record.meta.requireArqueoCaja)
  const isArqueoIniciado = store.state.sucursales.arqueoCaja

  const isSucursal = store.state.sucursales.sucursal
  console.log("Logica de iniciar caja");
  console.log("isArqueoIniciado", isArqueoIniciado);
  console.log("isSucursal", isSucursal);
  const isCaja = store.state.sucursales.caja
  console.log("isCaja", isCaja);

  console.log("requiresArqueoCaja: ", requiresArqueoCaja);
  console.log("isArqueoIniciado: ", isArqueoIniciado);

  if (isSucursal && isCaja) {
    console.log("1");
    var caja_id = isCaja.id
    if (to.name !== 'iniciarCaja') {
      console.log("2");
      if (isArqueoIniciado) {
        next()
      } else if (!requiresArqueoCaja) {
        next()
      } else {


        console.log("4");





        axios.get(`/sucursales/arqueo`).then((response) => {
          console.log("Peticionde arqueo de caja hecha por router");
          localStorage.setItem('arqueoCaja', JSON.stringify(response.data.arqueo_de_caja))
          next()
        }).catch(e => {
          console.log(e);
          console.log("5");

          if (e.response.status === 404) {
            console.log("6");

            console.log("role user owner", role_user_owner);
            if (role_user_admin || role_user_owner) {
              store.commit('SET_LAYOUT', { layout: 'layout-home', withNav: false })

              next()
            } else {
              store.commit('SET_LAYOUT', { layout: 'layout-basic', withNav: false })

              console.log("Me esta mandando aca");
              next({ name: 'iniciarCaja' })
            }
          }
        })
      }

    } else {
      console.log("7");

      if (!requiresArqueoCaja) {
        console.log("8");

        next()
      } else {
        console.log("9");

        if (isArqueoIniciado) {
          console.log("10");

          next()


        } {
          console.log("11");

          next({ name: 'iniciarCaja' })

        }
      }

    }
  } else {
    console.log("12");

    console.log("Aca");
    console.log(to);

    next()
  }

})

router.beforeEach((to, from, next) => {
  const isArqueoIniciado = store.state.sucursales.arqueoCaja
  const isSucursal = store.state.sucursales.sucursal
  const isCaja = store.state.sucursales.caja


  console.log("isSucursal && isCaja && isArqueoIniciado", Boolean(isSucursal && isCaja && isArqueoIniciado));
  if (isSucursal && isCaja && isArqueoIniciado) {
    if (to.name !== 'cajas') {
      next()

    } else {
      console.log("Bien hecho: el to es: ", to);
      next('/home')
    }

  } else {
    next()
  }

})


router.beforeEach((to, from, next) => {
  const alerta_retiro = store.state.notificaciones.alerta_retiro
  const bloqueo = store.getters['notificaciones/bloqueoRetiroDinero']
  if (bloqueo && alerta_retiro) {
    if (to.path !== '/movimientos/retiro') {
      next('/movimientos/retiro')

    } else {
      next()
    }
  } else {
    next()
  }


})

// router.beforeEach((to, from, next) => {

//   let isError = store.state.initialData.error
//   let messageError = store.state.initialData.messageError
//   console.log("isError",isError);
//   if (isError){
//   const requireModuloCompra = to.matched.some(record => record.meta.requireModuloCompra)
//   const permissionCompra = store.state.sucursales.configuracion.compra
//   if (requireModuloCompra) {
//     if (permissionCompra) {
//       next()

//     } else {
//       next(false)
//     }
//   } else {
//     next()
//   }
//   }
// })
// router.beforeEach((to, from, next) => {
//   const requireModuloCliente = to.matched.some(record => record.meta.requireModuloCliente)
//   const permissionClientes = store.state.sucursales.configuracion.clientes
//   console.log(permissionClientes);
//   if (requireModuloCliente) {
//     if (permissionClientes) {
//       next()

//     } else {
//       if (to.name == 'ClienteCuentaCorrienteSettings') {
//         next()
//       } else {

//         next('/home')
//       }
//     }
//   } else {
//     next()
//   }

// })
// router.beforeEach((to, from, next) => {
//   var cerrar_caja = localStorage.getItem("cerrarCaja")
//   const isArqueoIniciado = store.state.sucursales.arqueoCaja
//   const isCaja = store.state.sucursales.caja

//   if (isCaja) {

//     if (!isArqueoIniciado) {
//       if (cerrar_caja == 'true') {
//         localStorage.removeItem('cerrarCaja')
//         cerrar_caja = false
//       }
//     }
//     if (cerrar_caja == 'true') {
//       console.log(to.path);
//       if (to.path !== '/cerrarCaja') {
//         next('/cerrarCaja')
//       } else {
//         next()
//       }
//     } else {
//       next()
//     }
//   } else {
//     console.log("asagotoatas ");
//     next(false)
//   }

// })

export default router
