export default {
  formasDePago: [],
  // Registro de venta 

  formasDePagoSeleccionadas: [{ nombre: 'EFECTIVO', monto: null }],
  montoDebito: null,
  montoCredito: null,
  montoMercadoPago: null,
  montoTransferencia: null,
  porcentajeRecargoCredito: null,
  descuento: null,
  motivoDescuento: "",
  subtotal: null,
  impuestos: null,

  clienteVenta: null,
  clienteDireccion: null,
  esParaEnviar: false


}
