import Vue from 'vue'
import router from '@/router'
import SucursalesAPI from "@/api/sucursales/sucursal.js";


//en contexto tenemos todo el objeto completo

export async function fetch_sucursales(context, id) {

    try {
        const response = await SucursalesAPI.fetch(); // Esperar a que se resuelva la Promesa
        context.commit('SET_SUCURSALES', response.data);
    } catch (error) {
        console.log("Error en fetch de sucursales: ", error);
        context.commit('SET_SUCURSALES', [])

    }
}
export async function fetch_sucursal_info(context) {

    try {
        const response = await SucursalesAPI.getInfo(); // Esperar a que se resuelva la Promesa
        console.log("Try fetch_sucursal_info success", response.data);
        context.commit('SET_SUCURSAL', response.data.sucursal);
        context.commit('SET_CAJA', response.data.caja);

    } catch (error) {
        console.log("Error en fetch de sucursales: ", error);

    }
}
export async function validate_asignacion_sucursal(context, sucursal_uuid) {

    try {
        if (!sucursal_uuid){
            var uuid_cookie = context.getters['sucursalUuid'];
            if (!uuid_cookie) {
                throw new Error('No se ecnuentra la uuid de la sucursal') 
            }
            sucursal_uuid = uuid_cookie
        }
        const response = await SucursalesAPI.validate(sucursal_uuid); // Esperar a que se resuelva la Promesa
        console.log("Try fetch_sucursal_info success", response.data);
        context.commit('SET_SUCURSAL_UUID_COOKIE', sucursal_uuid);
        context.commit('SET_SUCURSAL', response.data.sucursal);


        // router.push({name: response.data.next_path})
        return { success: true, response: response };



    } catch (error) {
        console.log("Error validate_asignacion_sucursal",error);
        context.commit('SET_SUCURSAL', null);
        router.push({name: 'sucursales'})
        context.commit('DELETE_SUCURSAL_COOKIE');
        return { success: false, error: error };

        

    }
}

export async function validate_caja(context) {

    try {
        const response = await SucursalesAPI.validate_caja(); // Esperar a que se resuelva la Promesa
        console.log("validate caja", response.data);
        const caja = response.data.caja
        console.log(caja);
        if (!caja) {
            context.commit('SET_CAJA', null);
            context.commit('SET_ARQUEO_CAJA', null);


            context.commit('DELETE_CAJA_COOKIE');
            // router.push({name: response.data.next_path})
        }else{

            console.log("caja", caja);
            context.commit('SET_CAJA', caja);
            context.commit('SET_CAJA_ID_COOKIE', caja.id);
        }

        return { success: true, response: response };


        
            
      



    } catch (error) {
        context.commit('SET_CAJA', null);
        context.commit('DELETE_CAJA_COOKIE');

        return { success: false, error: error };


    }
}

export async function fetch_caja(context, caja_id) {
    return new Promise((resolve, reject) => {
        console.log("caja _id");
        caja_id = caja_id ? caja_id : context.rootGetters['sucursales/caja_id_cookie']
        SucursalesAPI.getCaja(caja_id).then(response => {
            if (Object.keys(response).length === 0) {
                context.commit('SET_CAJA', response.data);
                context.commit('SET_CAJA_ID_COOKIE', response.data.id);
            } else {
                context.commit('SET_CAJA', null);
                context.commit('DELETE_CAJA_COOKIE');

            }
            resolve(response);
        }).catch(e => {
            context.commit('SET_CAJA', null);
            context.commit('DELETE_CAJA_COOKIE');

            reject(e);
        })
    })

}
export function mi_caja(context) {
    return new Promise((resolve, reject) => {

        SucursalesAPI.getCajaPorArqueo().then(response => {

            if (Object.keys(response.data).length === 0) {
                console.log(context.rootGetters['sucursales/caja_id_cookie']);
                if (!context.rootGetters['sucursales/caja_id_cookie']) {

                    context.commit('SET_CAJA', null);
                    context.commit('SET_CAJA_ID_COOKIE', null);
                }
                context.commit('SET_ARQUEO_CAJA', null);
                return
            }
            context.commit('SET_CAJA', response.data.caja);
            context.commit('SET_CAJA_ID_COOKIE', response.data.caja.id);
            context.commit('SET_ARQUEO_CAJA', response.data.arqueo);




            resolve(response);
        }).catch(e => {
            context.commit('SET_CAJA', null);
            context.commit('SET_CAJA_ID_COOKIE', null);
            context.commit('SET_ARQUEO_CAJA', null);



            reject(e);
        })
    })



}


export async function fetchArqueoVigente(context) {
    try {
        console.log("Estoy dentro de fetchArqueoVigente");
        console.log("context.state.caja",context.state.caja);

        const response = await SucursalesAPI.getArqueoVigente()
        console.log("Response de fetchArqueoVigente", response.data);
        context.commit('SET_ARQUEO_CAJA', response.data.arqueo_de_caja);
        return { success: true, response: response };


    } catch (error) {
        // Ver en que ocaciones daria error.
        console.log(error);
        context.commit('SET_ARQUEO_CAJA', null);



        var cerrar_caja = localStorage.getItem('cerrarCaja')
        if (cerrar_caja == 'true' || !cerrar_caja) {
            localStorage.removeItem('cerrarCaja')
        }
        return { success: false, error: error };


    }
}



export async function fetch_configuracion(context) {
    try {
        const response = await SucursalesAPI.fetchConfig()
        context.commit('SET_CONFIGURACION', response.data)
        return { success: true, response: response };

        

    } catch (error) {
        return { success: false, error: error };


    }
}

export async function fetch_tipo_parametros(context) {
    const response = await SucursalesAPI.fetch_tipo_parametros
    context.commit('SET_TIPO_PARAMETROS', response.data)
}