

import Vue from 'vue'
import axios from 'axios'
import EgresosApi from "@/api/egresos/index";


export default {
  namespaced: true,
  state: { egresos: [], egresos_buzon: [], egreso_selected_update: null },
  mutations: {
    SET_EGRESOS_BUZON(state, data) {
      state.egresos_buzon = data
    },
    SET_EGRESO_SELECT(state, data) {
      state.egreso_selected_update = data
    },

    ADD_EGRESO(state, egreso) {
      state.egresos.push(egreso)
     
    },
    REMOVE_EGRESO(state, id) {
      for (var i in state.egresos) {

        if (state.egresos[i].id == id) {

          state.egresos.splice(i, 1);

          break; 
        }
      }
    },
  
    update_egreso(state, egreso) {

      for (var i in state.egresos) {

        if (state.egresos[i].id == egreso.id) {

          state.egresos.splice(i, 1);


          state.egresos.push(egreso)


          break; //Stop this loop, we found it!
        }
      }
     
    }
  },

  getters: {
    egresos(state) {
      return state.egresos
    },

  },
  actions: {
    async fetch_egresos_buzon(context) {
      
      

      const egresos = await EgresosApi.fetchEgresoBuzon(null, false)
      console.log(egresos);
      context.commit('SET_EGRESOS_BUZON', egresos.data)



    },
    async get_egreso(context, id) {

      var url = `/egresos/${id}`;

      const egreso = await Vue.axios({
        method: 'GET',
        url: url
      })
      context.commit('SET_EGRESO_SELECT', egreso.data)



    },
    async actualizar_egreso(context, egreso) {

      const egresos = await axios.put(`/egresos/${egreso.id}`, {
        nombre: egreso.nombre,
        telefono: egreso.telefono,
        direccion: egreso.direccion,
        email: egreso.email,
        localidad: { id: egreso.localidad.id }
      })

      context.commit('update_egreso', egresos.data)
    },

    async registrar_egreso_buzon(context, egreso) {
        
      const egresos = await EgresosApi.create(egreso);
      
      context.dispatch('fetch_egresos_buzon')

    },

    async eliminar_egreso(context, id) {

      const egresos = await axios.delete(`/egresos/${id}`)
      context.commit('REMOVE_EGRESO', id)
    },


  }
}