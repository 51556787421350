import Vue from 'vue'
import router from '@/router'

//en contexto tenemos todo el objeto completo

export async function fetch_barrios(context, id) {

    try {
        var url = `/soporte/barrios`;

        await Vue.axios({
            method: 'GET',
            url: url,
        }).then(response => {
            context.commit('set_barrios', response.data)
        })
    } catch (error) {
        console.log("eroor en fetch fromas de pago : " + error)

}
}