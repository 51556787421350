

import Vue from 'vue'
import axios from 'axios'
import DireccionesAPI from '@/api/clientes/direcciones'

export default {
    namespaced: true,
    state: {
        direcciones: [],
        direccion_seleccionada: null,
        cuentaCorriente: null,
        cuentaCorrienteEdit: null,
    },
    mutations: {
        SET_DIRECCIONES(state, data) {
            state.direcciones = data
        },
        setCuentaCorriente(state, data) {
            state.cuentaCorriente = data
        },
     
    },

    getters: {
        cuentasCorrientes(state) {
            return state.cuentasCorrientes
        },
        direccionFavorita(state){
            return state.direcciones.find(direccion => direccion.is_favorita == true)
        }
      

    },
    actions: {

        async fetch_direcciones(context, cliente_id) {
            const direcciones = await DireccionesAPI.index(cliente_id)

            context.commit('SET_DIRECCIONES', direcciones.data)
        },
      
       
        async getCuentaCorriente(context, id) {
            try {
                const cuentasCorrientes = await DireccionesAPI.show(id)


                context.commit('setCuentaCorriente', cuentasCorrientes.data)
    
            } catch (error) {
                context.commit('setCuentaCorriente', null)
            }
          


        },
        async updateCuentaCorriente(context, ctaCte) {

            const cuentasCorrientes = await DireccionesAPI.update(ctaCte)

            // context.commit('update_CuentaCorriente', cuentasCorrientes.data)
            context.dispatch('getCuentaCorriente', ctaCte.cliente)

        },

        async open_cuenta_corriente(context, cliente_id) {
            var data = {cliente: cliente_id}
            await DireccionesAPI.open(data)


            context.dispatch('fetchCuentasCorrientes')

        },

        async deleteCuentaCorriente(context, cliente_id) {
            const ctaCte = await DireccionesAPI.delete(cliente_id)
            // context.commit('quit_CuentaCorriente', ctaCte.id)
        },

        async setHabilitado(context, id) {
            try {
                const cuentasCorrientes = await DireccionesAPI.set_habilitado(id)

                context.dispatch('getCuentaCorriente', id)


    
            } catch (error) {
            }
          


        },


    }
}