
export default [
    {
        path: '/configuracion',
        name: 'configuracion',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/configuracion/configuracion.vue'),
            children: [
                {
                    path: 'general',
                    name: 'configuracionGeneral',
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    component: () => import(/* webpackChunkName: "about" */ '@/views/configuracion/configuracion.general.vue'),
                
                  },
                {
                    path: 'alerta',
                    name: 'configuracionNotificacion',
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    component: () => import(/* webpackChunkName: "about" */ '@/views/configuracion/configuracion.notificaciones.vue'),
                
                  },
                  {
                    path: 'caja',
                    name: 'configuracionCaja',
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    component: () => import(/* webpackChunkName: "about" */ '@/views/configuracion/configuracion.caja.vue'),
                
                  },
                  {
                    path: 'facturacion',
                    name: 'configuracionFacturacion',
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    component: () => import(/* webpackChunkName: "about" */ '@/views/configuracion/configuracion.facturacion.vue'),
                
                  },
            ],
        meta: { requiresAuth: true, requireArqueoCaja: false, requireCaja: false, requireSucursal: false  }
    
      },
      

]