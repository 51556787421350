

//Setea un error generico
export function getError(state, error) {
    state.error = true
    state.errorMessage = error + ". Contacte con el administrador"
    state.productos = []
}
export function SET_VIANDA(state, vianda) {
    state.vianda = vianda
}
export function set_cantidad_productos_admin(state, cantidad) {
    state.paginacion_productos_admin.cantidad = cantidad
}
export function set_page_productos_admin(state, page) {
    state.paginacion_productos_admin.page = page
}
export function set_productos_admin(state, productos) {

    state.productos_admin = productos

}
export function SET_LOADING_PRODUCTOS_ADMIN(state, boolean) {

    state.loading_productos_admin = boolean

}

export function SET_PRODUCTOS_ADMIN_SELECTED(state, productos) {
    state.productos_admin_selected = productos

}
export function SET_FILTERS_PRODUCTO(state, filters) {
    state.productos_filters = filters

}

export function SET_SELECCION_MANUAL_PRODUCTOS_ADMIN(state, bool) {
    console.log("SET_SELECCION_MANUAL_PRODUCTOS_ADMIN", bool);
    state.productos_admin_seleccion_manual = bool

}
export function ADD_PRODUCTO_ADMIN_SELECTED(state, producto) {
    state.productos_admin_selected.push(producto)

}
export function SET_PRODUCTOS_INVENTARIO(state, productos){
    state.productos_inventario = productos

}
export function REMOVE_ITEM_INVENTARIO(state, response){
    console.log(state.productos_inventario)
    for (let i = 0; i < state.productos_inventario.length; i++) {
        const element = state.productos_inventario[i];
        console.log(element);
        
    }
    console.log(state.productos_inventario.filter(p => p.id != response.data.id))
    state.productos_inventario = state.productos_inventario.filter(p => p.id != response.data.id);
   
}
//Setea lo que busca el usuario formateado para enviar al servidor
export function set_input_search(state, input_search) {

    var replaced = input_search.split(" ").join("-");
    state.search_data = replaced
}
//Setea lo que busca el usuario en formato original
export function set_url_productos_admin(state, data) {
    state.url_data = data
}

export function SET_CAT_PRODUCTO_REGISTRAR_MULTIPLE(state, categoria) {

        state.producto_registrar_multiple.categoria = categoria
  
 
}
export function SET_ELAB_PROP_PRODUCTO_REGISTRAR_MULTIPLE(state, bool) {

    state.producto_registrar_multiple.es_elaboracion_propia = bool


}
export function SET_MARCA_PRODUCTO_REGISTRA_MULTIPLE(state, marca) {
    if (marca) {
        state.producto_registrar_multiple.marca = marca
    }
}
export function SET_PROV_PRODUCTO_REGISTRAR_MULTIPLE(state,  proveedor) {
    if (proveedor) {
        state.producto_registrar_multiple.proveedor = proveedor
    }

}
export function SET_MARCA_UNICA_PROD_REG_MUL(state,  bool) {
        state.producto_registrar_multiple.es_marca_unica = bool

}

export function SET_PROD_PRODUCTO_REGISTRAR_MULTIPLE(state, producto) {
    let element = state.producto_registrar_multiple.productos.find(d => d.descripcion == producto.descripcion)
    if (element) {
        state.producto_repetido = true

        setTimeout(() => {
            state.producto_repetido = false

        }, 2500);
        return
    }
    state.producto_registrar_multiple.productos.push(producto)

}
export function RESET_PRODUCTO_REGISTRAR_MULTIPLE(state) {
    state.producto_registrar_multiple.productos = []
    state.producto_registrar_multiple.proveedor = null
    state.producto_registrar_multiple.marca= {id :  null}
    state.producto_registrar_multiple.categoria  = {id :  null}
    state.producto_registrar_multiple.es_marca_unica = false
    state.producto_registrar_multiple.es_categoria_unica = true
    state.producto_registrar_multiple.es_proveedor_unico = true
}
//Setean los valores seleccionados al registrar un producto
export function set_tipo_producto_register(state, tp) {

    state.tipoProducto_register = tp.id
    state.producto_register.categoria = tp



}
export function set_marca_register(state, marca) {
    if (marca) {

        state.marca_register = marca.id
        state.producto_register.marca = marca
    } else {
        state.marca_register = null
        state.producto_register.marca = null
    }
}
export function set_proveedor_register(state, proveedor) {

    state.proveedor_register = proveedor.id
    state.producto_register.proveedor = proveedor


}
export function clean_selected_register(state) {
    state.tipoProducto_register = null
    state.marca_register = null
    state.proveedor_register = null

}

export function addProduct(state, producto) {
    let element = state.producto_register.productos.find(d => d.descripcion == producto.descripcion)
    if (element) {
        state.producto_repetido = true

        setTimeout(() => {
            state.producto_repetido = false

        }, 2500);
        return
    }
    state.producto_register.productos.push(producto)


}
export function clean_producto_register(state) {
    state.producto_register.productos = []
    state.producto_register.marca = null
    state.producto_register.proveedor = null
    state.producto_register.categoria = null



}

export function QUITAR_PRODUCTO_EN_REG_PROD_MUL(state, p) {

    const index = state.producto_registrar_multiple.productos.indexOf(p);
    if (index > -1) {
        state.producto_registrar_multiple.productos.splice(index, 1);
    }

}
export function quit_product(state, p) {

    const index = state.producto_register.productos.indexOf(p);
    if (index > -1) {
        state.producto_register.productos.splice(index, 1);
    }

}


//Setea lo que escribio en el buscador - SIN USAR
export function set_descripcion_input_search(state, d) {
    state.descripcionBuscadorAdmin = d
}


export function quit_producto_admin(state, id) {

    for (var i in state.productos_admin) {

        if (state.productos_admin[i].id == id) {

            state.productos_admin.splice(i, 1);

            break;
        }
    }

}


// Termina buscador-----------------------------------------------------------

//Bandera de reseteo
export function reset_filter(state, filter) {
    state.reset_filter = filter.key
    setTimeout(() => {
        state.reset_filter = null
    }, 1000);
}

//Se setea a null el atributo marca_id de cada producto por cargar, porque marca_unica es true
export function set_marca_unica(state, bool) {
    state.marca_unica = bool
    if (bool == true) {
        for (let i = 0; i < state.producto_register.productos.length; i++) {
            if (state.producto_register.productos[i].marca.id) {

                state.producto_register.productos[i].marca.id = null
            }

        }
    }

}

//Le agrega a producto_register el atributo con la marca que le corresponde
export function SET_MARCA_POR_PRODUCTO_EN_REG_PROD_MUL(state, {marca, producto}) {
    const index = state.producto_registrar_multiple.productos.indexOf(producto);

    if (marca) {

        state.producto_registrar_multiple.productos[index].marca.id = marca.id
    } else {
        state.producto_registrar_multiple.productos[index].marca.id = null

    }


}
export function set_marca_producto(state, data) {
    let marca = data[0]
    let producto = data[1]
    const index = state.producto_register.productos.indexOf(producto);
    if (marca) {

        state.producto_register.productos[index].marca.id = data[0].id
    } else {
        state.producto_register.productos[index].marca.id = null

    }


}
export function set_productos_reporte(state, productos) {
    state.productos_reporte = []
    state.productos_reporte = productos
    state.error = false
    state.errorMessage = ''
}

// ACTUALIZACION
export function set_productos_update(state, productos) {
    state.productos_update = []
    state.productos_update = productos
    state.error = false
    state.errorMessage = ''
}
//Setea el/los producto que seleccionó el usuario para actualizar
export function set_producto_selected(state, productos) {
    if (Array.isArray(productos)) {
        state.productos_selected = []

        state.productos_selected = productos


    } else {

        state.productos_selected = []
        state.productos_selected.push(productos)
    }

}




export function quit_producto_for_update(state, id) {

    for (var i in state.productos_update) {

        if (state.productos_update[i].id == id) {

            state.productos_update.splice(i, 1);

            break;
        }
    }

}
// Haria practicamente lo mismo que set_update_producto_info
export function update_product(state, producto) {

    for (var i in state.productos_update) {

        if (state.productos_update[i].id == producto.id) {

            state.productos_update[i].precioVenta = producto.precioVenta
            state.productos_update[i].precioCosto = producto.precioCosto
            state.productos_update[i].iva = producto.iva

            state.productos_update[i].ganancia = producto.ganancia

            state.productos_update[i].flete = producto.flete

            break; //Stop this loop, we found it!
        }
    }

}
// Cuando actualiza un producto, lo actualiza en el array de productos_update
export function set_update_producto_info(state, producto) {

    for (var i in state.productos_update) {

        if (state.productos_update[i].id == producto.id) {

            state.productos_update.splice(i, 1);


            state.productos_update.push(producto)


            break;
        }
    }

}

// FILTROS
export function clean_filters(state) {
    state.filters_for_productos_admin = []
}
export function addFilter(state, filter) {
    let element = state.filters_for_productos_admin.find(d => d.key == filter.key)

    if (element) {
        for (let i = 0; i < state.filters_for_productos_admin.length; i++) {
            if (state.filters_for_productos_admin[i].key == element.key) {
                state.filters_for_productos_admin.splice(i, 1);
                state.filters_for_productos_admin.push(filter);
            }
        }
    } else {
        state.filters_for_productos_admin.push(filter);

    }

}
export function quitFilter(state, filter) {


    const index = state.filters_for_productos_admin.indexOf(filter);

    if (index > -1) {

        state.filters_for_productos_admin.splice(index, 1);


    }


}


export function set_productos_mas_vendidos(state, productos) {
    console.log("set_productos_mas_vendidos",productos);
    state.productos_mas_vendidos = productos

}
export function refresh_stock_producto_frecuente(state, detalle) {
    console.log(detalle);

    
    let producto = state.productos_mas_vendidos.find(d => d.id == detalle.producto.id)
    if (producto) {
        if (detalle.cantidad > 0) {

            producto.cantidadstock = detalle.producto.cantidadstock - detalle.cantidad
        }else {
            var cantidad = Math.abs(detalle.cantidad)
            producto.cantidadstock = detalle.producto.cantidadstock + cantidad

        }
    }


}
export function set_loading_mas_vendidos(state, bool) {
    state.loading_mas_vendidos = bool
}
