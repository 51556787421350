<template>
  <v-app-bar
    :extended="loadingSystemUpdate"
    extension-height="7"
    style="border-bottom: solid rgba(255, 255, 255, 0.12)"
    flat
    clipped-left
    app
  >
    <v-app-bar-nav-icon
      @click="goToRoute('/home')"
      class="siteLogo align-start"
    >
      <!-- Contenido del componente -->
      <a @click="goToRoute('/home')"></a>
    </v-app-bar-nav-icon>
    <v-btn text class="ml-5" @click="goToRoute('/sucursales')">
      {{ sucursalName }}
    </v-btn>
    <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>

    <v-toolbar-title class="d-flex justify-center">
      <producto-buscador
        :key="reload_search"
        @process-buscador-producto="goToBuscador"
        @process-buscador-producto-select="goToBuscador"
      ></producto-buscador>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <notificaciones-menu></notificaciones-menu>
    <usuario-menu></usuario-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ProductoBuscador from "@/components/producto/buscador/producto.buscador.vue";
import UsuarioMenu from "@/components/usuarios/usuario.menu.vue";
import NotificacionesMenu from "@/components/notificaciones/notificaciones.menu.vue";
import { getStorage } from "@/services/serviceLocalStorage";

export default {
  name: "NavigationLogged",
  components: {
    ProductoBuscador,
    UsuarioMenu,
    NotificacionesMenu,
  },
  data() {
    return {
      reload_search: 0,
      navBar: [],
    };
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),
    ...mapGetters(["loadingSystemUpdate"]),
    ...mapState("sucursales", ["sucursal"]),
    sucursalName() {
      return this.sucursal && this.sucursal.nombre
        ? this.sucursal.nombre.toUpperCase()
        : "Sucursal - default";
    },
  },
  methods: {
    goToRoute(route) {
      if (this.$route.path !== route) {
        this.$router.push(route).catch((e) => {});
      }
    },
    goToBuscador(search) {
      let pathName = this.$route.name;
      var isBarcode = false;
      let isnum = /^\d+$/.test(search);

      if (isnum && search.length > 6) {
        isBarcode = true;
      }
      var param = { q: search };
      if (isBarcode) {
        param = { barcode: search };
      }
      if (pathName == "consultarPrecios") {
        this.$router.replace({ query: param });
      } else {
        this.$router.push({ name: "consultarPrecios", query: param });
      }
      this.reload_search = !this.reload_search;
    },
  },
  mounted() {
    this.navBar.push(
      {
        id: 0,
        title: "Inicio",
        icon: "mdi-home",
        route: "/home",
      },
      {
        id: 1,
        title: "Productos",
        icon: "mdi-format-list-checks",
        route: "/productos",
      }
    );
    if (localStorage.getItem("arqueoCaja")) {
      this.navBar.push({
        id: 2,
        title: "Ventas",
        icon: "mdi-cart-arrow-right",
        route: "/ventas",
      });
    }
    if (this.configuracion && this.configuracion.compra) {
      this.navBar.push({
        id: 11,
        icon: "mdi-store-outline",
        title: "Compras",
        route: "/compras",
      });
    }
  },
};
</script>

<style scoped>
.siteLogo {
  left: 10px;
  background-image: url("../../../../assets/bear-face-icon-2.png"), none;
  background-size: 50px;
}
</style>
