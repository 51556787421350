

import Vue from 'vue'
import axios from 'axios'
import NotificacionesAPI from '@/api/configuracion/notificaciones/index.js'

export default {
  namespaced: true,
  state: { notificacionesNuevas: [], notificacionesAnteriores: [], sin_ver: 0, alerta_retiro: false, tipo_notificaciones: [] },
  mutations: {
    SET_TIPO_NOTIFICACIONES(state, data) {
      state.tipo_notificaciones = data
    },
    setNotificaciones(state, notificaciones) {
      state.notificacionesNuevas = notificaciones.results.nuevas
      state.notificacionesAnteriores = notificaciones.results.anteriores
      state.sin_ver = notificaciones.results.sin_ver
      state.alerta_retiro = notificaciones.results.alerta_retiro

    },
    setNotificacionesSinVer(state, cantidad) {
      state.sin_ver = cantidad

    },
    APPEND_NOTI_ANT(state, notificaciones) {
      notificaciones.forEach(item => state.notificacionesAnteriores.push(item));
    }

  },

  getters: {
    notificacionNuevaDeRetiro(state) {
      const check = state.notificacionesNuevas.find((item) => item.title == 'Retirar dinero del buzón')
      console.log(check);
      return check
    },
    notificacionesNuevas(state) {
      return state.notificacionesNuevas

    },
    notificacionesSinVer(state) {
      return state.sin_ver
    },
    notificacionesAnteriores(state) {
      return state.notificacionesAnteriores
    },

    bloqueoRetiroDinero(state) {
      var results = state.tipo_notificaciones.filter((function (tipo) { return tipo.codigo == '100'; }))

      var firstObj = (results.length > 0) ? results[0].bloqueo ? results[0].bloqueo : false  : false
      return firstObj
    },
    bloqueoStockMinimo(state) {
      var results = state.tipo_notificaciones.filter((function (tipo) { return tipo.codigo == '200'; }))

      var firstObj = (results.length > 0) ? results[0].bloqueo ? results[0].bloqueo : false  : false
      return firstObj
    },

  },
  actions: {
    async fetch_tipo_notificaciones(context) {
      const response = await NotificacionesAPI.index()
      context.commit('SET_TIPO_NOTIFICACIONES', response.data)
    },
    async update_tipo_notificacion_status(context, data) {
      const response = await NotificacionesAPI.update(data)
    },

    async fetch_notificaciones(context, page) {
        try {
          
          var url = `/notificaciones/pendientes?page=${page}`;
          const response = await Vue.axios({
            method: 'GET',
            url: url
          })
          context.commit('setNotificaciones', response.data)
        } catch (error) {
          console.log("Error al obtener la snotificaciones");
        }

    },
    async_fetch_notificaciones(context, page) {

      
      return context.dispatch('fetch_notificaciones',1)
    },

    async acutalizarVistoNotificaciones(context) {
      var url = "/notificaciones/setVisto";
      const notificaciones = context.state.notificacionesNuevas.concat(context.state.notificacionesAnteriores)
      const notificaciones_sin_ver = notificaciones.filter(n => n.visto == false)
      var notificaciones_id = notificaciones_sin_ver.map(a => a.id)

      try {
        const response = await Vue.axios({
          method: 'POST',
          url: url,
          data: { notificaciones: notificaciones_id }
        })
        context.commit('setNotificacionesSinVer', 0)
      } catch (error) {
        console.log(error);
      }
    },
  
    enviar_notificacion(context, mensaje, type) {
      context.commit('setProveedorSelectedUpdate', proveedor.data)
    },
    async actualizar_proveedor(context, proveedor) {

      const proveedores = await axios.put(`/proveedores/${proveedor.id}`, {
        nombre: proveedor.nombre,
        telefono: proveedor.telefono,
        direccion: proveedor.direccion,
        email: proveedor.email,
        localidad: { id: proveedor.localidad.id }
      })

      context.commit('update_proveedor', proveedores.data)
    },

    async registrar_proveedor(context, proveedor) {
      const proveedores = await axios.post(`/proveedores/`, {
        nombre: proveedor.nombre,
        telefono: proveedor.telefono,
        direccion: proveedor.direccion,
        email: proveedor.email,
        localidad: { id: proveedor.localidad.id }
      })

      context.dispatch('fetchProveedores')
    },

    async eliminar_proveedor(context, id) {
      const proveedores = await axios.delete(`/proveedores/${id}`)
      context.commit('quit_proveedor', id)
    },


  }
}