

import Vue from 'vue'
import router from '@/router'
import ClienteAPI from '@/api/clientes/clientes'

export default {
  namespaced: true,
  state: {
    clientes: [],
    cliente_selected_update: null
  },
  mutations: {
    setClientes(state, data) {
      state.clientes = data
    },
    setClienteUpdate(state, data) {
      state.cliente_selected_update = data
    },

    addCliente(state, cliente) {
      state.clientes.push(cliente)

    },
    quitCliente(state, id) {
      for (var i in state.clientes) {

        if (state.clientes[i].id == id) {

          state.clientes.splice(i, 1);

          break;
        }
      }
    },

    updateCliente(state, cliente) {

      for (var i in state.clientes) {

        if (state.clientes[i].id == cliente.id) {

          state.clientes.splice(i, 1);


          state.clientes.push(cliente)


          break; //Stop this loop, we found it!
        }
      }

    }
  },

  getters: {
    clientes(state) {
      return state.clientes
    },

  },
  actions: {

    async fetchClientes(context) {
      const clientes = await ClienteAPI.index()
      context.commit('setClientes', clientes.data)

    },

    async get_cliente(context, id) {

      var url = `/clientes/cliente/${id}`;

      const cliente = await Vue.axios({
        method: 'GET',
        url: url
      })
      context.commit('setClienteUpdate', cliente.data)



    },
    async actualizar_cliente(context, cliente) {

      const clientes = await ClienteAPI.update(cliente)

      context.commit('updateCliente', clientes.data)
    },

    async registrar_cliente(context, cliente) {
      await ClienteAPI.create(cliente)


      context.dispatch('fetchClientes')

    },
    async actualizar_cliente_contable(context, cliente) {
      await ClienteAPI.updateContable(cliente)
      context.dispatch('fetchClientes')

    },

    async eliminar_cliente(context, id) {
      try {

        const cliente = await ClienteAPI.delete(id)
        context.dispatch('fetchClientes')

        router.push({ name: 'clientes' })
      } catch (error) {

      }
      // context.commit('quitCliente', cliente.id)
    },


  }
}