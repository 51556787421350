
export default [

  {
    path: '/empleados',
    name: 'empleados',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/empleado/empleados.vue'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

  },
  {
    path: '/empleados/consultar',
    name: 'empleadosConsultar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/empleado/empleados.consultar.vue'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

  },
  {
    path: '/empleados/consumos',
    name: 'empleadosConsumo',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/empleado/empleados.consumos.vue'),

    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

  },
  
  {


    path: '/empleados/:id_empleado',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/empleado/empleado/empleado.vue'),
    children: [
      // UserHome will be rendered inside User's <router-view>
      // when /user/:id is matched
      {
        path: '',
        name: 'empleadoHome',
        component: () => import(/* webpackChunkName: "about" */ '@/views/empleado/empleado/empleado.home.vue'),
      },
      {
        path: 'editar',
        name: 'editarEmpleado',
        component: () => import(/* webpackChunkName: "about" */ '@/views/empleado/empleado/empleado.update.vue'),
      },
      {
        path: 'info',
        name: 'empleadoSinCliente',
        component: () => import(/* webpackChunkName: "about" */ '@/views/empleado/empleado/empleado.sincliente.vue'),
      },

      {
        path: 'usuario',
        name: 'empleadoUsuario',
        component: () => import(/* webpackChunkName: "about" */ '@/views/empleado/empleado/empleado.usuario.vue'),
      },
      {
        path: 'cuentacorriente/:id_cliente',
        name: 'empleadoCtaCte',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/cliente/cliente/ClienteCuentaCorriente.vue'),



      },

      {
        path: 'cuentacorriente/settings/id_cliente:',
        name: 'empleadoCtaCteSettings',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/cliente/cliente/ClienteCuentaCorrienteSettings.vue'),
      },

      // ...other sub routes
    ],
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

  },

  {
    path: '/empleados/nuevo',
    name: 'nuevoEmpleado',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/empleado/empleado/empleado.registrar.vue'),

    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

  },

  {
    path: '/profile',
    name: 'profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/usuarios/profile/profile.vue'),
    children: [
      {
        path: 'misdatos',
        name: 'misdatos',
        component: () => import(/* webpackChunkName: "about" */ '@/views/usuarios/profile/misDatos.vue'),



      },
      {
        path: 'consumos',
        name: 'misConsumos',
        component: () => import(/* webpackChunkName: "about" */ '@/views/usuarios/profile/misConsumos.vue'),



      },
      {
        path: 'retiros',
        name: 'misRetiros',
        component: () => import(/* webpackChunkName: "about" */ '@/views/usuarios/profile/misRetiros.vue'),



      },
    ],

    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

  },

]