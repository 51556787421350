import axios from 'axios'

function getStorage(key) {
  var result = localStorage.getItem(key)
    if (result != 'undefined') {
      return JSON.parse(localStorage.getItem(key))
    }
    return null
    


}
function saveStorage (key, data) {
  localStorage.setItem(key, JSON.stringify(data))
}


export {getStorage, saveStorage}