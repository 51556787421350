<template>
  <v-app id="inspire">
        <!-- <loading :active.sync="loadingDataInit"></loading> -->
      <!-- <LoadingCenter v-if="loadingDataInit || loadingInit" /> -->

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :right="snackbar.right"
      :left="snackbar.left"
      :timeout="snackbar.timeout"
      :top="snackbar.top"
    >
      <span class="title"
        ><v-icon large>{{ snackbar.icon }}</v-icon> {{ snackbar.text }}</span
      >
      <template v-slot:action="{ attrs }">
        <v-btn
          v-show="snackbar.button"
          text
          v-bind="attrs"
          outlined
          @click="snackbar.action"
        >
          <strong>{{ snackbar.textButton }}</strong>
        </v-btn>
      </template>
    </v-snackbar>
    <HeaderInbox></HeaderInbox>
    <NavigationInbox
      :style="navHome ? '' : 'display:none'"
      :links="links"
    ></NavigationInbox>
    <v-main :style="navHome ? '' : 'padding: 64px 0px 0px 0px;'">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import LoadingCenter from "@/util/LoadingCenter.vue";

import { mapActions, mapGetters, mapState } from "vuex";
import HeaderInbox from "./header/HeaderInbox.vue";
import NavigationInbox from "./navigation/NavigationInbox.vue";
export default {
  name: "LayoutInbox",
  components: {
    HeaderInbox,
    NavigationInbox,
    Loading,
    LoadingCenter
  },
  data() {
    return {
      hidenNav: false,
      snackbar: {
        show: false,
        button: false,
        textButton: "DEFAULT",
        color: "",
        mode: "",
        timeout: null,
        text: "",
        top: true,
        right: true,
        icon: null,
        vertical: false,
        action: () => {
          snackbar.show = false;
        },
      },
      links: [],
      menuName: "administrador",
    };
  },
  computed: {
    ...mapState(["loading"]),
     
    ...mapState("initialData", ["loadingDataInit"]),
    ...mapState(["loadingInit"]),
    ...mapGetters("notificaciones", ["bloqueoRetiroDinero"]),
    ...mapState("notificaciones", ["alerta_retiro"]),

    navHome() {
      if ("navHome" in this.$route.meta) {
        return this.$route.meta.navHome;
      } else {
        return true;
      }
    },
    ...mapGetters(["getLinksDelMenu"]),
  },
  methods: {
     ...mapActions(["fetchLinksPorMenu"]),
    async obtenerLinksDelMenu(menuName) {
      if (menuName) {
        console.log("layoubasic", menuName);
        await this.fetchLinksPorMenu(menuName); // Espera a que la acción termine antes de continuar
        this.links = this.getLinksDelMenu(menuName);
      }
    },
  },
   watch: {
    alerta_retiro(val) {
      if (val) {
        if (this.bloqueoRetiroDinero) {
          this.$router.push("/movimientos/retiro");
        }
        setTimeout(() => {
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            color: "warning",
            button: true,
            top: false,
            right: false,
            left: true,
            textButton: "Retirar",
            text: `Hacer un retiro`,
            icon: "mdi-bell-ring",
            timeout: -1,
            action: () => {
              this.$router.push("/movimientos/retiro");
            },
          });
        }, 1500);
      }
    },
  },
  mounted(){
       this.$updateBus.$on("showSnackBar", (snackbar, action) => {
      this.snackbar = Object.assign(this.snackbar, snackbar);
    });
  },
  created() {
    this.obtenerLinksDelMenu(this.menuName);
  },
};
</script>

<style lang="scss" scoped>
</style>