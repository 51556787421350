import axios from 'axios'
export default {
    index(search, paginacion) {
        var url = `/productos/p?q=${search}&page=${paginacion.page}&page_size=${paginacion.rowsPerPage}`;
        return axios.get(url)
    },
    indexHistorial(id_producto, paginacion) {
        var url = `/productos/historial?id=${id_producto}&page=${paginacion.page}&page_size=${paginacion.rowsPerPage}`;
        return axios.get(url)
    },
    indexInventario(id_producto, paginacion) {
        var url = `/productos/inventario/${id_producto}?page=${paginacion.page}&page_size=${paginacion.rowsPerPage}`;
        return axios.get(url)
    },
    indexCategorias(nombreCategoria) {
        if (!nombreCategoria) {
            var nombreCategoria = 'CATEGORIA'
        }
        var url = `/categorias/categorias?name=${nombreCategoria}`;
        return axios.get(url)
    },
    fetchProductosPorCategoria(nombreCategoria, paginacion) {
        var url = `/productos/p?categoria=${nombreCategoria}&page=${paginacion.page}&page_size=${paginacion.rowsPerPage}`;
        return axios.get(url)
    },
    createCategoria(data){
        var url = "/categorias/";
        return axios.post( url, data );
    },
    updateCategoria(id, data){
        var url = `/categorias/${id}`;
        return axios.put( url, data );
    },
    getSuggest(search, categorias=[]) {
        var url = `/productos/suggest?q=${search}&categorias=${categorias}`;
        return axios.get(url)
    },
    fetchPedidoYa() {
        return axios.get(`/productos/p?masvendidos=true&py=true`)
    },
    fetchMasVendidos() {
        return axios.get(`/productos/p?masvendidos=true`)
    },
    fetchInventario(params, paginacion) {
        var param_url = ''
        if (params.length > 0) {

            param_url = '&'
            for (var i = 0; i < params.length; i++) {
                var value = params[i].value
                var key = params[i].key
                param_url = param_url.concat(key, '=', value, '&')
            }
        }

        // var url = `/productos/p?page=${paginacion.page}&page_size=${paginacion.rowsPerPage}${param_url}`;

        if (!params || params.length == 0) return axios.get(`/productos/inventario?inventario=true&page=${paginacion.page}&page_size=${paginacion.rowsPerPage}`)

        return axios.get(`/productos/inventario?${param_url}page=${paginacion.page}&page_size=${paginacion.rowsPerPage}`)
    },
    fetchSinInventario() {
        return axios.get(`/productos/p?inventario=false`)
    },
    updateInventario(producto) {
        return axios.put(`/productos/inventario/${producto.id}`, producto)
    },
    registrarProductoFraccionado(productoPadre, productoParaRegistrar){
        var data = {parent_id: productoPadre.id, producto: productoParaRegistrar}
        return axios.post(`/productos/fraccionados`, data)
    },
    show(id) {
        return axios.get(`/productos/p/${id}`)
    },
    showProductosFraccionados(id) {
        return axios.get(`/productos/fraccionados/${id}`)
    },
    update(producto) {
        return axios.put(`/productos/p/${producto.id}`, producto)
    },
    registrarSimple(data) {
        return axios.post(`/productos/p`, data)
    },
    anular(id, motivoAnulacion) {
        return axios.put(`comprobantes/anulados/${id}`, {
            motivoAnulacion: motivoAnulacion,
        });
    },
    getVianda() {
        return axios.get(`/productos/p?vianda=true`)
    },
    registrarProductosMedianteListaDePrecios(data){
        var url = '/productos/registrar/productos-de-listadeprecio'
        return axios.post(url, data)
    }
}