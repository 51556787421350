import axios from 'axios'
import FacturaCompraAPI from '@/api/compra/factura'

export default {
    namespaced: true,
    state: {
        facturas: []


    },
    mutations: {

    },
    getters: {


    },
    actions: {

        fetch_factura_compra(context, paginacion) {
            return new Promise((resolve, reject) => {
                FacturaCompraAPI.index(paginacion)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

    }
}

